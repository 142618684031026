import { makeAutoObservable, runInAction } from "mobx";
import { ContractsSingleton, SignerInfo } from "../../logic";
import { web3Store } from "../web3-store";
import { SignerUtils } from "../../logic/utils/SignerUtils";

/**
 * Store to get info about signer
 * We need to display it at the top of the app
 */
class SignerInfoNavViewStore {
  isFetchingRequestData = false;
  private readonly web3Store = web3Store;
  signerInfo: SignerInfo | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchSignerInfo() {
    const cs = await this.core();
    const wallet = await cs.signer.getAddress();
    const workerUid = await cs.companyManager.getWorkerByWallet(wallet);
    if (workerUid.eq(0)) {
      // the wallet is not registered as a worker
      runInAction(() => {
        this.signerInfo = { wallet };
      });
    } else {
      const si = await SignerUtils.getSignerInfo(cs, wallet);
      runInAction(() => {
        this.signerInfo = si;
      });
    }
  }

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }
}

export const signerInfoViewStore = new SignerInfoNavViewStore();
