import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App/App";
import { Provider as MobxProvider } from "mobx-react";
import * as stores from "./stores";
import { HashRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <MobxProvider {...stores}>
      <HashRouter>
        <App />
      </HashRouter>
    </MobxProvider>
  </React.StrictMode>,
);
