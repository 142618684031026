import { makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { web3Store } from "../web3-store";
import { ContractsSingleton, ITokenInfo, RoleData, WorkerDataWithTitles, ZERO_ADDRESS } from "../../logic";
import { BigNumber } from "ethers";
import { WorkerUtils } from "../../logic/utils/WorkerUtils";

class ListWorkersEditorViewStore {
  isFetchingData = false;
  workers: WorkerDataWithTitles[] | null = null;
  /** List of roles (we need it to allow select new role from the list) */
  roles: RoleData[] | null = null;
  /**
   * Decimals for each debt-token used by workers
   *    debt-token-address => decimals (i.e. tetu => 18)
   */
  mapTokenDecimals: Map<string, ITokenInfo>;

  private readonly web3Store = web3Store;

  constructor() {
    // makeAutoObservable(this); // todo do we really need it? problems with ListWorkersEditor, recursive calls of effect
    this.mapTokenDecimals = new Map<string, ITokenInfo>();
  }

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);

  }

  //region Load data

  /** Load info about the workers */
  async fetchWorkers(workerUids: BigNumber[]) {
    runInAction(() => {
      this.isFetchingData = true;
    });
    try {
      const cs = await this.core();
      const r = await WorkerUtils.getWorkers(cs, workerUids);
      const roles = await cs.cachedData.getRolesList();

      // get all not-default debt-tokens used by the workers and found decimals for them
      const map = new Map<string, ITokenInfo>();
      for (const w of r) {
        if (w.debtToken && w.debtToken !== ZERO_ADDRESS) {
          const decimals = await cs.cachedData.getTokenDecimals(w.debtToken);
          const name = await cs.cachedData.getTokenName(w.debtToken);
          map.set(w.debtToken, {decimals, name});
        }
      }

      runInAction(() => {
        this.workers = r;
        this.roles = roles;
        this.mapTokenDecimals = map;
      });
      return r;
    } finally {
      runInAction(() => {
        this.isFetchingData = false;
      });
    }
  }

  //endregion Load data

  //region Light update "workers"
  updateWorkerInMemory(workerUid: BigNumber, updater: (worker: WorkerDataWithTitles) => void) {
    if (this.workers) {
      for (let i = 0; i < this.workers.length; ++i) {
        if (this.workers[i].workerUid.eq(workerUid)) {
          updater(this.workers[i]);
        }
      }
    }
  }

  //endregion Light update "workers"

  //region Change worker properties
  async changeWorkerName(workerUid: BigNumber, newName: string) {
    const cs = await this.core();
    await cs.runAndWait(() => cs.companyManager.setWorkerName(workerUid, newName));
    const updatedName = (await cs.companyManager.getWorkerInfo(workerUid))?.name;
    this.updateWorkerInMemory(workerUid, (w) => {
      w.name = updatedName;
    });
  }

  async changeWorkerWallet(workerUid: BigNumber, newWallet: string) {
    const cs = await this.core();
    await cs.runAndWait(() => cs.companyManager.changeWallet(workerUid, newWallet));
    const updatedValue = (await cs.companyManager.getWorkerInfo(workerUid))?.wallet;
    this.updateWorkerInMemory(workerUid, (w) => {
      w.wallet = updatedValue;
    });
  }

  /**
   * Change hour rate - only if user uses default debt token (USD)
   */
  async changeWorkerHourRate(workerUid: BigNumber, hourRate: number) {
    const cs = await this.core();
    await cs.runAndWait(() => cs.companyManager.setHourlyRate(workerUid, hourRate));
    const updatedValue = (await cs.companyManager.getWorkerInfo(workerUid))?.hourRate;
    this.updateWorkerInMemory(workerUid, (w) => {
      w.hourRate = BigNumber.from(updatedValue);
    });
  }

  /**
   * Change debt token and hour rate
   */
  async changeDebtTokenAndHourRate(workerUid: BigNumber, hourRate: BigNumber, debtToken: string) {
    const cs = await this.core();
    await cs.runAndWait(() => cs.companyManager.setWorkersDebtTokens(workerUid, debtToken, hourRate));
    const updatedValues = (await cs.companyManager.getWorkerDebtTokenInfo(workerUid));
    this.updateWorkerInMemory(workerUid, (w) => {
      w.hourRate = updatedValues.hourRateEx;
      w.debtToken = updatedValues.debtToken;
    });
  }

  async changeWorkerRole(workerUid: BigNumber, role: number) {
    const cs = await this.core();
    await cs.runAndWait(() => cs.companyManager.setWorkerRole(workerUid, role));
    const updatedValue = (await cs.companyManager.getWorkerInfo(workerUid))?.role;
    const updatedRoleTitle = (await cs.cachedData.getRoleData(updatedValue))?.title || "";
    this.updateWorkerInMemory(workerUid, (w) => {
      w.roleUid = updatedValue;
      w.roleTitle = updatedRoleTitle;
    });
  }

  //endregion Change worker properties
}

export const listWorkersEditorViewStore = new ListWorkersEditorViewStore();
