export const _DEFAULT_PAGE_SIZE = 10;

export class PaginationUtils {
  public static readonly defaultPageSize = _DEFAULT_PAGE_SIZE;

  /**
   * Call func multiple times
   * providing each time only limited number of input items.
   * Combine results to single result array
   *
   * @param items
   * @param func
   * @param chunkSize
   */
  static async chunkCall<TypeIn, TypeOut>(
    items: TypeIn[],
    func: (item: TypeIn[]) => Promise<TypeOut[]>,
    chunkSize: number = _DEFAULT_PAGE_SIZE,
  ): Promise<TypeOut[]> {
    const dest: TypeOut[] = [];
    for (let i = 0; i < items.length; i += chunkSize) {
      const chunk = items.slice(i, i + chunkSize);
      dest.push(...(await func(chunk)));
    }
    return dest;
  }
}
