export class ErrorUtils {
  /** https://fettblog.eu/typescript-hasownproperty/ */
  public static hasProperty<X extends Record<string, unknown>, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
    // eslint-disable-next-line no-prototype-builtins
    return obj.hasOwnProperty(prop);
  }

  // eslint-disable-next-line
  public static getErrorTextToDisplay(e: any): string {
    console.log(e, e instanceof Error, (e as unknown as Error).message, typeof e);
    if (e && typeof e === "object") {
      if (ErrorUtils.hasProperty(e, "message")) {
        if (e.message && typeof e.message === "string") {
          return e.message || "";
        }
      }
    }
    return e.toString();
  }
}
