import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {AlchemyWeb3, createAlchemyWeb3} from "@alch/alchemy-web3";
import {ExternalProvider} from "@ethersproject/providers/src.ts/web3-provider";

const rpcProvider = {
  137: process.env.REACT_APP_WEB_3_URL_MATIC,
  // hardhat local; it's not 31337 because of https://github.com/MetaMask/metamask-extension/issues/10290
  1337: process.env.REACT_APP_WEB_3_URL_HARDHAT,
  31337: process.env.REACT_APP_WEB_3_URL_HARDHAT,
  80001: process.env.REACT_APP_WEB_3_URL_MUMBAI,
  43113: process.env.REACT_APP_WEB_3_URL_FUJI,
};
type rpcProviderKey = keyof typeof rpcProvider;

export class ConnectionUtils {
  /**
   * Connect to the wallet using Web3Modal library
   */
  static async makeConnection(): Promise<{ web3modal: Web3Modal; provider: ExternalProvider }> {
    console.log("connect");
    const web3modal = new Web3Modal({
      cacheProvider: true,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: "3281dcb8100f405786ce1d6ef3e57c50",
            rpc: rpcProvider,
            supportedChainIds: Object.keys(rpcProvider),
          },
        },
      },
    });

    const provider = await web3modal.connect().catch((e) => {
      console.log(e);
    });

    return {web3modal, provider};
  }

  static web3(): AlchemyWeb3 {
    const key = process.env.REACT_APP_WEB_3_ACTIVE_NETWORK as unknown as rpcProviderKey;
    console.log("Connect to", key, rpcProvider[key]);
    return createAlchemyWeb3(rpcProvider[key] || "error_provider", {maxRetries: 3});
  }
}
