import { makeAutoObservable } from "mobx";
import { web3Store } from "../web3-store";
import { ContractsSingleton } from "../../logic";

/**
 * Store for create-request page
 */
class EpochNavigatorChainStore {
  constructor() {
    makeAutoObservable(this);
  }

  private readonly web3Store = web3Store;

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  async getCurrentEpoch() {
    return await (await this.core()).debtsManager.currentEpoch();
  }
}

export const epochNavigatorChainStore = new EpochNavigatorChainStore();
