import { BigNumber } from "ethers";

export class AmountUtils {
  static amountToString(bn?: BigNumber, tokenDecimals = 18): string {
    const precision = 1e6;
    if (bn !== undefined && bn !== null) {
      const n = bn.mul(precision).div(BigNumber.from(10).pow(tokenDecimals)).toNumber() / precision;
      return AmountUtils.numberToString(n);
    }
    return "?";
  }

  static numberToString(n?: number): string {
    if (n) {
      return new Intl.NumberFormat(undefined, { style: "decimal" }).format(n);
    }

    return "?";
  }

  /** Convert BigNumber to number by removing predefined number of 0
   * I.e. 1e18 => 1 */
  static removeDecimals(bn: BigNumber, decimals = 18): number {
    return bn.div(BigNumber.from(10).pow(decimals)).toNumber();
  }

  static isZeroAddress(address: string): boolean {
    try {
      return BigNumber.from(address).eq(0);
    } catch (e) {
      console.log(e);
    }

    return false;
  }
}
