import React, { useEffect, useState } from "react";
import { Button, Input, Layout, Modal, Radio, RadioChangeEvent, Select, Space } from "antd";
import { ListWorkersEditor } from "../../components/ListWorkersEditor";
import { BigNumber } from "ethers";
import { listWorkersEditorViewStore, web3Store, workersPageViewStore } from "../../stores";
import { observer } from "mobx-react";
import { DepartmentData } from "../../logic";

/**
 * Governance only.
 * Allow to view full list of workers, including the out-of-department-workers.
 * Allow to create new workers and move workers to departments
 * */
export const WorkersPage = observer(() => {
  //region Selected workers
  const [listWorkers, setListWorkers] = useState<BigNumber[]>([]);
  const [isModalCreateWorkersVisible, setIsModalCreateWorkersVisible] = useState(false);
  const [listDepartments, setListDepartments] = useState<DepartmentData[]>([]);

  /** Load list of workers and list of departments*/
  useEffect(() => {
    if (web3Store.provider) {
      const fetchData = async () => {
        // load all workers
        await recreateWorkersListItems();

        // load all departments
        await workersPageViewStore.fetchDepartments();
        setListDepartments(workersPageViewStore.departments || []);
      };
      fetchData().catch(console.error);
    }
  }, [listWorkersEditorViewStore, web3Store.provider]);

  async function recreateWorkersListItems() {
    await workersPageViewStore.fetchWorkers();
    const newList = workersPageViewStore.workerUids || [];
    setListWorkers(() => newList);
  }
  //endregion Selected department

  //region Create a new worker
  const [name, setName] = useState<string>("");
  const [wallet, setWallet] = useState<string>("");
  const [hourRate, setHourRate] = useState<number>(0);
  const [role, setRole] = useState<number>(1);

  function getRolesSelector(): JSX.Element {
    const rows = [];
    if (listWorkersEditorViewStore.roles) {
      for (const d of listWorkersEditorViewStore.roles) {
        rows.push(
          <Radio key={d.uid} value={d.uid}>
            {d.title}
          </Radio>,
        );
      }
    }
    return (
      <Radio.Group
        onChange={(event: RadioChangeEvent) => {
          setRole(Number(event.target.value));
        }}
        value={role}
        style={{marginBottom: 20}}
      >
        {rows}
      </Radio.Group>
    );
  }

  function buttonCreateWorker() {
    return (
      <Button
        onClick = {() => {
          setName("");
          setWallet("");
          setRole(1);
          setHourRate(0);
          setIsModalCreateWorkersVisible(true);
        }}
      >
        Create worker
      </Button>
    );
  }

  function createWorkerModalView(): JSX.Element {

    return <Modal
      title={"Create a new worker"}
      visible={isModalCreateWorkersVisible}
      onOk={async () => {
        setIsModalCreateWorkersVisible(false);
        await workersPageViewStore.createWorker(wallet, hourRate, name, role);
        await recreateWorkersListItems();
      }}
      onCancel={() => {
        setIsModalCreateWorkersVisible(false);
      }}
    >
      <Layout>
        <div>Name</div>
        <Input
          width="100%"
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
          }}
        />
      </Layout>
      <Layout>
        <div>Wallet</div>
        <Input
          width="100%"
          value={wallet}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setWallet(event.target.value);
          }}
        />
      </Layout>
      <Layout>
        <div>Role</div>
        {getRolesSelector()}
      </Layout>
      <Layout>
        <div>Hour rate</div>
        <Input
          width="100%"
          value={hourRate}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setHourRate(Number(event.target.value));
          }}
        />
      </Layout>
    </Modal>;
  }
  //endregion Create a new worker

  //region Move selected workers to the given department

  /** All selected workers (the workers are selected using checkboxes) */
  const [selectedWorkerUids, setSelectedWorkerUids] = useState<string[]>([]);
  const [cleanupSelection, setCleanupSelection] = useState<number>(0);
  const [selectedDepartment, setSelectedDepartment] = useState<{value: number, label: string} | undefined>();
  function buttonMoveSelectedWorkersToDepartment() {
    return (
      <Button
        hidden={!selectedWorkerUids || selectedWorkerUids.length === 0 || !selectedDepartment?.value}
        onClick = {async () => {
          // move selected worker to the selected department
          await workersPageViewStore.moveWorkersToDepartment(
            selectedWorkerUids.map(x => BigNumber.from(x)),
            selectedDepartment?.value || 0
          );

          await recreateWorkersListItems();
          setSelectedWorkerUids([]);

          // use a tricky way to clean up selection in the child component
          // we need to increase the counter for cleaning
          setCleanupSelection(cleanupSelection + 1);
        }}
      >
        Move
      </Button>
    );
  }

  /** User has selected/unselected a worker. Enable/disable "Move selected workers to department" button*/
  async function onChangeSelection(selectedWorkerUids: string[]) {
    setSelectedWorkerUids(selectedWorkerUids);
  }
  //endregion Move selected workers to the given department

  return (
    <div className="container">
      <h2>All workers</h2>
      <Space size={[8, 16]} wrap direction="vertical">
        <Space>
          {buttonCreateWorker()}
          <Select
            allowClear={true}
            style={{ width: 240 }}
            options={
              listDepartments.map(x => (
                {
                  value: x.uid,
                  label: x.title
                }
              ))
            }
            onSelect={(key: string | number, option: React.SetStateAction<{ value: number; label: string; } | undefined>) => {
              setSelectedDepartment(option);
              console.log(option);
            }}
            onClear={() => {
              setSelectedDepartment(undefined);
            }}
          />

        </Space>
        <Space>
          {selectedWorkerUids && selectedWorkerUids.length !== 0 && selectedDepartment?.value
           && `Move ${selectedWorkerUids} to ${selectedDepartment?.label}`}
          {buttonMoveSelectedWorkersToDepartment()}
        </Space>

        <ListWorkersEditor
          workerUids={listWorkers}
          useMultiSelection={true}
          onChangeSelection={onChangeSelection}
          cleanupSelection={cleanupSelection}
        />
        {createWorkerModalView()}
      </Space>
    </div>
  );
});
