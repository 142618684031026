import React, { useEffect, useState } from "react";
import { AmountUtils } from "../../logic/utils/AmountUtils";
import {useStores, web3Store} from "../../stores";
import { BigNumber } from "ethers";
import { Alert, Button, Checkbox, Space } from "antd";
import { PaymentTable } from "../../components/PaymentTable";
import { observer } from "mobx-react";

/**
 * SCB-112
 * Show week budget in ST and balance of the account in ST.
 * Button "start new epoch" with checkbox "make payment"
 * Button "make payment"
 * TODO: List of departments with buttons "make payment" for each
 */
export const PaymentsPage = observer(() => {
  const { paymentsPageViewStore } = useStores();

  const [defaultWeekBudgetST, setDefaultWeekBudgetST] = useState<BigNumber>(BigNumber.from(0));
  const [salaryToken, setSalaryToken] = useState<string>("");
  const [balanceDefaultST, setBalanceDefaultST] = useState<BigNumber | undefined>(undefined);
  const [makePaymentImmediately, setMakePaymentImmediately] = useState(true);
  const [countPerformedPaymentsForRefresh, setCountPerformedPaymentsForRefresh] = useState(0);

  useEffect(() => {
    if (web3Store.provider) {
      const fetchData = async () => {
        await paymentsPageViewStore.fetchBudgetValues();
        setDefaultWeekBudgetST(paymentsPageViewStore.defaultWeekBudgetST);
        setSalaryToken(paymentsPageViewStore.salaryTokenAddress);
        setBalanceDefaultST(paymentsPageViewStore.availableBalanceST);
      };
      fetchData().catch(console.error);
    }
  }, [web3Store.provider, countPerformedPaymentsForRefresh]);

  function refresh() {
    setCountPerformedPaymentsForRefresh(countPerformedPaymentsForRefresh + 1);
  }

  return (
    <div className="container">
      <h2>Default budget and balance</h2>
      <div>
        Salary token: {salaryToken}
        , {paymentsPageViewStore.defaultSalaryTokenName}
        , decimals={paymentsPageViewStore.defaultSalaryTokenDecimals}
      </div>
      <div>
        Budget: {AmountUtils.amountToString(defaultWeekBudgetST
          , paymentsPageViewStore.defaultSalaryTokenDecimals
        )} [{paymentsPageViewStore.getDefaultST()}]
      </div>
      <Alert
        type="success"
        message={`Balance: ${AmountUtils.amountToString(
          balanceDefaultST
          , paymentsPageViewStore.defaultSalaryTokenDecimals
        )}  [${paymentsPageViewStore.getDefaultST()}]`}
      />
      <Alert
        type="info"
        message={`Current cost of 1 USD: ${AmountUtils.amountToString(
          paymentsPageViewStore.priceUsdDefaultST
          , paymentsPageViewStore.defaultSalaryTokenDecimals
        )} [${paymentsPageViewStore.getDefaultST()}]`}
      />

      {paymentsPageViewStore.salaryTokenAddress !== paymentsPageViewStore.weekSalaryTokenAddress && (
        <>
          <h2>Data for the current epoch</h2>
          <div>
            Salary token: {paymentsPageViewStore.weekSalaryTokenAddress}
              , decimals={paymentsPageViewStore.weekSalaryTokenDecimals}
              , {paymentsPageViewStore.getWeekST()
            }
          </div>
          <Alert
            type="success"
            message={`Balance: ${AmountUtils.amountToString(
              paymentsPageViewStore.availableBalanceWeekST
              , paymentsPageViewStore.defaultSalaryTokenDecimals
            )} [${paymentsPageViewStore.getWeekST()}]`}
          />
          <Alert
            type="info"
            message={`Current cost of 1 USD: ${AmountUtils.amountToString(
              paymentsPageViewStore.priceUsdWeekST,
              paymentsPageViewStore.defaultSalaryTokenDecimals
            )} [${paymentsPageViewStore.getWeekST()}]`}
          />
          <div>&nbsp;</div>
        </>
      )}

      <Space size={[8, 16]} wrap direction="vertical">
        <h2>Start new epoch</h2>
        <Checkbox
          checked={makePaymentImmediately}
          onChange={(e) => {
            setMakePaymentImmediately(e.target.checked);
          }}
        >
          Pay salary to whole company after starting new epoch
        </Checkbox>
        <Button
          loading={paymentsPageViewStore.isFetchingRequestData}
          disabled={paymentsPageViewStore.isFetchingRequestData}
          type="primary"
          onClick={async () => {
            await paymentsPageViewStore.startEpoch(makePaymentImmediately);
            refresh();
          }}
        >
          Start epoch
        </Button>

        <h2>Payment</h2>
        <Button
          loading={paymentsPageViewStore.isFetchingRequestData}
          disabled={paymentsPageViewStore.isFetchingRequestData}
          type="primary"
          onClick={async () => {
            await paymentsPageViewStore.paySalaryToCompany();
            refresh();
          }}
        >
          Pay salary to the whole company
        </Button>

        <h3>Unpaid amounts, USD</h3>
        <PaymentTable
          costUsdInST={paymentsPageViewStore.priceUsdWeekST}
          onPayHandler={async () => {
            refresh();
          }}
        />
      </Space>
    </div>
  );
});
