import { ContractsSingleton } from "../core/ContractsSingleton";
import { SignerInfo } from "../core/DataTypes";
import { BigNumber } from "ethers";

export class SignerUtils {
  static async getSignerInfo(cs: ContractsSingleton, wallet: string): Promise<SignerInfo> {
    const workerUid = await cs.companyManager.getWorkerByWallet(wallet);
    if (workerUid.eq(0)) {
      return { wallet };
    }

    const workerData = await cs.companyManager.workersData(workerUid);
    const departmentUid = await cs.companyManager.workerToDepartment(workerUid);

    if (workerData.hourRate == 0) {
      return {
        wallet: wallet,
        workerData: {
          name: workerData.name,
          workerUid: workerData.uid,
          roleUid: workerData.role,
          hourRate: BigNumber.from(workerData.hourRate),
          wallet: wallet,
          departmentUid: departmentUid,
        },
        departmentTitle: (await cs.companyManager.departmentsData(departmentUid)).title,
        roleTitle: (await cs.companyManager.rolesData(workerData.role)).title,
      };
    } else {
      const debtTokenInfo = await cs.companyManager.getWorkerDebtTokenInfo(workerUid);
      return {
        wallet: wallet,
        workerData: {
          name: workerData.name,
          workerUid: workerData.uid,
          roleUid: workerData.role,
          hourRate: debtTokenInfo.hourRateEx,
          wallet: wallet,
          departmentUid: departmentUid,
          debtToken: debtTokenInfo.debtToken
        },
        departmentTitle: (await cs.companyManager.departmentsData(departmentUid)).title,
        roleTitle: (await cs.companyManager.rolesData(workerData.role)).title,
      };
    }
  }
}
