import React, { useEffect, useState } from "react";
import { ITokenInfo, RequestInfo, UnpaidDebt } from "../../logic";
import { Table } from "antd";
import { web3Store } from "../../stores";
import { listDebtsViewStore } from "../../stores/views/ListDebtsViewStore";
import { DebtTokenUtils } from "../../logic/utils/DebtTokenUtils";
import { formatUnits } from "ethers/lib/utils";

interface ListItem {
  key: string;
  debt: UnpaidDebt;
  request?: RequestInfo;
  token?: ITokenInfo;
}

type Props = {
  debts: UnpaidDebt[];
};

/**
 *  Display list of debts of the given worker
 */
export const ListDebts: React.FC<Props> = (props) => {
  const [listItems, setListItem] = useState<ListItem[]>([]);
  useEffect(() => {
    if (web3Store.provider) {
      const fetchRequestData = async () => {
        await listDebtsViewStore.fetchRequestsInfo(props.debts.map((x) => x.requestUid));
        if (listDebtsViewStore.requests) {
          const map: Map<string, RequestInfo> = new Map<string, RequestInfo>();
          for (const r of listDebtsViewStore.requests) {
            map.set(r.requestUid.toString(), r);
          }
          const list: ListItem[] = await Promise.all(
            props.debts.map(
              async (x) => ({
                key: x.requestUid.toString(),
                debt: x,
                request: map.get(x.requestUid.toString()),
                token: DebtTokenUtils.isDefaultDebtToken(x.debtToken)
                  ? undefined
                  : await listDebtsViewStore.getDebtTokenInfo(x.debtToken || "")
              })
            )
          );
          setListItem(list);
        }
      };
      fetchRequestData().catch(console.error);
    }
  }, [props, listDebtsViewStore, web3Store.provider]);

  const columns = [
    {
      title: "Request ID",
      dataIndex: "request",
      key: "request",
      render: (text: string, item: ListItem) => <div>{item.debt.requestUid.toString()}</div>,
    },
    {
      title: "Debt ID",
      dataIndex: "debt",
      key: "debt",
      render: (text: string, item: ListItem) => <div>{item.debt.debtUid.toString()}</div>,
    },
    {
      title: "Epoch",
      dataIndex: "epoch",
      key: "epoch",
      render: (text: string, item: ListItem) => <div>{item.request?.epoch?.toString()}</div>,
    },
    {
      title: "Count hours",
      dataIndex: "countHours",
      key: "countHours",
      render: (text: string, item: ListItem) => <div>{item.request?.countHours}</div>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: string, item: ListItem) => <div>{
        item.token && item.debt.unpaidAmount
          ? Math.round(+formatUnits(
            item.debt.unpaidAmount.amount.sub(item.debt.unpaidAmount.paidAmount),
            item.token.decimals
          )) + ` ${item.token.name}`
          : "$" + item.debt.amountUSD.toString()
      }</div>,
    },
  ];
  return (
    <div>
      {!listDebtsViewStore.requests || listDebtsViewStore.requests.length === 0 ? (
        <div>No debts</div>
      ) : (
        <Table<ListItem> columns={columns} dataSource={listItems} />
      )}
    </div>
  );
};
