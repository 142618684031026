/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type { IDebtsManager, IDebtsManagerInterface } from "../IDebtsManager";

const _abi = [
  {
    inputs: [],
    name: "ErrorAccessDenied",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorActionIsAlreadyDone",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "",
        type: "uint16",
      },
    ],
    name: "ErrorAlreadyHead",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorArraysHaveDifferentLengths",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorDataNotChanged",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "worker",
        type: "uint64",
      },
    ],
    name: "ErrorDebtTokenIsUsed",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "debtToken",
        type: "address",
      },
    ],
    name: "ErrorDebtTokenNotEnabled",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorEmptyArrayNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorEmptyString",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorGovernanceOrDepartmentHeadOnly",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorIncorrectAmount",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DebtUid",
        name: "debtUid",
        type: "uint64",
      },
    ],
    name: "ErrorIncorrectDebtConversion",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.HourRate",
        name: "rate",
        type: "uint16",
      },
    ],
    name: "ErrorIncorrectRate",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "providedAddress",
        type: "address",
      },
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "worker",
        type: "uint64",
      },
    ],
    name: "ErrorNotApprover",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorNotEnoughFund",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "debtToken",
        type: "address",
      },
    ],
    name: "ErrorPriceOracleForDebtTokenNotFound",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "currentLength",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxAllowedLength",
        type: "uint256",
      },
    ],
    name: "ErrorTooLongString",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "request",
        type: "uint256",
      },
    ],
    name: "ErrorUnapprovedRequestExists",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "uid",
        type: "uint16",
      },
    ],
    name: "ErrorUnknownDepartment",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "uid",
        type: "uint256",
      },
    ],
    name: "ErrorUnknownRequest",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "uid",
        type: "uint64",
      },
    ],
    name: "ErrorWorkerNotFound",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "errorCode",
        type: "uint256",
      },
    ],
    name: "ErrorZeroAddress",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "ErrorZeroPrice",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "errorCode",
        type: "uint256",
      },
    ],
    name: "ErrorZeroValueNotAllowed",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "requestUid_",
        type: "uint256",
      },
    ],
    name: "addDebt",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "requestUid_",
        type: "uint256",
      },
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "workerUid_",
        type: "uint64",
      },
      {
        internalType: "uint32",
        name: "countHours",
        type: "uint32",
      },
      {
        internalType: "string",
        name: "descriptionUrl",
        type: "string",
      },
    ],
    name: "addRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "departmentUid_",
        type: "uint16",
      },
      {
        internalType: "IClerkTypes.RoleUid",
        name: "roleUid_",
        type: "uint16",
      },
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "workerUid_",
        type: "uint64",
      },
    ],
    name: "convertUnpaidDebts",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "currentEpoch",
    outputs: [
      {
        internalType: "IClerkTypes.EpochType",
        name: "",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DebtUid",
        name: "",
        type: "uint64",
      },
    ],
    name: "debtsToRequests",
    outputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "departments",
    outputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "workerUid",
        type: "uint64",
      },
      {
        internalType: "address",
        name: "debtToken",
        type: "address",
      },
    ],
    name: "earnedAmountsInDebtTokens",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "firstEpoch",
    outputs: [
      {
        internalType: "IClerkTypes.EpochType",
        name: "",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "requestUid_",
        type: "uint256",
      },
    ],
    name: "getRequestWorkerAndRole",
    outputs: [
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "worker",
        type: "uint64",
      },
      {
        internalType: "IClerkTypes.RoleUid",
        name: "role",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lengthDepartments",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "departmentUid",
        type: "uint16",
      },
    ],
    name: "lengthWeekBudgetLimitsForRolesST",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxRoleValueInAllTimes",
    outputs: [
      {
        internalType: "IClerkTypes.RoleUid",
        name: "",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pay",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "departmentUid",
        type: "uint16",
      },
      {
        internalType: "IClerkTypes.RoleUid",
        name: "role",
        type: "uint16",
      },
      {
        internalType: "uint64",
        name: "indexDebt0",
        type: "uint64",
      },
    ],
    name: "payDebt",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "departmentUid",
        type: "uint16",
      },
    ],
    name: "payForDepartment",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "departmentUid",
        type: "uint16",
      },
      {
        internalType: "IClerkTypes.RoleUid",
        name: "role",
        type: "uint16",
      },
    ],
    name: "payForRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "",
        type: "uint256",
      },
    ],
    name: "requestDebtTokens",
    outputs: [
      {
        internalType: "address",
        name: "debtTokenAddress",
        type: "address",
      },
      {
        internalType: "IClerkTypes.HourRateEx",
        name: "hourRateEx",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "",
        type: "uint256",
      },
    ],
    name: "requestsData",
    outputs: [
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "worker",
        type: "uint64",
      },
      {
        internalType: "IClerkTypes.RoleUid",
        name: "role",
        type: "uint16",
      },
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "department",
        type: "uint16",
      },
      {
        internalType: "IClerkTypes.HourRate",
        name: "hourRate",
        type: "uint16",
      },
      {
        internalType: "uint32",
        name: "countHours",
        type: "uint32",
      },
      {
        internalType: "IClerkTypes.EpochType",
        name: "epoch",
        type: "uint16",
      },
      {
        internalType: "string",
        name: "descriptionUrl",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "",
        type: "uint256",
      },
    ],
    name: "requestsToDebts",
    outputs: [
      {
        internalType: "IClerkTypes.DebtUid",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "requestUid_",
        type: "uint256",
      },
    ],
    name: "revokeDebtForRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "",
        type: "uint16",
      },
      {
        internalType: "IClerkTypes.RoleUid",
        name: "",
        type: "uint16",
      },
    ],
    name: "roleDebts",
    outputs: [
      {
        internalType: "uint64",
        name: "totalCountDebts",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "firstUnpaidDebtIndex0",
        type: "uint64",
      },
      {
        internalType: "IClerkTypes.AmountUSD",
        name: "amountUnpaidTotalUSD",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "",
        type: "uint16",
      },
      {
        internalType: "IClerkTypes.RoleUid",
        name: "",
        type: "uint16",
      },
      {
        internalType: "IClerkTypes.NullableValue64",
        name: "",
        type: "uint64",
      },
    ],
    name: "roleDebtsList",
    outputs: [
      {
        internalType: "IClerkTypes.DebtUid",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bool",
        name: "paySalaryImmediately",
        type: "bool",
      },
    ],
    name: "startEpoch",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "",
        type: "uint64",
      },
    ],
    name: "statForWorkers",
    outputs: [
      {
        internalType: "uint32",
        name: "workedHours",
        type: "uint32",
      },
      {
        internalType: "IClerkTypes.AmountUSD",
        name: "earnedDollars",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DebtUid",
        name: "",
        type: "uint64",
      },
    ],
    name: "unpaidAmounts",
    outputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "paidAmount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DebtUid",
        name: "",
        type: "uint64",
      },
    ],
    name: "unpaidAmountsUSD",
    outputs: [
      {
        internalType: "IClerkTypes.AmountUSD",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "",
        type: "uint16",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "weekBudgetLimitsForRolesST",
    outputs: [
      {
        internalType: "IClerkTypes.AmountST",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "",
        type: "uint16",
      },
    ],
    name: "weekBudgetST",
    outputs: [
      {
        internalType: "IClerkTypes.AmountST",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "",
        type: "uint16",
      },
    ],
    name: "weekDepartmentUidsToPay",
    outputs: [
      {
        internalType: "IClerkTypes.EpochType",
        name: "",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "weekSalaryToken",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "value",
        type: "uint64",
      },
    ],
    name: "wrapToNullableValue64",
    outputs: [
      {
        internalType: "IClerkTypes.NullableValue64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
];

export class IDebtsManager__factory {
  static readonly abi = _abi;
  static createInterface(): IDebtsManagerInterface {
    return new utils.Interface(_abi) as IDebtsManagerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IDebtsManager {
    return new Contract(address, _abi, signerOrProvider) as IDebtsManager;
  }
}
