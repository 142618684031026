import { makeAutoObservable, runInAction } from "mobx";
import { ContractsSingleton, DepartmentData, RoleData } from "../../logic";
import { web3Store } from "../web3-store";
import { DebtUtils } from "../../logic/utils/DebtUtils";
import { BigNumber } from "ethers";

class PaymentTableViewStore {
  isFetchingData = false;

  departments: DepartmentData[] | null = null;
  roles: RoleData[] | null = null;
  mapWeekBudgetsForDepartmentsST: Map<string, BigNumber> | undefined;

  /** key = departmentUid.toString()
   *  value: unpaid amountUSD for each role, for the department
   * */
  unpaidAmounts: Map<string, number[]> | null = null;

  private readonly web3Store = web3Store;

  constructor() {
    makeAutoObservable(this);
  }

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  //region Load data
  /** Load unpaid amounts for all departments and roles */
  async fetchUnpaidAmounts() {
    runInAction(() => {
      this.isFetchingData = true;
    });
    try {
      const cs = await this.core();
      const r = await DebtUtils.getUnpaidAmounts(cs);
      const db = await cs.batchReader.getDebtDepartmentsInfo();

      const mapWeekBudgetsForDepartmentsST: Map<string, BigNumber> = new Map<string, BigNumber>();
      for (let i = 0; i < db.outDepartmentUids.length; ++i) {
        mapWeekBudgetsForDepartmentsST.set(db.outDepartmentUids[i].toString(), db.outBudgetAmountST[i]);
      }

      runInAction(() => {
        this.unpaidAmounts = r.unpaidAmounts;
        this.departments = r.departments;
        this.roles = r.roles;
        this.mapWeekBudgetsForDepartmentsST = mapWeekBudgetsForDepartmentsST;
      });
      return r;
    } finally {
      runInAction(() => {
        this.isFetchingData = false;
      });
    }
  }

  async payForDepartment(departmentUid: number) {
    const cs = await this.core();
    await cs.runAndWait(() => cs.debtsManager.payForDepartment(departmentUid));
    //refresh data
    await this.fetchUnpaidAmounts();
  }

  //endregion Load data
}

export const paymentTableViewStore = new PaymentTableViewStore();
