import { makeAutoObservable, runInAction } from "mobx";
import { ContractsSingleton, RoleData } from "../../logic";
import { web3Store } from "../web3-store";
import { BudgetUtils } from "../../logic/utils/BudgetUtils";

class DepartmentBudgetViewStore {
  isFetchingData = false;
  departmentUid: number | null = null;
  roles: RoleData[] | null = null;
  roleLimits: number[] | null = null;

  private readonly web3Store = web3Store;

  constructor() {
    makeAutoObservable(this);
  }

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  //region Load data

  /** Load role limits for the given department */
  async fetchRoleLimits(departmentUid: number) {
    runInAction(() => {
      this.isFetchingData = true;
    });
    try {
      const cs = await this.core();
      const roles = await cs.cachedData.getRolesList();
      const roleLimits = await BudgetUtils.getRoleLimits(cs, departmentUid);
      runInAction(() => {
        this.departmentUid = departmentUid;
        this.roles = roles;
        this.roleLimits = roleLimits;
      });
    } finally {
      runInAction(() => {
        this.isFetchingData = false;
      });
    }
  }

  //endregion Load data

  //region Update roles limits
  async updateLimits(departmentUid: number, roleLimits: number[]) {
    runInAction(() => {
      this.isFetchingData = true;
    });
    try {
      const cs = await this.core();
      await cs.runAndWait(() => cs.companyManager.setRoleShares(departmentUid, roleLimits));
    } finally {
      runInAction(() => {
        this.isFetchingData = false;
      });
    }
    await this.fetchRoleLimits(departmentUid);
  }

  //endregion Update roles limits
}

export const departmentBudgetViewStore = new DepartmentBudgetViewStore();
