import {Button, InputNumber, Space} from "antd";
import React, {useEffect} from "react";
import {epochNavigatorViewStore, web3Store} from "../../stores";
import {observer} from "mobx-react";

/**
 * Display current epoch
 * Allow to navigate by epochs.
 */
export const EpochNavigator = observer(() => {
  //const { epochNavigatorViewStore } = useStores()

  useEffect(() => {
    if (web3Store.provider) {
      const fetchData = async () => {
        await epochNavigatorViewStore.fetchCurrentEpoch();
      };
      fetchData().catch(console.error);
    }
  }, [web3Store.provider, epochNavigatorViewStore.currentEpoch]);

  return (
    <Space>
      <Button
        type={epochNavigatorViewStore.selectedEpoch === epochNavigatorViewStore.currentEpoch ? "default" : "primary"}
        onClick={() => (epochNavigatorViewStore.selectedEpoch = epochNavigatorViewStore.currentEpoch)}
      >
        Current epoch: {epochNavigatorViewStore.currentEpoch?.toString()}
      </Button>
      Selected epoch:&nbsp;
      <InputNumber
        size="small"
        value={epochNavigatorViewStore.selectedEpoch}
        onChange={async (newValue: number) => {
          epochNavigatorViewStore.selectedEpoch = newValue;
        }}
      />
    </Space>
  );
});
