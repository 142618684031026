import { makeAutoObservable, runInAction } from "mobx";
import { DepartmentData } from "../../logic";
import { BigNumber } from "ethers";
import { departmentPropertiesEditorChainStore } from "../chain-stores/DepartmentPropertiesEditorChainStore";

class DepartmentPropertiesEditorViewStore {
  private readonly chainStore = departmentPropertiesEditorChainStore;

  isFetchingRequestData = false;

  department: DepartmentData | undefined;
  currentOptionApproveLowByHigh: boolean | undefined;

  departmentWorkerUids: BigNumber[] | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchDepartmentData(departmentUid: number) {
    const d = await departmentPropertiesEditorChainStore.fetchDepartmentData(departmentUid, false);
    runInAction(() => {
      this.department = d.department;
      this.currentOptionApproveLowByHigh = d.currentOptionApproveLowByHigh;
    });
  }

  //region Change data
  async updateTitle(departmentUid: number, newTitle: string) {
    await departmentPropertiesEditorChainStore.updateTitle(departmentUid, newTitle);
    await this.fetchDepartmentData(departmentUid);
  }

  async updateHead(departmentUid: number, newHead: string) {
    await departmentPropertiesEditorChainStore.updateHead(departmentUid, newHead);
    await this.fetchDepartmentData(departmentUid);
  }

  async updateOptionApproveLowByHigh(departmentUid: number, newValue: boolean) {
    await departmentPropertiesEditorChainStore.updateOptionApproveLowByHigh(departmentUid, newValue);
    await this.fetchDepartmentData(departmentUid);
  }

  //endregion Change data
}

export const departmentPropertiesEditorViewStore = new DepartmentPropertiesEditorViewStore();
