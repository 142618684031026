import { ZERO_ADDRESS } from "../core/DataTypes";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { listWorkersEditorViewStore } from "../../stores";
import { BigNumber } from "ethers";

export class DebtTokenUtils {
  static isDefaultDebtToken(debtToken: string | undefined): boolean {
    return !(debtToken && (debtToken !== ZERO_ADDRESS));
  }

  /**
   * Generate hourRate to display
   * i.e.
   *    1e18 tetu => 1
   *    $1 => 1
   */
  static getHourRateToDisplay(debtToken: string | undefined, hourRate: BigNumber): number {
    return (debtToken && (debtToken !== ZERO_ADDRESS))
      ? +formatUnits(
        hourRate,
        listWorkersEditorViewStore.mapTokenDecimals.get(debtToken)?.decimals || 0
      )
      : hourRate.toNumber();
  }

  /**
   * Generate address of debt token to display, i.e.
   *    address of TETU => address of TETU
   *    zero address => empty string
   */
  static getDebtTokenToEdit(debtToken: string | undefined): string {
    return (debtToken && (debtToken !== ZERO_ADDRESS))
      ? debtToken || ""
      : "";
  }

  /**
   * Reverse for getHourRateToDisplay
   * hour rate to display => hour rate as BigNumber
   */
  static getHourRateToSave(debtTokenEdited: string, hourRateEdited: number): BigNumber {
    return debtTokenEdited
      ? parseUnits(hourRateEdited.toString(), listWorkersEditorViewStore.mapTokenDecimals.get(debtTokenEdited)?.decimals || 0)
      : BigNumber.from(hourRateEdited);
  }

  /**
   * Reverse for getDebtTokenToEdit
   *   address of TETU => address of TETU
   *   empty string => zero address
   */
  static getDebtTokenToSave(debtTokenEdited: string): string {
    return debtTokenEdited || ZERO_ADDRESS;
  }
}