import {ContractsSingleton} from "../core/ContractsSingleton";
import {
  DepartmentBudgetInfo,
  DepartmentBudgetInfoValueKind,
  DepartmentData,
  DepartmentDataEmpty,
  RoleData
} from "../core/DataTypes";
import {BigNumber} from "ethers";
import {AmountUtils} from "./AmountUtils";
import {MiscUtils} from "./MiscUtils";

export class BudgetUtils {
  static async getBudgetValue(
    cs: ContractsSingleton,
    kind: DepartmentBudgetInfoValueKind,
  ): Promise<{
    departments: DepartmentData[];
    roles: RoleData[];
    departmentBudgetValues: Map<string, DepartmentBudgetInfo>;
  }> {
    switch (kind) {
    case DepartmentBudgetInfoValueKind.CURRENT_SHARE_PERCENT:
      return await BudgetUtils.getBudgetValueSharePercents(cs);
    case DepartmentBudgetInfoValueKind.CURRENT_SHARE_AMOUNT_ST:
      return await BudgetUtils.getBudgetValueShareAmountST(
        cs,
        kind,
        async () => await cs.cachedData.getDefaultWeekBudgetST(),
      );
    case DepartmentBudgetInfoValueKind.NEXT_EPOCH_AMOUNT_ST:
      return await BudgetUtils.getBudgetValueShareAmountST(
        cs,
        kind,
        async () => await cs.companyManager.getWeekBudgetST(),
      );
    case DepartmentBudgetInfoValueKind.UNSPENT_AMOUNT_ST:
      return await BudgetUtils.getBudgetValueRemainsST(cs);
    }

    throw Error("not implemented");
  }

  //region Current budget values [%]
  static async getBudgetValueSharePercents(cs: ContractsSingleton): Promise<{
    departments: DepartmentData[];
    roles: RoleData[];
    departmentBudgetValues: Map<string, DepartmentBudgetInfo>;
  }> {
    const departments: DepartmentData[] = await cs.cachedData.getListDepartment();
    const roles: RoleData[] = await cs.cachedData.getRolesList();
    const departmentBudgetValues: Map<string, DepartmentBudgetInfo> = new Map<string, DepartmentBudgetInfo>();

    const rd = await cs.companyManager.getBudgetShares();
    for (let i = 0; i < rd.outDepartmentUids.length; ++i) {
      const limits = await cs.companyManager.getMaxWeekBudgetForRolesST(
        100, // 100%
        rd.outDepartmentUids[i],
      );
      const limitsForRoles: Map<string, number> = new Map<string, number>();
      for (let i = 0; i < limits.length; ++i) {
        limitsForRoles.set(
          (i + 1).toString(), // === CompanyManager.getRoleByIndex(i)
          limits[i].toNumber(),
        );
      }

      const bi: DepartmentBudgetInfo = {
        valueKind: DepartmentBudgetInfoValueKind.CURRENT_SHARE_PERCENT,
        budgetValue: (rd.outDepartmentShares[i].toNumber() * 100) / rd.outSumShares.toNumber(),
        limitsForRoles: limitsForRoles,
        department: departments.find((x) => x.uid === rd.outDepartmentUids[i]) || DepartmentDataEmpty,
        roles: roles,
      };
      departmentBudgetValues.set(rd.outDepartmentUids[i].toString(), bi);
    }

    return {
      departments,
      roles,
      departmentBudgetValues,
    };
  }

  //endregion Current budget values [%]

  //region Current budget amounts [salary token x 1e-18]
  static async getBudgetValueShareAmountST(
    cs: ContractsSingleton,
    kind: DepartmentBudgetInfoValueKind,
    totalBudgetAmountReceiver: () => Promise<BigNumber>,
  ): Promise<{
    departments: DepartmentData[];
    roles: RoleData[];
    departmentBudgetValues: Map<string, DepartmentBudgetInfo>;
  }> {
    const departments: DepartmentData[] = await cs.cachedData.getListDepartment();
    const roles: RoleData[] = await cs.cachedData.getRolesList();
    const departmentBudgetValues: Map<string, DepartmentBudgetInfo> = new Map<string, DepartmentBudgetInfo>();

    try {
      let totalBudgetAmount = BigNumber.from(0);
      try {
        totalBudgetAmount = await totalBudgetAmountReceiver();
      } catch (e) {
        console.log("Not enough funds!", e, kind);
      }

      const rd = await cs.companyManager.getWeekDepartmentBudgetsST(totalBudgetAmount);
      for (let i = 0; i < rd.outDepartmentUids.length; ++i) {
        const budgetValue = AmountUtils.removeDecimals(rd.outAmountsST[i]);
        await MiscUtils.delay(1000);
        const limits = await cs.companyManager.getMaxWeekBudgetForRolesST(
          100, // 100%
          rd.outDepartmentUids[i],
        );
        const limitsForRoles: Map<string, number> = new Map<string, number>();
        for (let i = 0; i < limits.length; ++i) {
          limitsForRoles.set(
            (i + 1).toString(), // === CompanyManager.getRoleByIndex(i)
            (budgetValue * limits[i].toNumber()) / 100,
          );
        }

        const bi: DepartmentBudgetInfo = {
          valueKind: kind,
          budgetValue: budgetValue,
          limitsForRoles: limitsForRoles,
          department: departments.find((x) => x.uid === rd.outDepartmentUids[i]) || DepartmentDataEmpty,
          roles: roles,
        };
        departmentBudgetValues.set(rd.outDepartmentUids[i].toString(), bi);
      }
    } catch (e) {
      console.log(e, kind);
    }

    return {
      departments,
      roles,
      departmentBudgetValues,
    };
  }

  //endregion Current budget amounts [salary token x 1e-18]

  //region Remains for current epoch (data from DebtsManager)
  static async getBudgetValueRemainsST(cs: ContractsSingleton): Promise<{
    departments: DepartmentData[];
    roles: RoleData[];
    departmentBudgetValues: Map<string, DepartmentBudgetInfo>;
  }> {
    const departments: DepartmentData[] = await cs.cachedData.getListDepartment();
    const roles: RoleData[] = await cs.cachedData.getRolesList();
    const departmentBudgetValues: Map<string, DepartmentBudgetInfo> = new Map<string, DepartmentBudgetInfo>();

    const db = await cs.batchReader.getDebtDepartmentsInfo();

    for (let i = 0; i < db.outDepartmentUids.length; ++i) {
      const departmentUid = db.outDepartmentUids[i];
      if (departmentUid === 0) continue;

      const budgetValue = db.outBudgetAmountST[i];
      const limitAmounts = await cs.batchReader.getWeekBudgetLimitsForRolesST(departmentUid);
      const limitsForRoles: Map<string, number> = new Map<string, number>();
      for (let i = 0; i < limitAmounts.length; ++i) {
        limitsForRoles.set(
          (i + 1).toString(), // === CompanyManager.getRoleByIndex(i)
          AmountUtils.removeDecimals(limitAmounts[i]),
        );
      }

      const bi: DepartmentBudgetInfo = {
        valueKind: DepartmentBudgetInfoValueKind.UNSPENT_AMOUNT_ST,
        budgetValue: AmountUtils.removeDecimals(budgetValue),
        limitsForRoles: limitsForRoles,
        department: departments.find((x) => x.uid === db.outDepartmentUids[i]) || DepartmentDataEmpty,
        roles: roles,
      };
      departmentBudgetValues.set(db.outDepartmentUids[i].toString(), bi);
    }

    return {
      departments,
      roles,
      departmentBudgetValues,
    };
  }

  //endregion Remains for current epoch (data from DebtsManager)

  //region Role limits for the department
  static async getRoleLimits(cs: ContractsSingleton, departmentUid: number): Promise<number[]> {
    // there are only few roles
    // so, we can read all items one by one without adding new function to BatchReader

    const lenLimits = (await cs.companyManager.lengthRoleShares(departmentUid)).toNumber();
    const limits: number[] = await Promise.all(
      Array.from([...Array(lenLimits)].keys()).map(async (index) =>
        (await cs.companyManager.roleShares(departmentUid, index)).toNumber(),
      ),
    );
    console.log(limits);

    const lenRoles = (await cs.cachedData.getRolesList()).length;

    // we need to return an array with length equal to actual number of the roles
    if (lenLimits < lenRoles) {
      while (limits.length < lenRoles) {
        limits.push(0);
      }
    } else if (lenLimits > lenRoles) {
      while (limits.length > lenRoles) {
        limits.pop();
      }
    }

    return limits;
  }

  //endregion Role limits for the department
}
