/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IApprovalsManager,
  IApprovalsManagerInterface,
} from "../IApprovalsManager";

const _abi = [
  {
    inputs: [],
    name: "ErrorAccessDenied",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorActionIsAlreadyDone",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "",
        type: "uint16",
      },
    ],
    name: "ErrorAlreadyHead",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorApproverOrHeadOrGovOnly",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorApprovingReDelegationIsNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorArraysHaveDifferentLengths",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorCannotRemoveNotPermanentApprover",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorDataNotChanged",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "worker",
        type: "uint64",
      },
    ],
    name: "ErrorDebtTokenIsUsed",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "debtToken",
        type: "address",
      },
    ],
    name: "ErrorDebtTokenNotEnabled",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorEmptyArrayNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorEmptyString",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorGovernanceOrDepartmentHeadOnly",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorIncorrectAmount",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DebtUid",
        name: "debtUid",
        type: "uint64",
      },
    ],
    name: "ErrorIncorrectDebtConversion",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorIncorrectDelegate",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.HourRate",
        name: "rate",
        type: "uint16",
      },
    ],
    name: "ErrorIncorrectRate",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "providedAddress",
        type: "address",
      },
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "worker",
        type: "uint64",
      },
    ],
    name: "ErrorNotApprover",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "providedAddress",
        type: "address",
      },
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "worker",
        type: "uint64",
      },
    ],
    name: "ErrorNotDelegated",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorNotEnoughFund",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "debtToken",
        type: "address",
      },
    ],
    name: "ErrorPriceOracleForDebtTokenNotFound",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorTheDelegateHasSamePermission",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorThePermissionIsAlreadyDelegated",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "currentLength",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxAllowedLength",
        type: "uint256",
      },
    ],
    name: "ErrorTooLongString",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "uid",
        type: "uint16",
      },
    ],
    name: "ErrorUnknownDepartment",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "uid",
        type: "uint256",
      },
    ],
    name: "ErrorUnknownRequest",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorWorkerCannotBeApprover",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "uid",
        type: "uint64",
      },
    ],
    name: "ErrorWorkerNotFound",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "errorCode",
        type: "uint256",
      },
    ],
    name: "ErrorZeroAddress",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "ErrorZeroPrice",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "errorCode",
        type: "uint256",
      },
    ],
    name: "ErrorZeroValueNotAllowed",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "approverToWorkers",
    outputs: [
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.ApproverPair",
        name: "",
        type: "uint256",
      },
    ],
    name: "approvers",
    outputs: [
      {
        internalType: "enum IClerkTypes.ApprovePermissionKind",
        name: "kind",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "delegatedTo",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "approver_",
        type: "address",
      },
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "worker_",
        type: "uint64",
      },
    ],
    name: "getApproverKind",
    outputs: [
      {
        internalType: "IClerkTypes.ApproverKind",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "approver_",
        type: "address",
      },
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "worker_",
        type: "uint64",
      },
    ],
    name: "isApprover",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "approver_",
        type: "address",
      },
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "worker_",
        type: "uint64",
      },
    ],
    name: "isRegisteredApprover",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "approver_",
        type: "address",
      },
    ],
    name: "lengthApproverToWorkers",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "workerUid",
        type: "uint64",
      },
    ],
    name: "lengthWorkersToPermanentApprovers",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "",
        type: "uint64",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "workersToPermanentApprovers",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class IApprovalsManager__factory {
  static readonly abi = _abi;
  static createInterface(): IApprovalsManagerInterface {
    return new utils.Interface(_abi) as IApprovalsManagerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IApprovalsManager {
    return new Contract(address, _abi, signerOrProvider) as IApprovalsManager;
  }
}
