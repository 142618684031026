import {IContractsSingleton} from "./IContractsSingleton";
import {BigNumber} from "ethers";
import {DepartmentData, RoleData, WorkerData} from "./DataTypes";
import {IERC20Extended__factory} from "../../typechain";
import {AmountUtils} from "../utils/AmountUtils";

/**
 * Cache (with lazy initialization) for static data required by most views:
 * - constants
 * - current epoch
 * - workers
 * - departments
 * - department heads
 * - approvals/delegates
 * - roles
 * The data is loaded by request once time, and then it can be taken from cache.
 * It's possible to clear any part of the cache and re-read data
 */
export class AppStaticDataCache {
  /** Connection to the chain */
  private readonly _cs: IContractsSingleton;

  private _cacheCurrentEpoch?: number;
  private _cacheCurrentWorkerUid?: BigNumber;

  private readonly _workerUidByWallet: Map<string, BigNumber> = new Map<string, BigNumber>();

  /** Full list of the registered departments */
  private _departments?: Map<string, DepartmentData>;
  private readonly _departmentWorkers: Map<string, BigNumber[]> = new Map<string, BigNumber[]>();

  private _roles?: Map<string, RoleData>;
  /** all roles ordered from 0 to countRoles */
  private _rolesList?: RoleData[];

  private readonly _workersData: Map<string, WorkerData> = new Map<string, WorkerData>();

  private _weekBudgetST?: BigNumber;
  private _salaryTokenAddress?: string;

  /** 0 - the signer is not a head of any department */
  private _departmentHeadedBySigner?: number;
  private _signerIsGovernance?: boolean;

  /** Tokens: address => name*/
  private readonly _mapTokenNames: Map<string, string> = new Map<string, string>();
  private readonly _mapTokenDecimals: Map<string, number> = new Map<string, number>();

  constructor(cs: IContractsSingleton) {
    this._cs = cs;
  }

  //region Current epoch, worker
  /** Get currently active epoch */
  async getCurrentEpoch(forceReread = false): Promise<number> {
    if (forceReread) {
      return (this._cacheCurrentEpoch = await this._cs.debtsManager.currentEpoch());
    } else {
      return this._cacheCurrentEpoch || (this._cacheCurrentEpoch = await this._cs.debtsManager.currentEpoch());
    }
  }

  /** Get currently signed worker */
  async getCurrentWorkerUid(forceReread = false): Promise<BigNumber> {
    if (forceReread || !this._cacheCurrentWorkerUid) {
      this._cacheCurrentWorkerUid = await this._cs.companyManager.getWorkerByWallet(await this._cs.signer.getAddress());
    }
    return this._cacheCurrentWorkerUid;
  }

  //endregion Current epoch, worker

  //region Workers
  /** Get WorkerUid by worker's wallet
   * @param wallet
   * @param forceRereadSingle If true - reread only specified worker
   * */
  async getWorkerByWallet(wallet: string, forceRereadSingle = false): Promise<BigNumber> {
    if (!forceRereadSingle) {
      if (this._workerUidByWallet.has(wallet)) {
        return this._workerUidByWallet.get(wallet) || BigNumber.from(0);
      }
    }
    const workerUid = await this._cs.companyManager.getWorkerByWallet(wallet);
    this._workerUidByWallet.set(wallet, workerUid);

    return workerUid;
  }

  /**
   * Get data for the specified worker
   * @param workerUid
   * @param forceReread
   */
  async getWorkerInfo(workerUid: BigNumber, forceReread = false): Promise<WorkerData | undefined> {
    const key = workerUid.toString();
    if (forceReread || !this._workersData.has(key)) {
      const wd = await this._cs.companyManager.getWorkerInfo(workerUid);

      if (wd.hourRate == 0) {
        // user uses not default debt token, i.e. TETU
        const {debtToken, hourRateEx} = await this._cs.companyManager.getWorkerDebtTokenInfo(workerUid);
        this._workersData.set(key, {
          workerUid: workerUid,
          roleUid: wd.role,
          name: wd.name,
          wallet: wd.wallet,
          hourRate: hourRateEx,
          departmentUid: wd.departmentUid,
          debtToken
        });
      } else {
        // user uses default debt token (USD)
        this._workersData.set(key, {
          workerUid: workerUid,
          roleUid: wd.role,
          name: wd.name,
          wallet: wd.wallet,
          hourRate: BigNumber.from(wd.hourRate),
          departmentUid: wd.departmentUid,
          debtToken: undefined
        });
      }
    }
    return this._workersData.get(key);
  }

  //endregion Workers

  //region Departments
  /**
   * Get info about given department
   * @param departmentUid
   * @param forceRereadAll If true - re-read data for all departments
   */
  async getDepartmentData(departmentUid: number, forceRereadAll = false): Promise<DepartmentData | undefined> {
    if (forceRereadAll || !this._departments || !this._departments.has(departmentUid.toString())) {
      this._departments = await AppStaticDataCache.reloadDepartments(this._cs);
    }

    return this._departments.get(departmentUid.toString());
  }

  async getListDepartment(forceRereadAll = false): Promise<DepartmentData[]> {
    if (forceRereadAll || !this._departments) {
      this._departments = await AppStaticDataCache.reloadDepartments(this._cs);
    }
    return Array.from(this._departments.values());
  }

  /**
   * Reload list of departments from the chain
   * @param cs
   */
  static async reloadDepartments(cs: IContractsSingleton): Promise<Map<string, DepartmentData>> {
    const departments = new Map<string, DepartmentData>();
    const countDepartments = await cs.companyManager.lengthDepartments();

    // assume, that we have only few departments and we can read them all without pagination
    const ret = await cs.batchReader.getDepartments(0, countDepartments);
    for (let i = 0; i < ret.outCount.toNumber(); ++i) {
      const d: DepartmentData = {
        uid: ret.outUids[i],
        title: ret.outTitles[i],
        head: ret.outHeads[i],
      };
      departments.set(ret.outUids[i].toString(), d);
    }

    return departments;
  }

  /**
   * Get list of workers of the given department
   * @param departmentUid
   * @param forceReread
   */
  async getDepartmentWorkers(departmentUid: number, forceReread = false): Promise<BigNumber[]> {
    const key = departmentUid.toString();
    if (forceReread || !this._departmentWorkers.has(key)) {
      const workers = await this._cs.batchReader.workersOfDepartment(departmentUid);
      this._departmentWorkers.set(key, workers);
    }
    return this._departmentWorkers.get(key) || [];
  }

  //endregion Departments

  //region Roles
  /**
   * Get info about given role
   * @param roleUid
   * @param forceRereadAll If true - re-read data for all departments
   */
  async getRoleData(roleUid: number, forceRereadAll = false): Promise<RoleData | undefined> {
    if (forceRereadAll || !this._roles) {
      const r = await AppStaticDataCache.reloadRoles(this._cs);
      this._roles = r.roles;
      this._rolesList = r.rolesList;
    }

    return this._roles.get(roleUid.toString());
  }

  private static async reloadRoles(
    cs: IContractsSingleton,
  ): Promise<{ roles: Map<string, RoleData>; rolesList: RoleData[] }> {
    const roles = new Map<string, RoleData>();
    const rolesList = [];

    const countDepartments = await cs.companyManager.lengthDepartments();

    // assume, that we have only few departments and we can read them all without pagination
    const ret = await cs.batchReader.getRoles(0, countDepartments);
    for (let i = 0; i < ret.outCount; ++i) {
      const d: RoleData = {
        uid: ret.outUids[i],
        title: ret.outTitles[i],
        countApprovals: ret.outCountApprovals[i],
      };
      roles.set(ret.outUids[i].toString(), d);
      rolesList.push(d);
    }

    return {roles, rolesList};
  }

  async getRolesList(forceReread = false): Promise<RoleData[]> {
    if (forceReread || !this._rolesList) {
      const r = await AppStaticDataCache.reloadRoles(this._cs);
      this._roles = r.roles;
      this._rolesList = r.rolesList;
    }
    return this._rolesList;
  }

  //endregion Roles

  //region Week budget
  /** Get currently assigned total week budget of the company in salary tokens */
  async getDefaultWeekBudgetST(forceReread = false): Promise<BigNumber> {
    if (forceReread || !this._weekBudgetST) {
      this._weekBudgetST = await this._cs.companyManager.weekBudgetST();
    }
    return this._weekBudgetST;
  }

  /** Get currently assigned salary token */
  async getDefaultSalaryToken(forceReread = false): Promise<string> {
    if (forceReread || !this._salaryTokenAddress) {
      this._salaryTokenAddress = await this._cs.companyManager.salaryToken();
    }
    return this._salaryTokenAddress;
  }

  //endregion Week budget

  //region Permissions
  /** Return the department headed by the signer */
  async getDepartmentHeadedBySigner(forceRefresh = false): Promise<number | undefined> {
    if (forceRefresh || !this._departmentHeadedBySigner) {
      const signer = await this._cs.signer.getAddress();
      this._departmentHeadedBySigner = undefined;

      for (const d of await this.getListDepartment(forceRefresh)) {
        if (d.head === signer) {
          this._departmentHeadedBySigner = d.uid;
        }
      }
    }

    return this._departmentHeadedBySigner;
  }

  /** Check if the signer is governance */
  async isSignerGovernance(forceRefresh = false): Promise<boolean | undefined> {
    console.log("signer", await this._cs.signer.getAddress());
    console.log("governance", await this._cs.controller.governance());
    if (forceRefresh || !this._signerIsGovernance) {
      const signer = await this._cs.signer.getAddress();
      this._signerIsGovernance = signer === (await this._cs.controller.governance());
    }

    return this._signerIsGovernance;
  }

  //endregion Permissions

  //region Token names
  async getTokenName(tokenAddress: string): Promise<string> {
    let name = this._mapTokenNames.get(tokenAddress);
    if (!name && !AmountUtils.isZeroAddress(tokenAddress)) {
      try {
        name = await IERC20Extended__factory.connect(tokenAddress, this._cs.signer).symbol();
        this._mapTokenNames.set(tokenAddress, name);
      } catch (e) {
        console.log(e);
        return "token???";
      }
    }

    return name || "token???";
  }

  async getTokenDecimals(tokenAddress: string): Promise<number> {
    let dec = this._mapTokenDecimals.get(tokenAddress);
    if (!dec && !AmountUtils.isZeroAddress(tokenAddress)) {
      try {
        dec = await IERC20Extended__factory.connect(tokenAddress, this._cs.signer).decimals();
        this._mapTokenDecimals.set(tokenAddress, dec);
      } catch (e) {
        console.log("getTokenDecimals", e);
        return 18; //TODO: 18 ???
      }
    }

    console.log("getTokenDecimals-default", tokenAddress);
    return dec || 18; //TODO: 18 ??
  }

  //endregion Token names
}

