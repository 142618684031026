import { makeAutoObservable, runInAction } from "mobx";
import { departmentPageChainStore } from "../chain-stores";
import { DepartmentData } from "../../logic";
import { BigNumber } from "ethers";

class DepartmentPageViewStore {
  private readonly chainStore = departmentPageChainStore;

  isFetchingData = false;

  /** Available departments = all departments for governance and any head of any department
   * */
  departments: DepartmentData[] | undefined;

  departmentWorkerUids: BigNumber[] | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchAvailableDepartments() {
    this.isFetchingData = true;
    try {
      const dd = await departmentPageChainStore.fetchAvailableDepartments();
      runInAction(() => {
        this.departments = dd;
      });
    } finally {
      this.isFetchingData = false;
    }
  }

  async fetchDepartmentWorkers(departmentUid: number) {
    this.isFetchingData = true;
    try {
      const ww = await departmentPageChainStore.fetchDepartmentWorkers(departmentUid);
      runInAction(() => {
        this.departmentWorkerUids = ww;
      });
    } finally {
      this.isFetchingData = false;
    }
  }
}

export const departmentPageViewStore = new DepartmentPageViewStore();
