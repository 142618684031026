import { BigNumber } from "ethers";
import { WorkerData, WorkerDataWithTitles } from "../core/DataTypes";
import { PaginationUtils } from "./PaginationUtils";
import { ContractsSingleton } from "../core/ContractsSingleton";

export class WorkerUtils {
  static async getWorkers(cs: ContractsSingleton, workerUids: BigNumber[]): Promise<WorkerDataWithTitles[]> {
    // get worker-data for each approve-target-worker
    const dest: WorkerData[] = [];
    for (let i = 0; i < workerUids.length; i += PaginationUtils.defaultPageSize) {
      const chunk = workerUids.slice(i, i + PaginationUtils.defaultPageSize);
      const r = await cs.batchReader.getWorkerInfoBatch(chunk);
      for (let j = 0; j < r.outNames.length; ++j) {
        dest.push({
          workerUid: chunk[j],
          hourRate: r.outHourRates[j],
          roleUid: r.outRoles[j],
          name: r.outNames[j],
          wallet: r.outWallets[j],
          departmentUid: r.outDepartmentUids[j],
          debtToken: r.outDebtTokens[j]
        });
      }
    }

    const ret = await Promise.all(
      dest.map(async (x) => ({
        name: x.name,
        wallet: x.wallet,
        workerUid: x.workerUid,
        roleUid: x.roleUid,
        departmentUid: x.departmentUid,
        hourRate: x.hourRate,
        debtToken: x.debtToken,
        departmentTitle: (await cs.cachedData.getDepartmentData(x.departmentUid))?.title || "",
        roleTitle: (await cs.cachedData.getRoleData(x.roleUid))?.title || "",
      })),
    );
    return ret;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static async getAllWorkers(cs: ContractsSingleton): Promise<BigNumber[]> {
    console.log("Batch reader address:", cs.batchReader.address);
    return await cs.batchReader.getAllWorkers();
  }

  static async createWorker(
    cs: ContractsSingleton,
    wallet: string,
    hourRate: number,
    name: string,
    role: number
  ) : Promise<WorkerData | undefined> {
    await cs.runAndWait(() => cs.companyManager.addWorker(wallet, hourRate, name, role));
    const workerUid = await cs.companyManager.getWorkerByWallet(wallet);
    return cs.cachedData.getWorkerInfo(workerUid);
  }
}
