import { observer } from "mobx-react";
import { epochNavigatorViewStore, useStores, web3Store } from "../../stores";
import React, { Key, useEffect, useState } from "react";
import { Alert, Button, Input, Modal, Space, Table, Tag } from "antd";
import { ApproveTargetInfo, RequestStatus, RequestUtils } from "../../logic";
import { ApproveTargetUtils } from "../../logic/utils/ApproveTargetUtils";
import { ListApprovals } from "../../components/ListApprovals";

/** ApproveTargetInfo + unique key (=== workerUid) */
interface ListItem {
  key: string;
  data: ApproveTargetInfo;
}

/**
 * Show list of approve targets.
 * It contains all workers which requests can be approved by the signer.
 * If a request is created, the table provides buttons Approve and Disapprove for the worker.
 */
export const ApproveRequestsPage = observer(() => {
  const { approveRequestsViewStore } = useStores();

  //region Load data
  const [listItems, setListItems] = useState<ListItem[]>([]);

  /** Load all approve-tasks in background */
  useEffect(() => {
    if (web3Store.provider) {
      const fetchRequestData = async () => {
        await approveRequestsViewStore.fetchApproveTargets(epochNavigatorViewStore.selectedEpoch);
        reloadListItems();
      };
      fetchRequestData().catch(console.error);
    }
  }, [approveRequestsViewStore, web3Store.provider, epochNavigatorViewStore.selectedEpoch]);

  /** Convert array of ApproveTargetInfo to array of list items with unique key */
  function reloadListItems() {
    const approveTargets: ApproveTargetInfo[] = approveRequestsViewStore.approveTargets;
    const dest = approveTargets.map((x) => ({
      key: x.workerData.workerUid.toString(),
      data: x,
    }));
    setListItems(dest);
  }

  //endregion Load data

  //region Errors
  /** The user hides the error message, we don't need to show that message anymore */
  const onClose = () => {
    approveRequestsViewStore.hideError();
  };

  const elementDisplayError = (
    <>
      <Alert message={approveRequestsViewStore.error} type="error" closable onClose={onClose} />
    </>
  );
  //endregion Errors

  //region Modal window to enter reason of rejection
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [listItemToReject, setListItemToReject] = useState({});

  const showModal = (listItem: ListItem) => {
    setListItemToReject(listItem.data);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    // make rejection
    await approveRequestsViewStore.rejectRequest(listItemToReject as ApproveTargetInfo, rejectionReason);
    reloadListItems();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //endregion Modal window to enter reason of rejection

  //region Modal window to view list of received approvals
  const showModalView = (item: ListItem) => {
    Modal.info({
      title: "List of approvals",
      content: <ListApprovals workerUid={item.data.workerData.workerUid} />,
      width: 1000,
    });
  };

  //endregion Modal window to view list of received approvals

  //region Actions for single item
  /** A button to approve request (make positive approval) */
  function buttonApprove(item: ListItem) {
    return (
      <Button
        loading={approveRequestsViewStore.isFetchingData}
        disabled={approveRequestsViewStore.isFetchingData}
        type={item.data.approval ? "default" : "primary"}
        onClick={async () => {
          await approveRequestsViewStore.approveRequest(item.data);
          reloadListItems();
        }}
      >
        Approve
      </Button>
    );
  }

  /** A button to reject request (make negative approval) */
  function buttonReject(item: ListItem) {
    return (
      <Button
        loading={approveRequestsViewStore.isFetchingData}
        disabled={approveRequestsViewStore.isFetchingData}
        type="default"
        onClick={() => showModal(item)}
      >
        Disapprove
      </Button>
    );
  }

  /** View full list of received approvals for the given worker in the modal window*/
  function buttonViewApprovals(item: ListItem) {
    return <Button onClick={() => showModalView(item)}>View approvals</Button>;
  }

  //endregion Actions for single item

  //region Actions for multiple items
  /** A button to approve request (make positive approval) */
  function buttonApproveSelected() {
    return (
      <Button
        loading={approveRequestsViewStore.isFetchingData}
        disabled={approveRequestsViewStore.isFetchingData || !checkedItems || checkedItems.length === 0}
        type="primary"
        onClick={async () => {
          await approveRequestsViewStore.approveRequestsBatch(checkedItems.map((x) => x.data));
          setCheckedItems([]);
          setRowKeys([]);
          reloadListItems();
        }}
      >
        Approve selected
      </Button>
    );
  }

  function buttonSelectUnapproved() {
    return (
      <Button
        loading={approveRequestsViewStore.isFetchingData}
        disabled={approveRequestsViewStore.isFetchingData}
        onClick={async () => {
          const dest: Key[] = [];
          const checkedItems: ListItem[] = [];
          for (const item of listItems) {
            if (!item.data.approval && item.data.request.requestStatus === RequestStatus.New_1) {
              dest.push(item.key);
              checkedItems.push(item);
            }
          }
          setCheckedItems(checkedItems);
          setRowKeys(dest);
        }}
      >
        Select unapproved
      </Button>
    );
  }

  function buttonClearSelection() {
    return (
      <Button
        loading={approveRequestsViewStore.isFetchingData}
        disabled={approveRequestsViewStore.isFetchingData}
        onClick={async () => {
          setCheckedItems([]);
          setRowKeys([]);
        }}
      >
        Clear selection
      </Button>
    );
  }

  //endregion Actions for multiple items

  //region Table
  const [selectedRowKeys, setRowKeys] = useState<Key[]>([]);
  const [checkedItems, setCheckedItems] = useState<ListItem[]>([]);

  const columns = [
    {
      title: "Worker",
      dataIndex: "workerData",
      key: "workerData",
      render: (text: string, ati: ListItem) => <div>{ati.data.workerData?.name}</div>,
      sorter: (a: ListItem, b: ListItem) =>
        (a.data.workerData?.name || "").localeCompare(b.data.workerData?.name || "") || 0,
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (text: string, ati: ListItem) => <div>{ati.data.workerDepartmentTitle}</div>,
      sorter: (a: ListItem, b: ListItem) =>
        (a.data.workerDepartmentTitle || "").localeCompare(b.data.workerDepartmentTitle || "") || 0,
    },
    {
      title: "Count hours",
      dataIndex: "hours",
      key: "hours",
      render: (text: string, ati: ListItem) => <div>{ati.data.request?.countHours}</div>,
      sorter: (a: ListItem, b: ListItem) => (a.data.request?.countHours || 0) - (b.data.request?.countHours || 0),
    },
    {
      title: "Explanation",
      dataIndex: "url",
      key: "url",
      render: (text: string, ati: ListItem) => (
        <a href={ati.data.request?.descriptionUrl} target="_blank" rel="noreferrer">
          {ati.data.request?.descriptionUrl}
        </a>
      ),
      sorter: (a: ListItem, b: ListItem) =>
        (a.data.request?.descriptionUrl || "").localeCompare(b.data.request?.descriptionUrl || "") || 0,
    },
    {
      title: "My approval",
      key: "status",
      dataIndex: "status",
      render: (text: string, ati: ListItem) =>
        ati.data.approval?.rejectionReason ? (
          <div>
            <Tag color={ati.data.approval?.wasApproved ? "green" : "red"}>
              {ApproveTargetUtils.approvalToString(ati.data.approval)}
            </Tag>
            <div>{ati.data.approval?.rejectionReason}</div>
          </div>
        ) : (
          <Tag color={ati.data.approval?.wasApproved ? "green" : "red"}>
            {ApproveTargetUtils.approvalToString(ati.data.approval)}
          </Tag>
        ),
    },
    {
      title: "Current final status",
      key: "status",
      dataIndex: "status",
      render: (text: string, ati: ListItem) => (
        <Tag
          color={
            ati.data.request?.requestStatus === RequestStatus.Approved_2
              ? "green"
              : ati.data.request?.requestStatus === RequestStatus.Rejected_3
                ? "red"
                : "blue"
          }
        >
          {RequestUtils.getRequestStatus(ati.data.request?.requestStatus)}
        </Tag>
      ),
      sorter: (a: ListItem, b: ListItem) => (a.data.request?.requestStatus || 0) - (b.data.request?.requestStatus || 0),
    },
    {
      title: "Actions",
      key: "action",
      render: (_: unknown, ati: ListItem) =>
        ati.data.request?.requestStatus !== RequestStatus.Unknown_0 &&
        ati.data.request?.requestStatus !== RequestStatus.Canceled_4 && (
          <Space size="middle">
            {epochNavigatorViewStore.isCurrentEpoch() && buttonApprove(ati)}
            {buttonViewApprovals(ati)}
            {epochNavigatorViewStore.isCurrentEpoch() && buttonReject(ati)}
          </Space>
        ),
    },
  ];

  /** multi-selection */
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: Key[], selectedRows: ListItem[]) => {
      setCheckedItems(selectedRows);
      setRowKeys(selectedRowKeys);
    },
    hideSelectAll: true,
  };
  //endregion Table

  return (
    <div className="container">
      {approveRequestsViewStore.error && elementDisplayError}
      <h2>Check and approve requests of co-workers</h2>
      <Space direction="vertical">
        {epochNavigatorViewStore.isCurrentEpoch() ? (
          <Space>
            {buttonSelectUnapproved()}
            {buttonClearSelection()}
            {buttonApproveSelected()}
          </Space>
        ) : (
          <Space>
            Epoch:
            {epochNavigatorViewStore.selectedEpoch}
            {<Tag color="red">Past</Tag>}
          </Space>
        )}
        {approveRequestsViewStore.approveTargets !== null && (
          <Table<ListItem>
            pagination={false}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            columns={columns}
            dataSource={listItems}
          />
        )}

        {/*/!* a modal dialog to enter reason of the rejection *!/*/}
        <Modal title="Enter a reason of the rejection" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <Input
            value={rejectionReason}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setRejectionReason(event.target.value);
            }}
            style={{ marginBottom: 20 }}
          />
        </Modal>
      </Space>
    </div>
  );
});

