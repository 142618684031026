import { makeAutoObservable, runInAction } from "mobx";
import { BigNumber, BigNumberish } from "ethers";
import { workersPageChainStore } from "../chain-stores";
import { DepartmentData, WorkerData } from "../../logic";

class WorkersPageViewStore {
  private readonly chainStore = workersPageChainStore;

  isFetchingData = false;

  workerUids: BigNumber[] | undefined;
  departments: DepartmentData[] | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchWorkers() {
    try {
      const workers = await workersPageChainStore.fetchWorkers();
      runInAction(() => {
        this.workerUids = workers;
      });
    } finally {
      this.isFetchingData = false;
    }
  }

  async fetchDepartments() {
    try {
      const departments = await workersPageChainStore.fetchDepartments();
      runInAction(() => {
        this.departments = departments;
      });
    } finally {
      this.isFetchingData = false;
    }
  }

  //region Create a worker
  async createWorker(
    wallet: string,
    hourRate: number,
    name: string,
    role: number
  ) : Promise<WorkerData> {
    // create a new worker
    const workerInfo = await workersPageChainStore.createWorker(
      wallet,
      hourRate,
      name,
      role
    );

    if (! workerInfo) {
      throw Error("Worker is not created");
    }

    return workerInfo;
  }
  //endregion Create a worker

  async moveWorkersToDepartment(workerUids: BigNumber[], department: BigNumberish) {
    await workersPageChainStore.moveWorkersToDepartment(workerUids, department);
  }
}

export const workersPageViewStore = new WorkersPageViewStore();
