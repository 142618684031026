/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type { IBatchReader, IBatchReaderInterface } from "../IBatchReader";

const _abi = [
  {
    inputs: [],
    name: "ErrorAccessDenied",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorActionIsAlreadyDone",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "",
        type: "uint16",
      },
    ],
    name: "ErrorAlreadyHead",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorApproverOrHeadOrGovOnly",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorApprovingReDelegationIsNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorArraysHaveDifferentLengths",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorCannotMoveHeadToAnotherDepartment",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorCannotRemoveNotPermanentApprover",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorDataNotChanged",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "worker",
        type: "uint64",
      },
    ],
    name: "ErrorDebtTokenIsUsed",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "debtToken",
        type: "address",
      },
    ],
    name: "ErrorDebtTokenNotEnabled",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "uid",
        type: "uint16",
      },
    ],
    name: "ErrorDepartmentAlreadyRegistered",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorEmptyArrayNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorEmptyString",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorGovernanceOrDepartmentHeadOnly",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorIncorrectAmount",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DebtUid",
        name: "debtUid",
        type: "uint64",
      },
    ],
    name: "ErrorIncorrectDebtConversion",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorIncorrectDelegate",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.HourRate",
        name: "rate",
        type: "uint16",
      },
    ],
    name: "ErrorIncorrectRate",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorIncorrectRoles",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "currentSum",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "requiredSum",
        type: "uint256",
      },
    ],
    name: "ErrorIncorrectSharesSum",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorNewWalletIsUsedByApprover",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "providedAddress",
        type: "address",
      },
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "worker",
        type: "uint64",
      },
    ],
    name: "ErrorNotApprover",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "providedAddress",
        type: "address",
      },
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "worker",
        type: "uint64",
      },
    ],
    name: "ErrorNotDelegated",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorNotEnoughFund",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "debtToken",
        type: "address",
      },
    ],
    name: "ErrorPriceOracleForDebtTokenNotFound",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorRequestIsCanceled",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RoleUid",
        name: "uid",
        type: "uint16",
      },
    ],
    name: "ErrorRoleNotFound",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorTheDelegateHasSamePermission",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorThePermissionIsAlreadyDelegated",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "currentLength",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxAllowedLength",
        type: "uint256",
      },
    ],
    name: "ErrorTooLongString",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "countHours",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxAllowedValue",
        type: "uint256",
      },
    ],
    name: "ErrorTooManyHours",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "request",
        type: "uint256",
      },
    ],
    name: "ErrorUnapprovedRequestExists",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorUnknownBudgetShares",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "uid",
        type: "uint16",
      },
    ],
    name: "ErrorUnknownDepartment",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "uid",
        type: "uint256",
      },
    ],
    name: "ErrorUnknownRequest",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorWalletIsAlreadyUsedByOtherWorker",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorWorkerCannotBeApprover",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "uid",
        type: "uint64",
      },
    ],
    name: "ErrorWorkerNotFound",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "errorCode",
        type: "uint256",
      },
    ],
    name: "ErrorZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorZeroDepartmentBudgetShare",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "ErrorZeroPrice",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "errorCode",
        type: "uint256",
      },
    ],
    name: "ErrorZeroValueNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorZeroWeekBudget",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "approver_",
        type: "address",
      },
    ],
    name: "approverToWorkersBatch",
    outputs: [
      {
        internalType: "IClerkTypes.WorkerUid[]",
        name: "",
        type: "uint64[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getAllWorkers",
    outputs: [
      {
        internalType: "IClerkTypes.WorkerUid[]",
        name: "",
        type: "uint64[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.ApprovalUid[]",
        name: "approvalUids",
        type: "uint256[]",
      },
    ],
    name: "getApprovals",
    outputs: [
      {
        internalType: "address[]",
        name: "approvers",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "approvedValues",
        type: "uint256[]",
      },
      {
        internalType: "string[]",
        name: "explanations",
        type: "string[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.EpochType",
        name: "epoch_",
        type: "uint16",
      },
      {
        internalType: "IClerkTypes.WorkerUid[]",
        name: "workerUids",
        type: "uint64[]",
      },
    ],
    name: "getApprovalsMadeBySigner",
    outputs: [
      {
        internalType: "uint64[]",
        name: "outApprovedValues",
        type: "uint64[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "requestUid",
        type: "uint256",
      },
    ],
    name: "getApprovalsMadeForRequest",
    outputs: [
      {
        internalType: "address[]",
        name: "approvers",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "approvedValues",
        type: "uint256[]",
      },
      {
        internalType: "string[]",
        name: "explanations",
        type: "string[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getDebtDepartmentsInfo",
    outputs: [
      {
        internalType: "IClerkTypes.DepartmentUid[]",
        name: "outDepartmentUids",
        type: "uint16[]",
      },
      {
        internalType: "IClerkTypes.AmountST[]",
        name: "outBudgetAmountST",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DebtUid[]",
        name: "debts_",
        type: "uint64[]",
      },
    ],
    name: "getDebtTokenUnpaidAmounts",
    outputs: [
      {
        internalType: "uint256[]",
        name: "amounts",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "paidAmounts",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "startFromIndex0_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "count_",
        type: "uint256",
      },
    ],
    name: "getDepartments",
    outputs: [
      {
        internalType: "uint256",
        name: "outCount",
        type: "uint256",
      },
      {
        internalType: "IClerkTypes.DepartmentUid[]",
        name: "outUids",
        type: "uint16[]",
      },
      {
        internalType: "address[]",
        name: "outHeads",
        type: "address[]",
      },
      {
        internalType: "string[]",
        name: "outTitles",
        type: "string[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid[]",
        name: "requests_",
        type: "uint256[]",
      },
    ],
    name: "getEpochAndStatusBatch",
    outputs: [
      {
        internalType: "enum IClerkTypes.RequestStatus[]",
        name: "outStatuses",
        type: "uint8[]",
      },
      {
        internalType: "IClerkTypes.EpochType[]",
        name: "outEpochs",
        type: "uint16[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.EpochType",
        name: "epoch_",
        type: "uint16",
      },
      {
        internalType: "IClerkTypes.WorkerUid[]",
        name: "workers_",
        type: "uint64[]",
      },
    ],
    name: "getRejectionReasons",
    outputs: [
      {
        internalType: "string[]",
        name: "outReasons",
        type: "string[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.EpochType",
        name: "epoch_",
        type: "uint16",
      },
      {
        internalType: "IClerkTypes.WorkerUid[]",
        name: "workers_",
        type: "uint64[]",
      },
    ],
    name: "getRequestStatuses",
    outputs: [
      {
        internalType: "enum IClerkTypes.RequestStatus[]",
        name: "statuses",
        type: "uint8[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.EpochType",
        name: "epoch_",
        type: "uint16",
      },
      {
        internalType: "IClerkTypes.WorkerUid[]",
        name: "ids_",
        type: "uint64[]",
      },
    ],
    name: "getRequestUidBatch",
    outputs: [
      {
        internalType: "IClerkTypes.RequestUid[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid[]",
        name: "requestUids_",
        type: "uint256[]",
      },
    ],
    name: "getRequests",
    outputs: [
      {
        internalType: "IClerkTypes.WorkerUid[]",
        name: "outWorkerUids",
        type: "uint64[]",
      },
      {
        internalType: "IClerkTypes.DepartmentUid[]",
        name: "outDepartmentUids",
        type: "uint16[]",
      },
      {
        internalType: "IClerkTypes.RoleUid[]",
        name: "outWorkerRoles",
        type: "uint16[]",
      },
      {
        internalType: "uint32[]",
        name: "outCountHours",
        type: "uint32[]",
      },
      {
        internalType: "string[]",
        name: "outDescriptionUrls",
        type: "string[]",
      },
      {
        internalType: "uint256[]",
        name: "outHourRates",
        type: "uint256[]",
      },
      {
        internalType: "IClerkTypes.EpochType[]",
        name: "outEpochTypes",
        type: "uint16[]",
      },
      {
        internalType: "address[]",
        name: "outDebtToken",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "startFromIndex0_",
        type: "uint16",
      },
      {
        internalType: "uint16",
        name: "count_",
        type: "uint16",
      },
    ],
    name: "getRoles",
    outputs: [
      {
        internalType: "uint16",
        name: "outCount",
        type: "uint16",
      },
      {
        internalType: "IClerkTypes.RoleUid[]",
        name: "outUids",
        type: "uint16[]",
      },
      {
        internalType: "string[]",
        name: "outTitles",
        type: "string[]",
      },
      {
        internalType: "IClerkTypes.CountApprovals[]",
        name: "outCountApprovals",
        type: "uint16[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "departmentUid",
        type: "uint16",
      },
    ],
    name: "getUnpaidAmountsForDepartment",
    outputs: [
      {
        internalType: "IClerkTypes.AmountUSD[]",
        name: "outUnpaidAmountUSD",
        type: "uint64[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "departmentUid",
        type: "uint16",
      },
      {
        internalType: "IClerkTypes.RoleUid",
        name: "roleUid",
        type: "uint16",
      },
      {
        internalType: "uint256",
        name: "bufferSize",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "startIndex0",
        type: "uint256",
      },
    ],
    name: "getUnpaidDebts",
    outputs: [
      {
        internalType: "uint256",
        name: "countItemsInArrays",
        type: "uint256",
      },
      {
        internalType: "IClerkTypes.RequestUid[]",
        name: "outRequestIds",
        type: "uint256[]",
      },
      {
        internalType: "IClerkTypes.WorkerUid[]",
        name: "outWorkers",
        type: "uint64[]",
      },
      {
        internalType: "IClerkTypes.AmountUSD[]",
        name: "outAmountsUSD",
        type: "uint64[]",
      },
      {
        internalType: "IClerkTypes.DebtUid[]",
        name: "outDebtUids",
        type: "uint64[]",
      },
      {
        internalType: "address[]",
        name: "outDebtTokens",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "departmentUid",
        type: "uint16",
      },
    ],
    name: "getWeekBudgetLimitsForRolesST",
    outputs: [
      {
        internalType: "IClerkTypes.AmountST[]",
        name: "outAmounts",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.WorkerUid[]",
        name: "workers_",
        type: "uint64[]",
      },
    ],
    name: "getWorkerInfoBatch",
    outputs: [
      {
        internalType: "uint256[]",
        name: "outHourRates",
        type: "uint256[]",
      },
      {
        internalType: "IClerkTypes.RoleUid[]",
        name: "outRoles",
        type: "uint16[]",
      },
      {
        internalType: "IClerkTypes.DepartmentUid[]",
        name: "outDepartmentUids",
        type: "uint16[]",
      },
      {
        internalType: "string[]",
        name: "outNames",
        type: "string[]",
      },
      {
        internalType: "address[]",
        name: "outWallets",
        type: "address[]",
      },
      {
        internalType: "address[]",
        name: "outDebtTokens",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "approver_",
        type: "address",
      },
      {
        internalType: "IClerkTypes.WorkerUid[]",
        name: "workers_",
        type: "uint64[]",
      },
    ],
    name: "isApproverBatch",
    outputs: [
      {
        internalType: "bool[]",
        name: "",
        type: "bool[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "departmentId",
        type: "uint16",
      },
    ],
    name: "workersOfDepartment",
    outputs: [
      {
        internalType: "IClerkTypes.WorkerUid[]",
        name: "",
        type: "uint64[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class IBatchReader__factory {
  static readonly abi = _abi;
  static createInterface(): IBatchReaderInterface {
    return new utils.Interface(_abi) as IBatchReaderInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IBatchReader {
    return new Contract(address, _abi, signerOrProvider) as IBatchReader;
  }
}
