import { makeAutoObservable } from "mobx";
import { web3Store } from "../web3-store";
import { ContractsSingleton, RequestUtils, UnpaidDebt } from "../../logic";
import { BigNumber } from "ethers";
import { DebtUtils } from "../../logic/utils/DebtUtils";

/**
 * Store for create-request page
 */
class CreateRequestChainStore {
  constructor() {
    makeAutoObservable(this);
  }

  private readonly web3Store = web3Store;

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  /** Get request of the current worker for the current epoch */
  async getCurrentRequest(epoch: number | undefined) {
    return await RequestUtils.getCurrentRequestInfo(await this.core(), epoch);
  }

  async getCurrentWorkerUid() {
    return await (await this.core()).cachedData.getCurrentWorkerUid();
  }

  async createRequest(countHours: number, urlDescription: string) {
    const cs = await this.core();
    await cs.runAndWait(() => cs.requestsManager.createRequest(countHours, urlDescription));
  }

  async cancelRequest() {
    const cs = await this.core();
    await cs.runAndWait(() => cs.requestsManager.cancelRequest());
  }

  async getUnpaidDebt(requestUid: BigNumber): Promise<UnpaidDebt | undefined> {
    const cs = await this.core();
    return await DebtUtils.getDebtForRequest(cs, requestUid);
  }
}

export const createRequestChainStore = new CreateRequestChainStore();
