import { BigNumber } from "ethers";
import { ContractsSingleton } from "../core/ContractsSingleton";
import { PaginationUtils } from "./PaginationUtils";
import {
  DepartmentData,
  DepartmentDataEmpty,
  IDebtTokenUnpaidAmount,
  RequestInfo,
  RoleData,
  UnpaidDebt,
} from "../core/DataTypes";

export class DebtUtils {
  //region List of departments
  /** get a list of departments for which debts have ever been registered */
  static async getDebtDepartmentUids(cs: ContractsSingleton): Promise<number[]> {
    return (await cs.batchReader.getDebtDepartmentsInfo()).outDepartmentUids;
  }
  //endregion List of departments

  //region Get list of all roles that have ever been registered
  /** get a max count of roles that have ever been registered
   * If we have max count = N then we should enumerate following roles [1...N]
   * */
  static async getDebtRoles(cs: ContractsSingleton): Promise<RoleData[]> {
    const dest: RoleData[] = [];
    const currentCountRole = await cs.companyManager.countRoles();
    const maxRoleInDM = await cs.debtsManager.maxRoleValueInAllTimes();
    for (let i = 0; i < Math.min(currentCountRole, maxRoleInDM); ++i) {
      const rd = await cs.cachedData.getRoleData(i + 1);
      if (rd) {
        dest.push(rd);
      } else {
        dest.push({
          // this role is removed
          title: (i + 1).toString(),
          uid: i + 1,
          countApprovals: 0,
        });
      }
    }

    // probably total number of roles was reduced, we should take into account previous roles
    for (let i = Math.min(currentCountRole, maxRoleInDM); i < Math.max(currentCountRole, maxRoleInDM); ++i) {
      dest.push({
        uid: i + 1,
        title: (i + 1).toString(),
        countApprovals: 0,
      });
    }

    return dest;
  }

  //endregion Get list of all roles that have ever been registered

  //region Get list of all departments with unpaid debts

  /** Get full list of unpaid debts (for all workers) for the given department, role */
  static async getUnpaidDebtsForDepartmentAndRole(
    cs: ContractsSingleton,
    departmentUid: number,
    roleUid: number,
  ): Promise<{
    retRequestIds: BigNumber[];
    retWorkers: BigNumber[];
    retAmountsUSD: BigNumber[];
    retDebtUids: BigNumber[];
    retDebtTokens: string[];
  }> {
    const bufferSize = PaginationUtils.defaultPageSize;
    let startIndex0 = 0;

    const retRequestIds: BigNumber[] = [];
    const retWorkers: BigNumber[] = [];
    const retAmountsUSD: BigNumber[] = [];
    const retDebtUids: BigNumber[] = [];
    const retDebtTokens: string[] = [];

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { countItemsInArrays, outRequestIds, outWorkers, outAmountsUSD, outDebtUids, outDebtTokens } =
        await cs.batchReader.getUnpaidDebts(departmentUid, roleUid, bufferSize, startIndex0);
      for (let i = 0; i < countItemsInArrays.toNumber(); ++i) {
        retRequestIds.push(outRequestIds[i]);
        retWorkers.push(outWorkers[i]);
        retAmountsUSD.push(outAmountsUSD[i]);
        retDebtUids.push(outDebtUids[i]);
        retDebtTokens.push(outDebtTokens[i]);
      }
      if (countItemsInArrays.toNumber() < bufferSize) {
        break;
      }
      startIndex0 += countItemsInArrays.toNumber();
    }

    return { retRequestIds, retWorkers, retAmountsUSD, retDebtUids, retDebtTokens };
  }

  //endregion Get list of all departments with unpaid debts

  //region List of unpaid debts
  /**
   * Get full list of unpaid debts.
   * Some debts can be canceled (worker has canceled already approved request).
   * For such debts we will have retWorker[i] === 0, we ignore such debts
   * @param cs
   */
  static async getUnpaidDebts(cs: ContractsSingleton): Promise<UnpaidDebt[]> {
    const dest: UnpaidDebt[] = [];

    const departmentUids = await this.getDebtDepartmentUids(cs);
    const roles = await this.getDebtRoles(cs);

    for (const departmentUid of departmentUids) {
      for (const role of roles) {
        const r = await this.getUnpaidDebtsForDepartmentAndRole(cs, departmentUid, role.uid);
        const unpaidAmountsMap = await this.prepareUnpaidAmountsMap(cs, r.retDebtUids, r.retDebtTokens);

        dest.push(
          ...r.retWorkers.map((x, index) => {
            const rrr: UnpaidDebt = {
              departmentUid: departmentUid,
              debtUid: r.retDebtUids[index],
              roleUid1: role.uid,
              workerUid: r.retWorkers[index],
              amountUSD: r.retAmountsUSD[index].toNumber(),
              requestUid: r.retRequestIds[index],
              debtToken: r.retDebtTokens[index],
              unpaidAmount: unpaidAmountsMap.get(r.retDebtUids[index].toString())
            };
            return rrr;
          }),
        );
      }
    }

    return dest.filter((x) => !x.workerUid.eq(0));
  }

  /**
   * Select not-USD debts and fetch unpaidAmounts for them
   */
  static async prepareUnpaidAmountsMap(
    cs: ContractsSingleton,
    debtUids: BigNumber[],
    debtTokens: string[]
  ) : Promise<Map<string, IDebtTokenUnpaidAmount>> {

    // debtUid as str => IDebtTokenUnpaidAmount
    const dest = new Map<string, IDebtTokenUnpaidAmount>();

    // filter all debts that have not empty debt-tokens and get {unpaidAmounts} for them
    const debtWithDebtTokens: BigNumber[] = debtUids.filter(
      (debtToken, index) => debtTokens[index] // all debts with not empty debt-tokens
    );

    // get unpaid amounts for not-USD-debts
    const unpaidAmounts = await this.getDebtTokenUnpaidAmounts(cs, debtWithDebtTokens);

    // save unpaid amounts to map
    for (let i = 0; i < debtWithDebtTokens.length; ++i) {
      dest.set(debtUids[i].toString(), unpaidAmounts[i]);
    }

    return dest;
  }

  //endregion List of unpaid debts

  //region Get detail info about requests
  static async getRequestsInfo(cs: ContractsSingleton, requestUids: BigNumber[]): Promise<RequestInfo[]> {
    const dest: RequestInfo[] = [];

    for (let i = 0; i < requestUids.length; i += PaginationUtils.defaultPageSize) {
      const chunk = requestUids.slice(i, i + PaginationUtils.defaultPageSize);
      const r = await cs.batchReader.getRequests(chunk);
      for (let j = 0; j < r.outWorkerUids.length; ++j) {
        dest.push({
          requestStatus: 0, //TODO
          requestUid: chunk[j],
          workerUid: r.outWorkerUids[j],
          epoch: r.outEpochTypes[j],
          countHours: r.outCountHours[j],
          departmentTitle: (await cs.cachedData.getDepartmentData(r.outDepartmentUids[j]))?.title,
          departmentUid: r.outDepartmentUids[j],
          descriptionUrl: r.outDescriptionUrls[j],
          hourRate: r.outHourRates[j],
          roleUid: r.outWorkerRoles[j],
          roleTitle: (await cs.cachedData.getRoleData(r.outWorkerRoles[j]))?.title,
          debtToken: r.outDebtToken[j]
        });
      }
    }

    return dest;
  }

  //endregion Get detail info about requests

  //region Get list of unpaid amounts for all pairs department/role
  /**
   * Get list of unpaid amounts in USD for all pairs department/role
   * If the debt is set in debt-token, it's recalculated to USD using current price from the price oracle.
   */
  static async getUnpaidAmounts(cs: ContractsSingleton): Promise<{
    departments: DepartmentData[];
    roles: RoleData[];
    unpaidAmounts: Map<string, number[]>;
  }> {
    const departments: DepartmentData[] = await Promise.all(
      (await this.getDebtDepartmentUids(cs)).map(async (x) => {
        return await cs.cachedData.getDepartmentData(x) || DepartmentDataEmpty;
      }),
    );
    const roles = await this.getDebtRoles(cs);

    const dataForMap = await Promise.all(
      departments.map(
        async x => ({
          departmentSid: x.uid.toString(),
          unpaidAmounts: (await cs.batchReader.getUnpaidAmountsForDepartment(x.uid)).map(
            (x: BigNumber) => x.toNumber()
          )
        })
      )
    );

    const unpaidAmounts = new Map<string, number[]>();
    for (const dm of dataForMap) {
      unpaidAmounts.set(dm.departmentSid, dm.unpaidAmounts);
    }

    return { departments, roles, unpaidAmounts };
  }

  //endregion Get list of unpaid amounts for all pairs department/role

  //region Get the debt for the request
  static async getDebtForRequest(cs: ContractsSingleton, requestUid: BigNumber): Promise<UnpaidDebt | undefined> {
    const debtUid = await cs.debtsManager.requestsToDebts(requestUid);
    if (!debtUid.eq(0)) {
      const rd = await cs.debtsManager.requestsData(requestUid);
      const amountUsd = await cs.debtsManager.unpaidAmountsUSD(debtUid);
      return {
        requestUid: requestUid,
        debtUid: debtUid,
        departmentUid: rd.department,
        amountUSD: amountUsd.toNumber(),
        roleUid1: rd.role,
        workerUid: rd.worker,
      };
    }

    return;
  }

  //endregion Get the debt for the request

  //region Get debts by departments
  /**
   * @return map: departmentUid => value of debt in USD
   */
  static async getDebtsByDepartments(cs: ContractsSingleton): Promise<Map<string, number>> {
    const r = (await DebtUtils.getUnpaidAmounts(cs)).unpaidAmounts;

    const dest: Map<string, number> = new Map<string, number>();
    r.forEach(function (value, key) {
      dest.set(key, r.get(key)?.reduce((prev: number, cur: number) => prev + cur, 0) || 0);
    });

    return dest;
  }
  //endregion Get debts by departments

  //region Get unpaidAmounts for the given debts with not-default debt-tokens
  static async getDebtTokenUnpaidAmounts(cs: ContractsSingleton, debts: BigNumber[]) : Promise<IDebtTokenUnpaidAmount[]> {
    if (debts.length) {
      const { amounts, paidAmounts } = await cs.batchReader.getDebtTokenUnpaidAmounts(debts);
      return amounts.map(
        (amount, index) => ({
          amount,
          paidAmount: paidAmounts[index]
        })
      );
    } else {
      return [];
    }
  }
  //endregion Get unpaidAmounts for the given debts with not-default debt-tokens
}

