import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { DepartmentBudgetInfoValueKind } from "../../logic";
import { BudgetTable, DebtsColumnData } from "../../components/BudgetTable";
import { useStores, web3Store } from "../../stores";
import { AmountUtils } from "../../logic/utils/AmountUtils";
import { Alert } from "antd";
import { BigNumber } from "ethers";

/**
 * Show budget: %, salary tokens (ST) and available ST for next epoch
 */
export const BudgetPage = observer(() => {
  const { budgetPageViewStore } = useStores();

  useEffect(() => {
    if (web3Store.provider) {
      const fetchData = async () => {
        await budgetPageViewStore.fetchBudgetValues();
      };
      fetchData().catch(console.error);
    }
  }, [budgetPageViewStore, web3Store.provider]);

  //region Generate debts map

  /**
   * Generate map with unpaid debts for each department.
   * Debt values are recalculated to salary tokens/1e18
   */
  function getDebtsMap(priceUsdST: BigNumber | undefined): DebtsColumnData {
    const dest: Map<string, number> = new Map<string, number>();

    if (budgetPageViewStore.debtsByDepartmentsUSD && priceUsdST) {
      for (const [departmentSID, unpaidDebtsUSD] of budgetPageViewStore.debtsByDepartmentsUSD.entries()) {
        dest.set(departmentSID, AmountUtils.removeDecimals(BigNumber.from(unpaidDebtsUSD).mul(priceUsdST)));
      }
    }

    return {
      map: dest,
    };
  }

  //endregion Generate debts map

  return (
    <div className="container">
      <h2>Current balance and default budget</h2>
      <div>
        Salary token: {budgetPageViewStore.salaryTokenAddress}
          , {budgetPageViewStore.defaultSalaryTokenName}
          , decimals={budgetPageViewStore.defaultSalaryTokenDecimals}
      </div>
      <div>
        Default budget: {AmountUtils.amountToString(
          budgetPageViewStore.defaultWeekBudgetST
          , budgetPageViewStore.defaultSalaryTokenDecimals
        )} [
        {budgetPageViewStore.defaultSalaryTokenName}]{" "}
      </div>
      <div>
        Balance: {AmountUtils.amountToString(
          budgetPageViewStore.availableBalanceDefaultST
          , budgetPageViewStore.defaultSalaryTokenDecimals
        )} [
        {budgetPageViewStore.defaultSalaryTokenName}]{" "}
      </div>
      <div>
        Available budget:{" "}
        {budgetPageViewStore.availableBalanceDefaultST &&
        budgetPageViewStore.defaultWeekBudgetST.gt(budgetPageViewStore.availableBalanceDefaultST)
          ? AmountUtils.amountToString(
            budgetPageViewStore.defaultWeekBudgetST
            , budgetPageViewStore.weekSalaryTokenDecimals
          )
          : AmountUtils.amountToString(
            budgetPageViewStore.availableBalanceDefaultST
            , budgetPageViewStore.weekSalaryTokenDecimals
          )}{" "}
        [{budgetPageViewStore.defaultSalaryTokenName}]{" "}
      </div>
      <Alert type="success" message="Available budget is calculated as max(balance, default budget)" />
      <Alert
        type="info"
        message={`Current cost of 1 USD: ${AmountUtils.amountToString(
          budgetPageViewStore.priceUsdDefaultST
          , budgetPageViewStore.defaultSalaryTokenDecimals
        )} [${
          budgetPageViewStore.defaultSalaryTokenName
        }]`}
      />
      <div>&nbsp;</div>

      <h2>Available budget for next epoch, [{budgetPageViewStore.defaultSalaryTokenName}]</h2>
      <BudgetTable
        displayMode={DepartmentBudgetInfoValueKind.NEXT_EPOCH_AMOUNT_ST}
        debts={getDebtsMap(budgetPageViewStore.priceUsdDefaultST)}
        showDebts={true}
      />

      <h2>Default budgets (%)</h2>
      <BudgetTable
        displayMode={DepartmentBudgetInfoValueKind.CURRENT_SHARE_PERCENT}
        showDebts={false}
      />

      <h2>Default budget, [{budgetPageViewStore.defaultSalaryTokenName}]</h2>
      <BudgetTable
        displayMode={DepartmentBudgetInfoValueKind.CURRENT_SHARE_AMOUNT_ST}
        debts={getDebtsMap(budgetPageViewStore.priceUsdDefaultST)}
        showDebts={true}
      />

      <h2>Budget assigned to the current epoch, [{budgetPageViewStore.weekSalaryTokenName}]</h2>
      <div>
        Salary token: {budgetPageViewStore.weekSalaryTokenAddress}, {budgetPageViewStore.weekSalaryTokenName}
      </div>
      <BudgetTable
        displayMode={DepartmentBudgetInfoValueKind.UNSPENT_AMOUNT_ST}
        debts={getDebtsMap(budgetPageViewStore.priceUsdWeekST)}
        showDebts={true}
      />
    </div>
  );
});

