import React, { useEffect, useState } from "react";
import { ApprovalByApproverInfo } from "../../logic";
import { Table, Tag } from "antd";
import { epochNavigatorViewStore, listApprovalsViewStore, web3Store } from "../../stores";
import { BigNumber } from "ethers";

interface ListItem {
  key: string;
  data: ApprovalByApproverInfo;
}

type ListApprovalsProps = {
  workerUid: BigNumber;
};

/**
 *  Display list of approvals received by given request
 */
export const ListApprovals: React.FC<ListApprovalsProps> = (props) => {
  const [listItems, setListItem] = useState<ListItem[]>([]);
  useEffect(() => {
    if (web3Store.provider) {
      const fetchRequestData = async () => {
        await listApprovalsViewStore.fetchApprovals(props.workerUid, epochNavigatorViewStore.selectedEpoch);
        if (listApprovalsViewStore.approvals) {
          const list = listApprovalsViewStore.approvals.map((x) => ({ key: x.approver, data: x }));
          setListItem(list);
        }
      };
      fetchRequestData().catch(console.error);
    }
  }, [listApprovalsViewStore, web3Store.provider, epochNavigatorViewStore.selectedEpoch]);

  const columns = [
    {
      title: "Approver - wallet",
      dataIndex: "wallet",
      key: "wallet",
      render: (text: string, item: ListItem) => <div>{item.data.approver}</div>,
    },
    {
      title: "Approver - id",
      dataIndex: "workerId",
      key: "workerId",
      render: (text: string, item: ListItem) => <div>{item.data.approverWorkerUid?.toString()}</div>,
    },
    {
      title: "Approver - name",
      dataIndex: "workerName",
      key: "workerName",
      render: (text: string, item: ListItem) => <div>{item.data.approverWorkerTitle}</div>,
    },
    {
      title: "Approved",
      dataIndex: "approved",
      key: "approved",
      render: (text: string, item: ListItem) => (
        <Tag color={item.data.wasApproved ? "green" : "red"}>{item.data.wasApproved ? "Approved" : "Rejected"}</Tag>
      ),
    },
    {
      title: "Rejection reason",
      dataIndex: "rejection",
      key: "rejection",
      render: (text: string, item: ListItem) => <div>{item.data.rejectionReason}</div>,
    },
    {
      title: "Canceled",
      key: "canceled",
      dataIndex: "cancelled",
      render: (text: string, item: ListItem) => item.data.wasCanceled && <Tag color={"purple"}>Canceled</Tag>,
    },
  ];
  return (
    <div>
      {!listApprovalsViewStore.approvals || listApprovalsViewStore.approvals.length === 0 ? (
        <div>No approvals yet</div>
      ) : (
        <Table<ListItem> columns={columns} dataSource={listItems} />
      )}
    </div>
  );
};
