import { makeAutoObservable, runInAction } from "mobx";
import { ContractsSingleton, DebtListItem, UnpaidDebt } from "../../logic";
import { web3Store } from "../web3-store";
import { DebtUtils } from "../../logic/utils/DebtUtils";

class WorkerDebtsViewStore {
  isFetchingData = false;
  debts: DebtListItem[] | null = null;
  /** Last happened error */
  error = "";

  private readonly web3Store = web3Store;

  constructor() {
    makeAutoObservable(this);
  }

  /** Hide last happened error */
  hideError() {
    runInAction(() => {
      this.error = "";
    });
  }

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  //region Load data
  async fetchDebts(): Promise<UnpaidDebt[]> {
    runInAction(() => {
      this.isFetchingData = true;
    });
    try {
      const r: UnpaidDebt[] = await DebtUtils.getUnpaidDebts(await this.core());
      const items: DebtListItem[] = await this.getListItems(r);

      runInAction(() => {
        this.debts = items;
      });
      return r;
    } finally {
      runInAction(() => {
        this.isFetchingData = false;
      });
    }
  }

  //endregion Load data

  //region Generate list of items
  async getListItems(debts: UnpaidDebt[]): Promise<DebtListItem[]> {
    const cs = await this.core();

    // group debts by workerUid
    const map: Map<string, DebtListItem> = new Map<string, DebtListItem>();
    for (const d of debts) {
      const key = d.workerUid.toString();
      const item = map.get(key);
      if (item) {
        item.listDebts.push(d);
        item.countDebts += 1;
        item.totalDebtsAmountUSD += d.amountUSD;
      } else {
        map.set(key, {
          key: key,
          workerData: (await cs.cachedData.getWorkerInfo(d.workerUid)),
          listDebts: [d],
          totalDebtsAmountUSD: d.amountUSD,
          countDebts: 1,
          roleTitle: (await cs.cachedData.getRoleData(d.roleUid1))?.title || "",
          departmentTitle: (await cs.cachedData.getDepartmentData(d.departmentUid))?.title || "",
        });
      }
    }

    return Array.from<DebtListItem>(map.values());
  }

  //endregion Generate list of items
}

export const workerDebtsViewStore = new WorkerDebtsViewStore();
