import { Button, Card, Checkbox, Input, Space } from "antd";
import React, { useEffect, useState } from "react";
import { departmentPropertiesEditorViewStore, web3Store } from "../../stores";

type ListProperties = {
  departmentUid: number;
};

/**
 * Allow to edit title, head and options of the department
 */
export const DepartmentPropertiesEditor = (props: ListProperties):  JSX.Element => {
  //const { epochNavigatorViewStore } = useStores()

  const [currentTitle, setCurrentTitle] = useState<string>("");
  const [currentHead, setCurrentHead] = useState<string>("");
  const [currentOption, setCurrentOption] = useState(false);
  const [newTitle, setNewTitle] = useState<string>("");
  const [newHead, setNewHead] = useState<string>("");
  const [newOption, setNewOption] = useState(false);

  useEffect(() => {
    if (web3Store.provider) {
      const fetchData = async () => {
        await departmentPropertiesEditorViewStore.fetchDepartmentData(props.departmentUid);
        if (departmentPropertiesEditorViewStore.department) {
          setCurrentTitle(departmentPropertiesEditorViewStore.department.title);
          setNewTitle(departmentPropertiesEditorViewStore.department.title);

          setCurrentHead(departmentPropertiesEditorViewStore.department.head);
          setNewHead(departmentPropertiesEditorViewStore.department.head);

          setCurrentOption(departmentPropertiesEditorViewStore.currentOptionApproveLowByHigh || false);
          setNewOption(departmentPropertiesEditorViewStore.currentOptionApproveLowByHigh || false);
        }
      };
      fetchData().catch(console.error);
    }
  }, [props, web3Store.provider]);

  //region Update buttons
  async function updateTitle() {
    await departmentPropertiesEditorViewStore.updateTitle(props.departmentUid, newTitle);
    setCurrentTitle(departmentPropertiesEditorViewStore.department?.title || "");
    setNewTitle(departmentPropertiesEditorViewStore.department?.title || "");
  }

  async function updateHead() {
    await departmentPropertiesEditorViewStore.updateHead(props.departmentUid, newHead);
    setCurrentHead(departmentPropertiesEditorViewStore.department?.head || "");
    setNewHead(departmentPropertiesEditorViewStore.department?.head || "");
  }

  async function updateOption() {
    await departmentPropertiesEditorViewStore.updateOptionApproveLowByHigh(props.departmentUid, newOption);
    setCurrentOption(departmentPropertiesEditorViewStore.currentOptionApproveLowByHigh || false);
    setNewOption(departmentPropertiesEditorViewStore.currentOptionApproveLowByHigh || false);
  }

  //endregion Update buttons
  return (
    <div>
      <Card title="Department title">
        <Space>
          <Input
            width="100%"
            value={newTitle}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewTitle(event.target.value);
            }}
          />
          <Button
            onClick={async () => updateTitle()}
            type={newTitle === currentTitle ? "default" : "primary"}
            disabled={newTitle === currentTitle}
          >
            Update
          </Button>
        </Space>
      </Card>
      <Card title="Head of the department">
        <Space>
          <Input
            value={newHead}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewHead(event.target.value);
            }}
          />
          <Button
            onClick={async () => updateHead()}
            type={newHead === currentHead ? "default" : "primary"}
            disabled={newHead === currentHead}
          >
            Update
          </Button>
        </Space>
      </Card>
      <Card title="Edit options">
        <Space>
          <Checkbox
            onChange={(e) => {
              setNewOption(e.target.checked);
            }}
            checked={newOption}
          >
            A worker with higher role can approve requests of the worker with lower role
          </Checkbox>
          <Button
            onClick={async () => updateOption()}
            type={newOption === currentOption ? "default" : "primary"}
            disabled={newOption === currentOption}
          >
            Update
          </Button>
        </Space>
      </Card>
    </div>
  );
};
