import { makeAutoObservable, runInAction } from "mobx";
import { ContractsSingleton } from "../../logic";
import { web3Store } from "../web3-store";
import { BigNumber } from "ethers";
import { IERC20, IERC20__factory } from "../../typechain";
import { DebtUtils } from "../../logic/utils/DebtUtils";
import { AmountUtils } from "../../logic/utils/AmountUtils";

class BudgetPageViewStore {
  isFetchingData = false;

  defaultWeekBudgetST: BigNumber | null = null;

  salaryTokenAddress = "";
  availableBalanceDefaultST: BigNumber | null = null;
  defaultSalaryTokenName: string | undefined;
  defaultSalaryTokenDecimals: number | undefined;
  priceUsdDefaultST: BigNumber | undefined;

  weekSalaryTokenAddress = "";
  weekSalaryTokenName: string | undefined;
  weekSalaryTokenDecimals: number | undefined;
  availableBalanceWeekST: BigNumber | null = null;
  priceUsdWeekST: BigNumber | undefined;

  debtsByDepartmentsUSD: Map<string, number> | undefined;

  private readonly web3Store = web3Store;

  constructor() {
    makeAutoObservable(this);
  }

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  //region Load data

  /** Load budget values of specified kind */
  async fetchBudgetValues() {
    runInAction(() => {
      this.isFetchingData = true;
    });
    try {
      const cs = await this.core();
      const weekBudgetST = await cs.cachedData.getDefaultWeekBudgetST();

      const defaultSalaryTokenAddress = await cs.cachedData.getDefaultSalaryToken();
      const defaultSalaryTokenName = await cs.cachedData.getTokenName(defaultSalaryTokenAddress);
      const defaultSalaryTokenDecimals = await cs.cachedData.getTokenDecimals(defaultSalaryTokenAddress);

      const weekSalaryTokenAddress = await cs.debtsManager.weekSalaryToken();
      const weekSalaryTokenName = await cs.cachedData.getTokenName(weekSalaryTokenAddress);
      const weekSalaryTokenDecimals = await cs.cachedData.getTokenDecimals(weekSalaryTokenAddress);

      let availableBalanceDefaultST: BigNumber | undefined;
      try {
        availableBalanceDefaultST = await (
          (await IERC20__factory.connect(defaultSalaryTokenAddress, cs.signer)) as IERC20
        ).balanceOf(cs.paymentsManager.address);
      } catch (e) {
        //not enough funds
        console.log("Not enough funds?", e);
      }

      let priceUsdDefaultST: BigNumber | undefined;
      try {
        const salaryToken = BigNumber.from(weekSalaryTokenAddress).eq(0)
          ? defaultSalaryTokenAddress
          : weekSalaryTokenAddress;
        priceUsdDefaultST = await cs.priceOracle.getPrice(salaryToken);
      } catch (e) {
        //token is not supported
        console.log("Token is not supported?", e);
      }

      let availableBalanceWeekST: BigNumber | undefined;
      if (defaultSalaryTokenAddress === weekSalaryTokenAddress) {
        availableBalanceWeekST = availableBalanceDefaultST;
      } else if (!AmountUtils.isZeroAddress(weekSalaryTokenAddress)) {
        try {
          availableBalanceWeekST = await (
            (await IERC20__factory.connect(weekSalaryTokenAddress, cs.signer)) as IERC20
          ).balanceOf(cs.paymentsManager.address);
        } catch (e) {
          //not enough funds
          console.log("Not enough funds?", e);
        }
      }

      let priceUSDWeekST: BigNumber | undefined;
      try {
        if (!AmountUtils.isZeroAddress(weekSalaryTokenAddress)) {
          priceUSDWeekST = await cs.priceOracle.getPrice(weekSalaryTokenAddress);
        }
      } catch (e) {
        //token is not supported
        console.log("Token is not supported?", e);
      }

      const debtsByDepartmentsUSD = await DebtUtils.getDebtsByDepartments(cs);

      runInAction(() => {
        this.defaultWeekBudgetST = weekBudgetST;

        this.salaryTokenAddress = defaultSalaryTokenAddress;
        this.availableBalanceDefaultST = availableBalanceDefaultST || null;
        this.defaultSalaryTokenName = defaultSalaryTokenName;
        this.priceUsdDefaultST = priceUsdDefaultST;
        this.defaultSalaryTokenDecimals = defaultSalaryTokenDecimals;

        this.weekSalaryTokenAddress = weekSalaryTokenAddress;
        this.weekSalaryTokenName = weekSalaryTokenName;
        this.availableBalanceWeekST = availableBalanceWeekST || null;
        this.priceUsdWeekST = priceUSDWeekST;
        this.weekSalaryTokenDecimals = weekSalaryTokenDecimals;

        this.debtsByDepartmentsUSD = debtsByDepartmentsUSD;
      });
    } finally {
      runInAction(() => {
        this.isFetchingData = false;
      });
    }
  }

  //endregion Load data
}

export const budgetPageViewStore = new BudgetPageViewStore();
