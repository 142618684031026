import React from "react";
import { Nav } from "../components";
import { Route, Routes } from "react-router-dom";
import { routes } from "../router";

import "antd/dist/antd.css";
import "./App.css";

import { Layout, Space } from "antd";
import { EpochNavigator } from "../components/EpochNavigator";
import { SignerInfoNav } from "../components/SignerInfoNav";
import {ContractAddress} from "../components/ContractAddress";

const { Header, Footer, Content } = Layout;

function App(): JSX.Element {
  return (
    <Layout>
      <Header>
        <Nav />
      </Header>
      <Layout>
        <Content>
          <Space>
            <SignerInfoNav />
            <EpochNavigator />
            <ContractAddress/>
          </Space>

          <Routes>
            {routes.map((route) => {
              return <Route key={route.path} path={route.path} element={<route.component />} />;
            })}
          </Routes>
        </Content>
      </Layout>
      <Footer></Footer>
    </Layout>
  );
}

export default App;
