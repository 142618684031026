import { Alert, Button, Input, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useStores, web3Store } from "../../stores";
import { RoleData } from "../../logic";
import { observer } from "mobx-react";

type ListProperties = {
  departmentUid: number;
};

interface ListItem {
  key: string;
  role: RoleData;
  roleLimit: number;
  newRoleLimit: number;
}

/** Allow to edit role limits */
export const DepartmentBudgetEditor = observer((props: ListProperties) => {
  const { departmentBudgetViewStore } = useStores();
  const [listItems, setListItem] = useState<ListItem[]>([]);

  useEffect(() => {
    if (web3Store.provider) {
      const fetchData = async () => {
        await departmentBudgetViewStore.fetchRoleLimits(props.departmentUid);
        recreateListItems();
      };
      fetchData().catch(console.error);
    }
  }, [props, web3Store.provider]);

  //region Create list of items
  function recreateListItems() {
    const items: ListItem[] = [];
    if (
      departmentBudgetViewStore.roles &&
      departmentBudgetViewStore.roleLimits &&
      departmentBudgetViewStore.roles.length === departmentBudgetViewStore.roleLimits.length
    ) {
      for (let i = 0; i < departmentBudgetViewStore.roles.length; ++i) {
        items.push({
          key: departmentBudgetViewStore.roles[i].uid.toString(),
          role: departmentBudgetViewStore.roles[i],
          roleLimit: departmentBudgetViewStore.roleLimits[i],
          newRoleLimit: departmentBudgetViewStore.roleLimits[i],
        });
        //console.log(departmentBudgetViewStore.roles[i].title, departmentBudgetViewStore.roleLimits[i]);
      }
    }

    setListItem(items);
  }

  function areLimitsModified(): boolean {
    const r = listItems.filter((x) => x.roleLimit !== x.newRoleLimit);
    return r.length !== 0;
  }

  //endregion Create list of items

  //region Table columns
  const columns = [
    {
      title: "Role ID",
      dataIndex: "roleID",
      key: "roleID",
      render: (text: string, item: ListItem) => <div>{item.role.uid.toString()}</div>,
    },
    {
      title: "Role name",
      dataIndex: "name",
      key: "name",
      render: (text: string, item: ListItem) => <div>{item.role.title}</div>,
    },
    {
      title: "Role limit",
      dataIndex: "limit",
      key: "limit",
      render: (text: string, item: ListItem, index: number) => (
        <Input
          value={item.newRoleLimit}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const items = [...listItems];
            items[index].newRoleLimit = Number(event.target.value);
            setListItem(items);
          }}
        />
      ),
    },
  ];
  //endregion Table columns

  return (
    <div>
      <Space direction="vertical">
        <Alert
          type="info"
          message="Limit 20, means, that it's allowed to use at least 20% of department budget to pay debts to the workers with the given role.
                       It's possible to pay more but only if lowest roles have available funds and don't have unpaid debts.
                "
        />
        <Alert
          type="success"
          message="Example. Nomarch=20. All roles have not-zero debts, Governance starts to pay salary to Nomarchs at first.
                       As result, only 20% of department budget will be used to pay debts to Nomarchs, not more.
                       Then governance pays salary to other roles.
                       Suppose, they don't have unpaid debts anymore but have available funds.
                       Now this funds can be used to pay remain debts to Nomarchs."
        />
        <Alert
          type="warning"
          message="Example 2. Novice=100, Educated=0, Blessed=0, Nomarch=0
                    means, that all debts of the Novice should be paid at first, then all debts of the Educated,
                    then all debts of the Blessed and only then any debts of the Nomarchs."
        />
        <Space direction="vertical">
          <Button
            loading={departmentBudgetViewStore.isFetchingData}
            disabled={!areLimitsModified() || departmentBudgetViewStore.isFetchingData} //TODO: weird, but isFetchingData doesn't work here
            type={areLimitsModified() ? "primary" : "default"}
            onClick={() => {
              departmentBudgetViewStore.updateLimits(
                props.departmentUid,
                listItems.map((x) => x.newRoleLimit),
              );
            }}
          >
            Update
          </Button>
          {!listItems || listItems.length === 0 ? (
            <div>No data</div>
          ) : (
            <Table<ListItem> columns={columns} dataSource={listItems} />
          )}
        </Space>
      </Space>
    </div>
  );
});
