import { makeAutoObservable } from "mobx";
import { web3Store } from "../web3-store";
import { ContractsSingleton, DepartmentData } from "../../logic";

class DepartmentPropertiesEditorChainStore {
  readonly FLAG_DEPARTMENT_OPTION_APPROVE_LOW_BY_HIGH = 0x2; // see CompanyManagerStorage

  constructor() {
    makeAutoObservable(this);
  }

  private readonly web3Store = web3Store;

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  //region List of departments
  async fetchDepartmentData(
    departmentUid: number,
    refreshRequired: boolean,
  ): Promise<{
    department: DepartmentData | undefined;
    currentOptionApproveLowByHigh: boolean;
  }> {
    const cs = await this.core();
    return {
      department: await cs.cachedData.getDepartmentData(departmentUid, refreshRequired),
      currentOptionApproveLowByHigh: await cs.companyManager.getDepartmentOption(
        departmentUid,
        this.FLAG_DEPARTMENT_OPTION_APPROVE_LOW_BY_HIGH,
      ),
    };
  }

  //endregion List of departments

  //region Change data
  async updateTitle(departmentUid: number, newTitle: string) {
    const cs = await this.core();
    await cs.runAndWait(() => cs.companyManager.renameDepartment(departmentUid, newTitle));
  }

  async updateHead(departmentUid: number, newHead: string) {
    const cs = await this.core();
    await cs.runAndWait(() => cs.companyManager.setDepartmentHead(departmentUid, newHead));
  }

  async updateOptionApproveLowByHigh(departmentUid: number, newValue: boolean) {
    const cs = await this.core();
    await cs.runAndWait(() =>
      cs.companyManager.setDepartmentOption(departmentUid, this.FLAG_DEPARTMENT_OPTION_APPROVE_LOW_BY_HIGH, newValue),
    );
  }

  //endregion Change data
}

export const departmentPropertiesEditorChainStore = new DepartmentPropertiesEditorChainStore();
