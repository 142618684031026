import { makeAutoObservable, runInAction } from "mobx";
import { web3Store } from "../web3-store";
import { ContractsSingleton, ITokenInfo, RequestInfo } from "../../logic";
import { BigNumber } from "ethers";
import { DebtUtils } from "../../logic/utils/DebtUtils";

class ListDebtsViewStore {
  isFetchingData = false;
  requests: RequestInfo[] | null = null;

  private readonly web3Store = web3Store;

  constructor() {
    makeAutoObservable(this);
  }

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  //region Load data
  /** Load all approvals of the worker's request for the current epoch */
  async fetchRequestsInfo(requestUids: BigNumber[]) {
    runInAction(() => {
      this.isFetchingData = true;
    });
    try {
      const cs = await this.core();
      const r = await DebtUtils.getRequestsInfo(cs, requestUids);
      runInAction(() => {
        this.requests = r;
      });
      return r;
    } finally {
      runInAction(() => {
        this.isFetchingData = false;
      });
    }
  }

  async getDebtTokenInfo(tokenAddress: string): Promise<ITokenInfo> {
    const core = await this.core();
    return {
      name: await core.cachedData.getTokenName(tokenAddress),
      decimals: await core.cachedData.getTokenDecimals(tokenAddress),
    };
  }
  //endregion Load data
}

export const listDebtsViewStore = new ListDebtsViewStore();
