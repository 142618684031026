import { makeAutoObservable, runInAction } from "mobx";
import { ApprovalByApproverInfo, ApprovalInfo, ApproveTargetInfo, RequestStatus } from "../../logic";
import { approveRequestsChainStore } from "../chain-stores";
import { BigNumber } from "ethers";

class ApproveRequestsViewStore {
  private readonly approveRequestsChainStore = approveRequestsChainStore;

  isFetchingData = false;
  approveTargets: ApproveTargetInfo[] | null = null;
  /** Last happened error */
  error = "";

  constructor() {
    makeAutoObservable(this);
  }

  /** Hide last happened error */
  hideError() {
    runInAction(() => {
      this.error = "";
    });
  }

  //region Load data
  async fetchApproveTargets(epoch: number | undefined): Promise<ApproveTargetInfo[]> {
    runInAction(() => {
      this.isFetchingData = true;
    });
    try {
      const r = await this.approveRequestsChainStore.getListApproveTargets(epoch);
      runInAction(() => {
        this.approveTargets = r;
      });
      return r;
    } finally {
      runInAction(() => {
        this.isFetchingData = false;
      });
    }
  }

  async fetchApprovals(workerUid: BigNumber, epoch: number | undefined): Promise<ApprovalByApproverInfo[]> {
    return await this.approveRequestsChainStore.fetchApprovals(workerUid, epoch);
  }

  //endregion Load data

  //region Approve and reject
  async approveRequest(data: ApproveTargetInfo) {
    runInAction(() => {
      this.isFetchingData = true;
    });
    try {
      await this.approveRequestsChainStore.approveSingleRequest(data.workerData.workerUid);
      const ret = await this.approveRequestsChainStore.getApproval(data.workerData.workerUid);
      runInAction(() => {
        this.replaceApproval(data.workerData.workerUid, ret.approval, ret.finalStatus);
      });
    } finally {
      runInAction(() => {
        this.isFetchingData = false;
      });
    }
  }

  async rejectRequest(data: ApproveTargetInfo, explanationWhyRejected: string) {
    runInAction(() => {
      this.isFetchingData = true;
    });
    try {
      await this.approveRequestsChainStore.rejectSingleRequest(data.workerData.workerUid, explanationWhyRejected);
      const ret = await this.approveRequestsChainStore.getApproval(data.workerData.workerUid);
      runInAction(() => {
        this.replaceApproval(data.workerData.workerUid, ret.approval, ret.finalStatus);
      });
    } finally {
      runInAction(() => {
        this.isFetchingData = false;
      });
    }
  }

  async approveRequestsBatch(data: ApproveTargetInfo[]) {
    runInAction(() => {
      this.isFetchingData = true;
    });
    try {
      await this.approveRequestsChainStore.approveRequestsBatch(data.map((x) => x.workerData.workerUid));
    } finally {
      runInAction(() => {
        this.isFetchingData = false;
      });
    }
    await this.fetchApproveTargets(undefined); // let's re-read all data - for simplicity
  }

  //endregion Approve and reject

  //region Refresh approveTargets
  /**
   * Replace prev approval info by new one
   * @param a
   * @param workerUid
   * @param fs
   */
  replaceApproval(workerUid: BigNumber, a: ApprovalInfo | undefined, fs: RequestStatus) {
    if (this.approveTargets) {
      this.approveTargets = this.approveTargets.map((x) =>
        x.workerData.workerUid === workerUid ? this.setApproval(x, a, fs) : x,
      );
    }
  }

  setApproval(x: ApproveTargetInfo, a: ApprovalInfo | undefined, fs: RequestStatus): ApproveTargetInfo {
    x.approval = a;
    if (x.request) {
      x.request.requestStatus = fs;
    }
    return x;
  }

  //endregion Refresh approveTargets
}

export const approveRequestsViewStore = new ApproveRequestsViewStore();
