import { makeAutoObservable } from "mobx";
import { web3Store } from "../web3-store";
import { ContractsSingleton, DepartmentData, WorkerData } from "../../logic";
import { BigNumber, BigNumberish } from "ethers";
import { WorkerUtils } from "../../logic/utils/WorkerUtils";
import { DepartmentUtils } from "../../logic/utils/DepartmentUtils";
import { worker } from "cluster";

/**
 * Store for worker editor page
 */
class WorkersPageChainStore {
  constructor() {
    makeAutoObservable(this);
  }

  private readonly web3Store = web3Store;

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  //region List of workers
  async fetchWorkers(): Promise<BigNumber[]> {
    const cs = await this.core();
    if (await cs.cachedData.isSignerGovernance()) {
      return await WorkerUtils.getAllWorkers(cs);
    } else {
      return [];
    }
  }

  async createWorker(
    wallet: string,
    hourRate: number,
    name: string,
    role: number
  ) : Promise<WorkerData | undefined> {
    console.log("create worker 2");
    const cs = await this.core();
    if (await cs.cachedData.isSignerGovernance()) {
      console.log("create worker 3");
      return await WorkerUtils.createWorker(
        cs,
        wallet,
        hourRate,
        name,
        role
      );
    }
  }

  //endregion List of workers

  //region List departments
  async fetchDepartments(): Promise<DepartmentData[]> {
    const cs = await this.core();
    if (await cs.cachedData.isSignerGovernance()) {
      return cs.cachedData.getListDepartment();
    } else {
      return [];
    }
  }

  async moveWorkersToDepartment(workerUids: BigNumber[], department: BigNumberish) {
    const cs = await this.core();
    if (await cs.cachedData.isSignerGovernance()) {
      console.log("moveWorkersToDepartment", workerUids, department);
      await cs.runAndWait(() => cs.companyManager.moveWorkersToDepartment(workerUids, department));
    } else {
      return [];
    }
  }
  //endregion List departments
}

export const workersPageChainStore = new WorkersPageChainStore();
