import { CreateRequestPage, HomePage } from "../pages";
import { ApproveRequestsPage } from "../pages/ApprovePage";
import { WorkerDebtsPage } from "../pages/WorkerDebtsPage";
import { BudgetPage } from "../pages/BudgetPage";
import { PaymentsPage } from "../pages/PaymentsPage";
import { DepartmentPage } from "../pages/DepartmentPage";
import { WorkersPage } from "../pages/WorkersPage";

export const routes = [
  {
    path: "/",
    exact: true,
    component: HomePage,
  },
  {
    path: "/create-request",
    exact: true,
    component: CreateRequestPage,
  },
  {
    path: "/to-approve",
    exact: true,
    component: ApproveRequestsPage,
  },
  {
    path: "/show-worker-debts",
    exact: true,
    component: WorkerDebtsPage,
  },
  {
    path: "/budgets",
    exact: true,
    component: BudgetPage,
  },
  {
    path: "/payments",
    exact: true,
    component: PaymentsPage,
  },
  {
    path: "/department",
    exact: true,
    component: DepartmentPage,
  },
  {
    path: "/workers",
    exact: true,
    component: WorkersPage,
  },
];
