import {makeAutoObservable} from "mobx";
import Web3Modal from "web3modal";
import {ConnectionUtils} from "../logic/utils/ConnectionUtils";
import {ExternalProvider, JsonRpcFetchFunc} from "@ethersproject/providers/src.ts/web3-provider";

class Web3Store {
  web3modal: Web3Modal | null = null;
  provider: ExternalProvider | JsonRpcFetchFunc | null = null;

  constructor() {
    makeAutoObservable(this);
    this.connect();
  }

  get web3() {
    return ConnectionUtils.web3();
  }

  async connect() {
    console.log("before connect");
    const r = await ConnectionUtils.makeConnection();
    console.log("after connect");

    //        runInAction( () => {
    this.web3modal = r.web3modal;
    this.provider = r.provider;
    console.log("Provider is set", this.provider);
    //        })
  }

  disconnect() {
    if (this.web3modal) {
      this.web3modal.clearCachedProvider();
      this.provider = null;
    }
  }
}

export const web3Store = new Web3Store();
