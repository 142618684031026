import {Table} from "antd";
import {useStores, web3Store} from "../../stores";
import React, {useEffect, useState} from "react";
import {DepartmentBudgetInfo, DepartmentBudgetInfoValueKind, DepartmentData, RoleData} from "../../logic";
import {AmountUtils} from "../../logic/utils/AmountUtils";

/**
 * SCB-67
 * Display table with budgets.
 *                        Total             Limit for Novice                    Limit for Educated ...
 *   department 1     Budget of dep1        Max amount for novices of dep1     Max amount for educated workers of dep1
 *   department 2     Budget of dep2        Max amount for novices of dep2     ...
 *   ...
 *   department N     Budget of depN        Max amount for novices of depN
 *   all departments   Total budget                  empty                             empty
 *
 * The component is suitable to show:
 * - amounts for current week
 * - default amounts (for default budget)
 * - shares (percentage)
 *
 * All amounts are shown in salary tokens. All amounts are divided on 1e18 (precision of the salary token)
 *
 * SCB-113: show optional additional column "debts"
 */

interface BudgetTableListItem {
  key: string;
  data: DepartmentBudgetInfo;
}

interface BudgetTableColumn {
  title: string;
  dataIndex: string;
  key: string;
  render: (text: string, item: BudgetTableListItem) => JSX.Element;
  sorter?: (a: BudgetTableListItem, b: BudgetTableListItem) => number;
}

/** SCB-113: show optional additional column "debts" */
export interface DebtsColumnData {
  /** DepartmentUid => value of unpaid debts for the department, units depends on displayMode*/
  map: Map<string, number>;
}

type BudgetTableProps = {
  displayMode: DepartmentBudgetInfoValueKind;
  debts?: DebtsColumnData;
  showDebts: boolean;
};

export const BudgetTable = (props: BudgetTableProps): JSX.Element => {
  const {budgetTableViewStore} = useStores();

  const [columns, setColumns] = useState<BudgetTableColumn[]>([]);
  const [items, setItems] = useState<BudgetTableListItem[]>([]);

  useEffect(() => {
    if (web3Store.provider) {
      const fetchData = async () => {
        // fetch raw data
        await budgetTableViewStore.fetchBudgetValues(props.displayMode);

        // generate list of columns: title, department budget + N columns (where N = count of roles)
        setColumns(generateColumns(budgetTableViewStore.roles));

        // generate list of items
        setItems(
          generateItems(
            budgetTableViewStore.departments,
            budgetTableViewStore.departmentBudgetValues,
          ),
        );
      };
      fetchData().catch(console.error);
    }
  }, [props, web3Store.provider]);

  //region Generate columns
  /** Generate list of columns:
   *      department title,
   *      department budget,
   *      N columns with limits (where N = count of roles) */
  function generateColumns(roles: RoleData[]): BudgetTableColumn[] {
    const dest: BudgetTableColumn[] = [];
    dest.push({
      title: "Department title",
      key: "title",
      dataIndex: "title",
      render: (text: string, item: BudgetTableListItem) => <div>{item.data.department.title}</div>,
      sorter: (a, b) => a.data.department.title.localeCompare(b.data.department.title),
    });

    // optional "debts" column. We don't need it i.e. for %
    if (props.debts) {
      dest.push({
        title: "Unpaid debts",
        key: "debts",
        dataIndex: "debts",
        render: (text: string, item: BudgetTableListItem) => (
          <div>{props.debts?.map.has(item.key) && AmountUtils.numberToString(props.debts?.map.get(item.key) || 0)}</div>
        ),
        sorter: (a, b) => (props.debts?.map.get(a.key) || 0) - (props.debts?.map.get(b.key) || 0),
      });
    }

    dest.push({
      title: "Department budget",
      key: "budget",
      dataIndex: "budget",
      render: (text: string, item: BudgetTableListItem) => (
        <div>{AmountUtils.numberToString(item.data.budgetValue)}</div>
      ),
      sorter: (a, b) => (a.data.budgetValue || 0) - (b.data.budgetValue || 0),
    });
    for (const role of roles) {
      dest.push({
        title: `Limit for ${role.title}`,
        key: `limit_${role.uid}`,
        dataIndex: `limit_${role.uid}`,
        render: (text: string, item: BudgetTableListItem) => (
          <div>
            {item.data.limitsForRoles && AmountUtils.numberToString(item.data.limitsForRoles.get(role.uid.toString()))}
          </div>
        ),
        sorter: (a, b) => (a.data.budgetValue || 0) - (b.data.budgetValue || 0),
      });
    }

    return dest;
  }

  //endregion Generate columns

  //region Generate items
  function generateItems(
    departments: DepartmentData[],
    departmentBudgetValues: Map<string, DepartmentBudgetInfo>,
  ): BudgetTableListItem[] {
    const dest: BudgetTableListItem[] = [];
    for (const d of departments) {
      const item = departmentBudgetValues.get(d.uid.toString());
      if (item) {
        dest.push({
          key: d.uid.toString(),
          data: item,
        });
      }
    }
    return dest;
  }

  //endregion Generate items

  return (
    <div>
      {!columns || !items || items.length === 0 ? (
        <div>No data</div>
      ) : (
        <Table<BudgetTableListItem>
          columns={columns}
          dataSource={items}
          summary={(pageData) => {
            const total = pageData.reduce((prev, cur) => prev + cur.data.budgetValue, 0);
            const totalDebts = props.debts
              ? Array.from(props.debts.map.values()).reduce((prev, cur) => prev + cur, 0)
              : 0;
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <b>Total</b>
                  </Table.Summary.Cell>
                  {props.showDebts ? (
                    <>
                      <Table.Summary.Cell index={1}>{AmountUtils.numberToString(totalDebts)}</Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>{AmountUtils.numberToString(total)}</Table.Summary.Cell>
                    </>
                  ) : (
                    <>
                      <Table.Summary.Cell index={1}>{AmountUtils.numberToString(total)}</Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    </>
                  )}
                </Table.Summary.Row>
              </>
            );
          }}
        />
      )}
    </div>
  );
};
