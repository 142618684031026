/** Constants, enums, structrs from the Solidity-contracts */
import {BigNumber} from "ethers";

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
/**
 * Possible status of hours worked request
 * === IClerkTypes.RequestStatus
 */
export enum RequestStatus {
  Unknown_0,
  /** Worker has added the request, but the request is not still approved / rejected */
  New_1,
  /** The request has got enough approvals to be accepted to payment */
  Approved_2,
  /** The request has got at least one disapproval, so it cannot be accepted to payment */
  Rejected_3,
  /** Worker has canceled the request */
  Canceled_4,
}

/**
 * A worker of the team
 * ~ ICompanyManagerBvase.Worker
 */
export interface WorkerData {
  workerUid: BigNumber;
  roleUid: number;
  name: string;
  wallet: string;
  departmentUid: number;

  /**
   * undefined if debt token is default (USD)
   */
  debtToken?: string;

  /**
   * Meaning of hour rate depends on {debtToken}
   * if debtToken is default (undefined), hour rate is set in [$/hour] (no decimals)
   * if debtToken is not default, hour rate is set in [tokens/hour] with decimals of the token (i.e. 18 for TETU)
   */
  hourRate: BigNumber;
}

export const WorkerDataEmpty: WorkerData = {
  workerUid: BigNumber.from(0),
  hourRate: BigNumber.from(0),
  roleUid: 0,
  name: "error",
  wallet: "error",
  departmentUid: 0
};

export interface WorkerDataWithTitles extends WorkerData {
  departmentTitle: string | undefined;
  roleTitle: string;
}

/**
 * === ICompanyManagerBase.Department
 */
export interface DepartmentData {
  /** Unique ID of the department */
  uid: number;
  /** Address of the department's head. Can be ZERO_ADDRESS */
  head: string;
  /** Title of the department */
  title: string;
}

export const DepartmentDataEmpty = {
  uid: 0,
  head: "error",
  title: "error"
};

/**
 * === ICompanyManagerBase.RoleData
 */
export interface RoleData {
  /** RoleUid
   * it's 1-based
   * */
  uid: number;
  title: string;
  countApprovals: number;
}

/**
 * Hours worked request
 */
export interface RequestInfo {
  requestStatus: RequestStatus;
  epoch: number;

  requestUid: BigNumber;
  workerUid: BigNumber;

  departmentUid?: number;
  departmentTitle?: string;

  roleUid?: number;
  roleTitle?: string;

  countHours?: number;
  descriptionUrl?: string;

  /**
   * undefined if debt token is default (USD)
   */
  debtToken?: string;

  /**
   * Meaning of hour rate depends on {debtToken}
   * if debtToken is default (undefined), hour rate is set in [$/hour] (no decimals)
   * if debtToken is not default, hour rate is set in [tokens/hour] with decimals of the token (i.e. 18 for TETU)
   */
  hourRate?: BigNumber;
}

/**
 * An approval given by the approver to the worker.
 */
export interface ApprovalInfo {
  wasApproved: boolean;
  /** If true: the approval was given, but then the worker has canceled request,
   * so it's necessary to re-approve the request */
  wasCanceled: boolean;

  rejectionReason?: string;
}

/**
 * A worker which request can be approved by the signer.
 */
export interface ApproveTargetInfo {
  workerData: WorkerData;
  /** The department the worker currently belongs to */
  workerDepartmentTitle: string;

  /**
   * A request for the current epoch (if exist)
   * created by the worker.
   */
  request: RequestInfo;

  /**
   * An approval (if any)
   * given by the signer to this approve target.
   */
  approval?: ApprovalInfo;
}

/**
 * Approval + info about approver (wallet, workerUid if any, title of the approver-worker)
 */
export interface ApprovalByApproverInfo extends ApprovalInfo {
  approver: string;
  approverWorkerUid: BigNumber | undefined;
  approverWorkerTitle: string | undefined;
}

/**
 * Info about current signer.
 * The signer can be worker or not-registered wallet.
 */
export interface SignerInfo {
  wallet: string;
  workerData?: WorkerData;
  departmentTitle?: string;
  roleTitle?: string;
}

/**
 * Info about completely or partly unpaid debt.
 * We can get DebtUid by requestUid if necessary
 */
export interface UnpaidDebt {
  requestUid: BigNumber;
  debtUid: BigNumber;
  workerUid: BigNumber;
  departmentUid: number;
  roleUid1: number;
  /**
   * The meaning depends on the value of {debtToken}
   * If debtToken is null, the debt is in USD, so this is a value of unpaid amount in USD.
   * if debtToken is NOT null, the debt is in debt-tokens. Not zero value just indicates that the debt is not paid completely.
   *      In this case, amount of debt is stored in {unpaidAmount} (in terms of debt-tokens)
   */
  amountUSD: number;

  /**
   * undefined if debt token is default (USD)
   */
  debtToken?: string;

  /**
   * Debt-amounts for not-USD-debts
   */
  unpaidAmount?: IDebtTokenUnpaidAmount;
}

/** Value DebtsManager.unpaidAmount (not empty for the debts with not-default debt-tokens) */
export interface IDebtTokenUnpaidAmount {
  /** Total amount of the debt in terms of {debtToken} */
  amount: BigNumber;
  /** Paid part of the debt in terms of {debtToken} */
  paidAmount: BigNumber;
}

/** Summary unpaid debts info for a worker */
export interface DebtListItem {
  key: string;
  workerData: WorkerData | undefined;
  departmentTitle: string;
  roleTitle: string;
  countDebts: number;
  totalDebtsAmountUSD: number;
  listDebts: UnpaidDebt[];
}

/** A value of the department's budget.
 * The meaning of the value depends on the selected mode.
 * It can be:
 * - current share of week budget assigned to the department (percent)
 * - current share of week budget assigned to the department (amount, [salary token])
 * - amount [salary token] for next epoch (with taking into account current balance of PaymentManager)
 * - unspent amount for the past epoch [salary token]
 * */
export enum DepartmentBudgetInfoValueKind {
  UNKNOWN = 0,
  /** current share of week budget assigned to the department (percent) */
  CURRENT_SHARE_PERCENT = 1,
  /** current share of week budget assigned to the department (amount, [salary token]) */
  CURRENT_SHARE_AMOUNT_ST = 2,
  /** amount [salary token] for next epoch (with taking into account current balance of PaymentManager) */
  NEXT_EPOCH_AMOUNT_ST = 3,
  /** unspent amount for the past epoch [salary token] */
  UNSPENT_AMOUNT_ST,
}

export interface DepartmentBudgetInfo {
  department: DepartmentData;
  valueKind: DepartmentBudgetInfoValueKind;
  /** A value of the department's budget.
   * The meaning of the value depends on the selected mode,
   * see {valueKind}
   **/
  budgetValue: number;

  /**
   * Budget limits for roles.
   * key: roleUid.toString()
   * value: budget limit for the given role in the given department.
   *        same meaning as for {budgetValue}
   */
  limitsForRoles: Map<string, number>;
  roles: RoleData[];
}

export interface ITokenInfo {
  name: string;
  decimals: number;
}
