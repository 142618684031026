import { Button, Space, Table, Tag } from "antd";
import { useStores, web3Store } from "../../stores";
import React, { useEffect, useState } from "react";
import { DepartmentData, RoleData } from "../../logic";
import { AmountUtils } from "../../logic/utils/AmountUtils";
import { BigNumber } from "ethers";

/**
 * SCB-67
 * List of tables with total unpaid debts for each role and Payment buttons
 */

interface PaymentTableListItem {
  key: string;
  department: DepartmentData;
  /** Unpaid amounts for roles: 1,2,.. N*/
  unpaidAmountsForRoles: number[];
  totalUnpaidAmount: number;
  budgetUSD: number;
}

interface PaymentTableColumn {
  title: string;
  dataIndex: string;
  key: string;
  render: (text: string, item: PaymentTableListItem) => JSX.Element;
  sorter?: (a: PaymentTableListItem, b: PaymentTableListItem) => number;
}

interface Properties {
  /** Cost of 1 USD in ST */
  costUsdInST: BigNumber | undefined;
  /** This function should be called by PaymentTable when any Pay is made*/
  onPayHandler: (departmentUid: number) => Promise<void>;
}

export const PaymentTable = (props: Properties):  JSX.Element => {
  const { paymentTableViewStore } = useStores();

  const [columns, setColumns] = useState<PaymentTableColumn[]>([]);
  const [items, setItems] = useState<PaymentTableListItem[]>([]);

  useEffect(() => {
    if (web3Store.provider) {
      const fetchData = async () => {
        // fetch raw data
        await paymentTableViewStore.fetchUnpaidAmounts();

        // generate list of columns: title, department budget + N columns (where N = count of roles)
        setColumns(generateColumns(paymentTableViewStore.roles));

        // generate list of items
        setItems(
          generateItems(
            paymentTableViewStore.departments,
            paymentTableViewStore.unpaidAmounts,
          ),
        );
      };
      fetchData().catch(console.error);
    }
  }, [props, paymentTableViewStore, web3Store.provider]);

  //region Generate columns
  /** A button to approve request (make positive approval) */
  function buttonPayForDepartment(item: PaymentTableListItem) {
    return (
      <Button
        loading={paymentTableViewStore.isFetchingData}
        disabled={paymentTableViewStore.isFetchingData}
        type="primary"
        onClick={async () => {
          await paymentTableViewStore.payForDepartment(item.department.uid);
          await props.onPayHandler(item.department.uid);
        }}
      >
        Pay
      </Button>
    );
  }

  /** Generate list of columns:
   *      department title
   *      total unpaid amount by all roles
   *      N columns with unpaid amounts for each role */
  function generateColumns(roles: RoleData[]): PaymentTableColumn[] {
    const dest: PaymentTableColumn[] = [];
    dest.push({
      title: "Department title",
      key: "title",
      dataIndex: "title",
      render: (text: string, item: PaymentTableListItem) => <div>{item.department.title}</div>,
      sorter: (a, b) => a.department.title.localeCompare(b.department.title),
    });
    dest.push({
      title: "~ Budget [USD]",
      key: "budgetUSD",
      dataIndex: "budgetUSD",
      render: (text: string, item: PaymentTableListItem) => <div>{AmountUtils.numberToString(item.budgetUSD)}</div>,
      sorter: (a, b) => (a.budgetUSD || 0) - (b.budgetUSD || 0),
    });
    dest.push({
      title: "Total debts",
      key: "totalAmount",
      dataIndex: "totalAmount",
      render: (text: string, item: PaymentTableListItem) => (
        <div>{AmountUtils.numberToString(item.totalUnpaidAmount)}</div>
      ),
      sorter: (a, b) => (a.totalUnpaidAmount || 0) - (b.totalUnpaidAmount || 0),
    });
    for (const role of roles) {
      dest.push({
        title: `${role.title}, USD`,
        key: `limit_${role.uid}`,
        dataIndex: `limit_${role.uid}`,
        render: (text: string, item: PaymentTableListItem) => (
          <div>
            {item.unpaidAmountsForRoles && AmountUtils.numberToString(item.unpaidAmountsForRoles[role.uid - 1])}
          </div>
        ),
        sorter: (a, b) => (a.unpaidAmountsForRoles[role.uid - 1] || 0) - (b.unpaidAmountsForRoles[role.uid - 1] || 0),
      });
    }
    dest.push({
      title: "Actions",
      key: "action",
      dataIndex: "action",
      render: (text: string, item: PaymentTableListItem) => (
        <Space size="middle">{item.totalUnpaidAmount > 0 && buttonPayForDepartment(item)}</Space>
      ),
    });
    return dest;
  }

  //endregion Generate columns

  //region Generate items
  function generateItems(
    departments: DepartmentData[],
    unpaidAmounts: Map<string, number[]>,
  ): PaymentTableListItem[] {
    const dest: PaymentTableListItem[] = [];
    for (const d of departments) {
      const amounts = unpaidAmounts.get(d.uid.toString());
      if (amounts) {
        const weekBudgetOfDepartmentST = paymentTableViewStore.mapWeekBudgetsForDepartmentsST?.get(d.uid.toString());

        const weekBudgetOfDepartmentUSD =
          weekBudgetOfDepartmentST && props.costUsdInST
            ? weekBudgetOfDepartmentST.div(props.costUsdInST).toNumber()
            : undefined;

        dest.push({
          key: d.uid.toString(),
          department: d,
          unpaidAmountsForRoles: amounts,
          totalUnpaidAmount: amounts.reduce((p, c) => p + c, 0),
          budgetUSD: weekBudgetOfDepartmentUSD,
        });
      }
    }
    return dest;
  }

  //endregion Generate items

  return (
    <div>
      {!columns || !items || items.length === 0 ? (
        <div>No data</div>
      ) : (
        <Table<PaymentTableListItem>
          columns={columns}
          dataSource={items}
          summary={(pageData) => {
            const totalBudgetUSD = pageData.reduce((prev, cur) => prev + cur.budgetUSD, 0);
            const total = pageData.reduce((prev, cur) => prev + cur.totalUnpaidAmount, 0);
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <b>Total</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Tag color={total > totalBudgetUSD ? "red" : "green"}>
                      {AmountUtils.numberToString(totalBudgetUSD)}
                    </Tag>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>{AmountUtils.numberToString(total)}</Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      )}
    </div>
  );
};
