import { makeAutoObservable } from "mobx";
import { epochNavigatorChainStore } from "../chain-stores";
import { web3Store } from "../web3-store";
import {ContractsSingleton} from "../../logic";

class AddressContractViewStore {
  private readonly web3Store = web3Store;
  addressController: string | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  async fetchAddressController() {
    const addressController = (await this.core()).controller.address;
    this.addressController = addressController;
  }
}

export const addressContractViewStore = new AddressContractViewStore();
