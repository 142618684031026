import { Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useStores, web3Store } from "../../stores";
import { observer } from "mobx-react";

/**
 * Display current epoch
 * Allow to navigate by epochs.
 */
export const SignerInfoNav = observer(() => {
  const { signerInfoViewStore } = useStores();

  const [signerName, setSignerName] = useState<string>("");
  const [signerWallet, setSignerWallet] = useState<string>("");
  const [signerDepartment, setSignerDepartment] = useState("");
  const [signerHourRate, setSignerHourRate] = useState("");

  useEffect(() => {
    if (web3Store.provider) {
      const fetchData = async () => {
        await signerInfoViewStore.fetchSignerInfo();
        setSignerName(signerInfoViewStore.signerInfo?.workerData?.name || "");
        setSignerWallet(signerInfoViewStore.signerInfo?.wallet || "");
        setSignerDepartment(signerInfoViewStore.signerInfo?.departmentTitle || "");
        setSignerHourRate(signerInfoViewStore.signerInfo?.workerData?.hourRate.toString() || "");
      };
      fetchData().catch(console.error);
    }
  }, [web3Store.provider]);

  return (
    <div>
      <Tag>
        <>Signer: {signerName}</>
      </Tag>
      <Tag>
        <>{signerWallet}</>
      </Tag>
      <Tag>
        <>{signerDepartment}</>
      </Tag>
      <Tag>
        <>hour rate={signerHourRate}</>
      </Tag>
    </div>
  );
});
