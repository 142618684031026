import { observer } from "mobx-react";
import React from "react";
import { Button, Modal, Space, Table } from "antd";
import { workerDebtsViewStore } from "../../stores";
import { DebtListItem } from "../../logic";
import { ListDebts } from "../../components/ListDebts";
import { AmountUtils } from "../../logic/utils/AmountUtils";

/**
 * Show all unpaid debts of the selected worker
 */
export const WorkerDebtsPage = observer(() => {
  //    const { approveRequestsViewStore } = useStores()

  //region Buttons on the top
  /** A button to load unpaid debts for all company*/
  function buttonLoadDebtsForCompany() {
    return (
      <Button
        loading={workerDebtsViewStore.isFetchingData}
        disabled={workerDebtsViewStore.isFetchingData}
        type="primary"
        onClick={async () => {
          await workerDebtsViewStore.fetchDebts();
        }}
      >
        Load all unpaid debts
      </Button>
    );
  }

  //endregion Buttons on the top

  //region Modal window to view debts
  const showModalView = (item: DebtListItem) => {
    Modal.info({
      title: <div>List of debts for {item.workerData?.name}</div>,
      content: <ListDebts debts={item.listDebts} />,
      width: 1000,
    });
  };

  /** View full list of debts */
  function buttonViewDebts(item: DebtListItem) {
    return <Button onClick={() => showModalView(item)}>View debts</Button>;
  }

  //endregion Modal window to view list of received approvals

  //region Table
  const columns = [
    {
      title: "WorkerId",
      dataIndex: "workerId",
      key: "workerId",
      render: (text: string, item: DebtListItem) => <div>{item.workerData?.workerUid.toString() || "error"}</div>,
      sorter: (a: DebtListItem, b: DebtListItem) =>
        (a.workerData?.workerUid.toNumber() || 0) - (b.workerData?.workerUid.toNumber() || 0),
    },
    {
      title: "Worker",
      dataIndex: "workerName",
      key: "workerName",
      render: (text: string, item: DebtListItem) => <div>{item.workerData?.name || "error"}</div>,
      sorter: (a: DebtListItem, b: DebtListItem) => a.workerData?.name.localeCompare(b.workerData?.name || "") || 0,
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (text: string, item: DebtListItem) => <div>{item.departmentTitle}</div>,
      sorter: (a: DebtListItem, b: DebtListItem) => a.departmentTitle?.localeCompare(b.departmentTitle || "") || 0,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text: string, item: DebtListItem) => <div>{item.roleTitle}</div>,
      sorter: (a: DebtListItem, b: DebtListItem) => a.roleTitle?.localeCompare(b.roleTitle || "") || 0,
    },
    {
      title: "Count debts",
      dataIndex: "countDebts",
      key: "countDebts",
      render: (text: string, item: DebtListItem) => <div>{item.countDebts}</div>,
      sorter: (a: DebtListItem, b: DebtListItem) => a.countDebts - b.countDebts,
    },
    {
      title: "Total amount USD",
      dataIndex: "totalAmountUsd",
      key: "totalAmountUsd",
      render: (text: string, item: DebtListItem) => <div>{AmountUtils.numberToString(item.totalDebtsAmountUSD)}</div>,
      sorter: (a: DebtListItem, b: DebtListItem) => a.totalDebtsAmountUSD - b.totalDebtsAmountUSD,
    },
    {
      title: "Actions",
      key: "action",
      render: (_: unknown, item: DebtListItem) => <Space size="middle">{buttonViewDebts(item)}</Space>,
    },
  ];
  //endregion Table

  return (
    <div className="container">
      <h2>Currently unpaid debts</h2>
      <Space direction="vertical">
        <div>{buttonLoadDebtsForCompany()}</div>

        {workerDebtsViewStore.debts !== null && (
          <Table<DebtListItem>
            columns={columns}
            dataSource={workerDebtsViewStore.debts}
            summary={(pageData) => {
              const totalCountDebts = pageData.reduce((prev, cur) => prev + cur.countDebts, 0);
              const totalAmountUSD = pageData.reduce((prev, cur) => prev + cur.totalDebtsAmountUSD, 0);
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                      <b>Total</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>{AmountUtils.numberToString(totalCountDebts)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>{AmountUtils.numberToString(totalAmountUSD)}</Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        )}
      </Space>
    </div>
  );
});

