import { ContractsSingleton } from "../core/ContractsSingleton";
import {
  ApprovalByApproverInfo,
  ApprovalInfo,
  ApproveTargetInfo,
  DepartmentData,
  RequestStatus,
  WorkerData, WorkerDataEmpty,
} from "../core/DataTypes";
import { BigNumber } from "ethers";
import { RequestUtils } from "./RequestUtils";
import { PaginationUtils } from "./PaginationUtils";
import { WorkerUtils } from "./WorkerUtils";

/**
 * Utils to read approve-related data
 */
export class ApproveTargetUtils {
  /**
   * Get full list of the workers for which the signer is approver.
   * For each worker return info about request created by the worker in the current epoch (if any)
   *
   * Get full list of possible approve-targets
   * The list can include:
   * - heads of other departments (if the signer is a head of a department OR a governance)
   * - workers of the same department
   * - all workers for which the wallet of the signer is explicitly assigned as an approver (or delegate)
   * Get only real approve-targets by calling isApproverBatch
   * @param cs
   * @param signerAddress
   * @param epoch
   *      optional
   *      current epoch is used by default
   */
  static async getListApproveTargets(
    cs: ContractsSingleton,
    signerAddress: string,
    epoch: number | undefined,
  ): Promise<ApproveTargetInfo[]> {
    // let's collect all potential approve-targets here
    const initialWorkerUids: BigNumber[] = [];

    // get info about the signer
    const signerWorkerUid = await cs.cachedData.getWorkerByWallet(signerAddress);
    const workerData = signerWorkerUid.eq(0)
      ? undefined
      : await cs.cachedData.getWorkerInfo(signerWorkerUid);
    
    // get list of departments
    const departments = await cs.cachedData.getListDepartment();

    // is the signer head of a department?...
    const headOfDepartment = ApproveTargetUtils.isHeadOf(signerAddress, departments);
    if (headOfDepartment) {
      // ...so, all other heads are potential approve-targets
      initialWorkerUids.push(...(await ApproveTargetUtils.getOtherHeads(cs, headOfDepartment, departments)));
    }

    // get all co-workers
    if (workerData?.departmentUid) {
      initialWorkerUids.push(...(await cs.cachedData.getDepartmentWorkers(workerData?.departmentUid)));
    } else {
      const departmentHeadedBySigner = await cs.cachedData.getDepartmentHeadedBySigner();
      if (departmentHeadedBySigner) {
        initialWorkerUids.push(...(await cs.cachedData.getDepartmentWorkers(departmentHeadedBySigner)));
      }
    }

    // get all explicit approve-targets
    const explicityWorkerUids = await ApproveTargetUtils.getExplicitTargetWorkers(cs, signerAddress);

    // combine list and exclude duplicates if any
    const workerUids = [...initialWorkerUids, ...explicityWorkerUids].filter((v, i, a) => a.indexOf(v) === i); // distinct (remove duplicates)

    // get list of confirmed approve-targets
    const approveTargetWorkerUids = await ApproveTargetUtils.getApproveTargetsOnly(cs, signerAddress, workerUids);

    // get request uid for each pair (current-epoch : worker)
    const selectedEpoch = epoch || (await cs.cachedData.getCurrentEpoch());
    const ri = await RequestUtils.getRequestsInfo(cs, selectedEpoch, approveTargetWorkerUids);

    // get worker-data for each approve-target-worker
    const workersDataMap = new Map<string, WorkerData>();
    for (const w of await WorkerUtils.getWorkers(cs, workerUids)) {
      workersDataMap.set(w.workerUid.toString(), w);
    }

    // get already given approvals
    const approvals: {approvedValue: number, workerUid: BigNumber}[] = await PaginationUtils.chunkCall(
      workerUids
      , async chunk => (await cs.batchReader.getApprovalsMadeBySigner( //TODO: cache?
        selectedEpoch
        , workerUids
      )).map( (x: BigNumber, index: number) => ({
        approvedValue: x.toNumber(),
        workerUid: chunk[index]
      }))
    );
    const approvalsMap: Map<string, ApprovalInfo> = new Map<string, ApprovalInfo>();
    for (const a of approvals) {
      const da = ApproveTargetUtils.decodeApproval(a.approvedValue);
      if (da && a.workerUid) {
        approvalsMap.set(a.workerUid.toString(), da);
      }
    }

    // get reason of already rejected requests (if any)
    const rejectedApprovals = approvals.filter(x => (x.approvedValue & this.APPROVAL_NEGATIVE) !== 0);
    if (rejectedApprovals.length !== 0) {
      const reasons: {rejectionReason: string, workerUid: BigNumber}[] = await PaginationUtils.chunkCall(
        workerUids
        , async chunk => (await cs.batchReader.getRejectionReasons(
          await cs.cachedData.getCurrentEpoch()
          , workerUids
        )).map( (x: string, index: number) => ({
          rejectionReason: x,
          workerUid: chunk[index]
        }))
      );
      for (const r of reasons) {
        const key = r.workerUid.toString();
        if (approvalsMap.has(key) && r.rejectionReason) {
          const a = approvalsMap.get(key);
          if (a) {
            a.rejectionReason = r.rejectionReason;
            approvalsMap.set(key, a);
          }
        }
      }
    }

    return await Promise.all(
      ri.map(async (x) => {
        const workerData = workersDataMap.get(x.workerUid.toString()) || WorkerDataEmpty;
        return {
          workerData: workerData,
          workerDepartmentTitle: workerData?.departmentUid
            ? (await cs.cachedData.getDepartmentData(workerData.departmentUid))?.title ||
              workerData.departmentUid.toString()
            : "",
          request: x,
          approval: approvalsMap.get(x.workerUid.toString()),
        };
      }),
    );
  }

  /** Get single approval + final status */
  static async getApproval(
    cs: ContractsSingleton,
    workerUid: BigNumber,
  ): Promise<{ approval: ApprovalInfo | undefined; finalStatus: RequestStatus }> {
    const epoch = await cs.cachedData.getCurrentEpoch();
    const a = await cs.batchReader.getApprovalsMadeBySigner(epoch, [workerUid]);
    const approval = ApproveTargetUtils.decodeApproval(a[0].toNumber());
    if (approval && !approval?.wasApproved) {
      //TODO: we need more effective way to get the rejection reason
      const requestUid = await cs.requestsManager.getRequestUid(await cs.cachedData.getCurrentEpoch(), workerUid);
      const approvalUid = await cs.requestsManager.getApprovalUid(await cs.signer.getAddress(), requestUid);
      const rejectionReason = await cs.requestsManager.approvalExplanations(approvalUid);
      approval.rejectionReason = rejectionReason;
    }
    const fs = await cs.batchReader.getRequestStatuses(epoch, [workerUid]);

    return {
      approval: approval,
      finalStatus: fs[0] as RequestStatus,
    };
  }

  /** Get all approvals received by the worker's request in the selected epoch */
  static async getApprovals(
    cs: ContractsSingleton,
    workerUid: BigNumber,
    epoch: number | undefined,
  ): Promise<ApprovalByApproverInfo[]> {
    const dest: ApprovalByApproverInfo[] = [];

    const selectedEpoch = epoch || (await cs.cachedData.getCurrentEpoch());
    const requestUid = await cs.requestsManager.getRequestUid(selectedEpoch, workerUid);
    const data = await cs.batchReader.getApprovalsMadeForRequest(requestUid);

    for (let i = 0; i < data.approvers.length; ++i) {
      const da = this.decodeApproval(data.approvedValues[i].toNumber());
      const workerUid = await cs.cachedData.getWorkerByWallet(data.approvers[i]);
      dest.push({
        approver: data.approvers[i],
        wasApproved: da?.wasApproved || false,
        wasCanceled: da?.wasCanceled || false,
        rejectionReason: data.explanations[i] || "",
        approverWorkerUid: workerUid.eq(0) ? undefined : workerUid,
        approverWorkerTitle: workerUid.eq(0) ? undefined : (await cs.cachedData.getWorkerInfo(workerUid))?.name,
      });
    }

    return dest;
  }

  static async getApproveTargetsOnly(
    cs: ContractsSingleton,
    signerAddress: string,
    workerUids: BigNumber[],
  ): Promise<BigNumber[]> {
    const dest: BigNumber[] = [];

    const chunkSize = 10;
    for (let i = 0; i < workerUids.length; i += chunkSize) {
      const chunk = workerUids.slice(i, i + chunkSize);
      const bb = await cs.batchReader.isApproverBatch(signerAddress, chunk);
      if (bb.length === chunk.length) {
        dest.push(...chunk.filter((x, index) => bb[index]));
      }
    }

    return dest;
  }

  /**
   * Check if the given address is a head of any of the departments.
   *
   * @param signerAddress
   * @param departments
   *
   * @return ID of the department of which the signer is the head
   */
  static isHeadOf(signerAddress: string, departments: DepartmentData[]): number | undefined {
    for (const d of departments) {
      if (d.head === signerAddress) {
        return d.uid;
      }
    }
  }

  /**
   * Get workerId of all heads of the other departments
   * @param cs
   * @param department The department of which the signer is a head
   * @param departments Full list of departments
   */
  static async getOtherHeads(
    cs: ContractsSingleton,
    department: number,
    departments: DepartmentData[],
  ): Promise<BigNumber[]> {
    const dest: BigNumber[] = [];
    for (const d of departments) {
      if (d.uid !== department) {
        const workerUid = await cs.cachedData.getWorkerByWallet(d.head);
        if (!workerUid.eq(0)) {
          dest.push(workerUid);
        }
      }
    }
    return dest;
  }

  /**  See RequestsManagerStorage.APPROVAL_XXX */
  private static readonly APPROVAL_POSITIVE: number = 0x1;
  private static readonly APPROVAL_NEGATIVE: number = 0x2;
  private static readonly APPROVAL_CANCELED: number = 0x4;

  /**
   * approvalValueMask is maks with flags RequestsManagerStorage.APPROVAL_XXX
   * Decode mask to the struct.
   * If there was no approval, return undefined.
   * @param approvalValueMask
   */
  static decodeApproval(approvalValueMask: number): ApprovalInfo | undefined {
    if (approvalValueMask !== 0) {
      return {
        wasApproved: (approvalValueMask & ApproveTargetUtils.APPROVAL_POSITIVE) !== 0,
        wasCanceled: (approvalValueMask & ApproveTargetUtils.APPROVAL_CANCELED) !== 0,
      };
    }
  }

  static approvalToString(approval?: ApprovalInfo): string | undefined {
    if (approval) {
      const value = approval.wasApproved ? "Approved" : "Rejected";
      return approval.wasCanceled ? `${value} [canceled]` : value;
    }
  }

  /** Find all workers for which the signer is explicitly assigned as an approver */
  static async getExplicitTargetWorkers(cs: ContractsSingleton, signerAddress: string): Promise<BigNumber[]> {
    return await cs.batchReader.approverToWorkersBatch(signerAddress);
  }
}

