import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { epochNavigatorViewStore, useStores, web3Store } from "../../stores";
import { Alert, Button, Input, Space, Tag } from "antd";
import { ITokenInfo, RequestStatus, RequestUtils, UnpaidDebt } from "../../logic";
import { ListApprovals } from "../../components/ListApprovals";
import { BigNumber } from "ethers";
import { ListDebts } from "../../components/ListDebts";
import { DebtTokenUtils } from "../../logic/utils/DebtTokenUtils";
import { formatUnits } from "ethers/lib/utils";

/**
 * View data/status of the request for the current epoch.
 * Create new request / cancel exist request.
 */
export const CreateRequestPage = observer(() => {
  const { createRequestViewStore } = useStores();

  /** If we need to re-fetch request, we should change this value */
  const [refetchRequest, setRefetchRequest] = useState(false);

  const [countHours, setCountHours] = useState(0);
  const [urlDescription, setUrlDescription] = useState("");
  const [currentWorkerUid, setCurrentWorkerUid] = useState<BigNumber>(BigNumber.from(0));
  /** The debt for the current request */
  const [theDebt, setTheDebt] = useState<UnpaidDebt>();
  const [debtTokenInfo, setDebtTokenInfo] = useState<ITokenInfo>();

  useEffect(() => {
    if (web3Store.provider) {
      const fetchRequestData = async () => {
        await createRequestViewStore.fetchRequest();
        await createRequestViewStore.fetchCurrentWorkerUid();
        await createRequestViewStore.fetchTheDebt();
        if (DebtTokenUtils.isDefaultDebtToken(createRequestViewStore.request?.debtToken)) {
          setDebtTokenInfo({name: "$", decimals: 0});
        } else {
          const tokenInfo = await createRequestViewStore.getDebtTokenInfo(createRequestViewStore.request?.debtToken || "");
          setDebtTokenInfo(tokenInfo);
        }

        setCurrentWorkerUid(createRequestViewStore.currentWorkerUid);
        setTheDebt(createRequestViewStore.currentDebt);
      };
      fetchRequestData().catch(console.error);
    }
  }, [createRequestViewStore, refetchRequest, web3Store.provider, epochNavigatorViewStore.selectedEpoch]);

  //region Elements
  function getStatusTag(): JSX.Element {
    const status = createRequestViewStore.request?.requestStatus;
    return (
      <Tag color={RequestUtils.getStatusColor(status || RequestStatus.Unknown_0)}>
        {RequestUtils.getRequestStatus(createRequestViewStore.request?.requestStatus)}
      </Tag>
    );
  }

  const elementShowExistRequest = (
    <>
      <div>Status: {getStatusTag()}</div>
      <div>
        <Space>
          Epoch:
          {createRequestViewStore.request?.epoch}
          {!epochNavigatorViewStore.isCurrentEpoch() && <Tag color="red">Past</Tag>}
        </Space>
      </div>
      <div>Count hours: {createRequestViewStore.request?.countHours}</div>
      <div>
        Description url:{" "}
        <a href={createRequestViewStore.request?.descriptionUrl}
          target="_blank"
          rel="noreferrer"
        >{createRequestViewStore.request?.descriptionUrl}</a>
      </div>
      <div>Hour rate, {debtTokenInfo?.name}: {
        createRequestViewStore.request?.hourRate
          ? +formatUnits(createRequestViewStore.request.hourRate, debtTokenInfo?.decimals || 0)
          : ""
      } </div>
      <div>Amount, {debtTokenInfo?.name}: {
        createRequestViewStore.request?.hourRate && createRequestViewStore.request?.countHours
          ? +formatUnits(
            createRequestViewStore.request.hourRate.mul(createRequestViewStore.request.countHours),
            debtTokenInfo?.decimals || 0
          )
          : ""
      } </div>
      <div>Role: {createRequestViewStore.request?.roleTitle}</div>
      <div>Department: {createRequestViewStore.request?.departmentTitle}</div>
      <div>Worker ID: {createRequestViewStore.request?.workerUid.toString()}</div>
      <div>Request ID: {createRequestViewStore.request?.requestUid.toString()}</div>

      {epochNavigatorViewStore.isCurrentEpoch() && (
        <Button
          loading={createRequestViewStore.isFetchingRequestData}
          disabled={createRequestViewStore.isFetchingRequestData && !epochNavigatorViewStore.isCurrentEpoch()}
          type="primary"
          onClick={async () => {
            await createRequestViewStore.cancelRequest();
            setRefetchRequest(!refetchRequest);
          }}
        >
          Cancel request
        </Button>
      )}
    </>
  );

  const elementEnterNewRequest = (
    <>
      <div>Status: {RequestUtils.getRequestStatus(createRequestViewStore.request?.requestStatus)}</div>
      <h2>Create new request</h2>
      Hours worked:
      <Input
        value={countHours}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setCountHours(Number(event.target.value));
        }}
        style={{ marginBottom: 20 }}
      />
      Hours Report URL (max 100 chars):
      <Input
        value={urlDescription}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setUrlDescription(event.target.value);
        }}
        style={{ marginBottom: 20 }}
      />
      <Button
        loading={createRequestViewStore.isFetchingRequestData}
        disabled={createRequestViewStore.isFetchingRequestData}
        onClick={async () => {
          await createRequestViewStore.createRequest(countHours, urlDescription);
          setRefetchRequest(!refetchRequest);
        }}
        type="primary"
      >
        Create request
      </Button>
    </>
  );

  const elementNoRequest = (
    <Alert message={`There were no request for epoch ${epochNavigatorViewStore.selectedEpoch}`} type="info" />
  );
  //endregion Elements

  return (
    <div className="container">
      <Space wrap direction="vertical">
        <h2>My request</h2>
        {createRequestViewStore.request?.requestStatus === RequestStatus.Unknown_0 ||
        createRequestViewStore.request?.requestStatus === RequestStatus.Canceled_4
          ? epochNavigatorViewStore.isCurrentEpoch()
            ? elementEnterNewRequest
            : elementNoRequest
          : elementShowExistRequest}

        {createRequestViewStore.currentWorkerUid && (
          <div>
            <h2>Received approvals</h2>
            {!currentWorkerUid.eq(0) && <ListApprovals workerUid={currentWorkerUid} />}
          </div>
        )}

        {theDebt && (
          <>
            <h2>Unpaid debt for the request</h2>
            <ListDebts debts={[theDebt]} />
          </>
        )}
      </Space>
    </div>
  );
});
