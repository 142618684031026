import { ExternalProvider, JsonRpcFetchFunc } from "@ethersproject/providers/src.ts/web3-provider";
import { ContractTransaction, ethers } from "ethers";
import {
  IApprovalsManager,
  IApprovalsManager__factory,
  IBatchReader,
  IBatchReader__factory,
  ICompanyManager,
  ICompanyManager__factory,
  IController,
  IController__factory,
  IDebtsManager,
  IDebtsManager__factory,
  IPaymentsManager,
  IPaymentsManager__factory,
  IPriceOracle,
  IPriceOracle__factory,
  IRequestsManager,
  IRequestsManager__factory,
} from "../../typechain";
import { ControllerAddress } from "../../network";
import { JsonRpcSigner } from "@ethersproject/providers/src.ts/json-rpc-provider";
import { IContractsSingleton } from "./IContractsSingleton";
import { AppStaticDataCache } from "./AppStaticDataCache";

/**
 * A singleton to access to all contracts of the app.
 * Lazy initialization
 */
export class ContractsSingleton implements IContractsSingleton {
  public readonly signer: JsonRpcSigner;

  public readonly controller: IController;
  public readonly requestsManager: IRequestsManager;
  public readonly companyManager: ICompanyManager;
  public readonly approvalsManager: IApprovalsManager;
  public readonly debtsManager: IDebtsManager;
  public readonly paymentsManager: IPaymentsManager;
  public readonly priceOracle: IPriceOracle;
  public readonly batchReader: IBatchReader;

  private static _instance: ContractsSingleton;
  private _provider: ethers.providers.Web3Provider; //TODO: can it be changed, what about memory leaks here?

  public readonly cachedData: AppStaticDataCache;

  constructor(
    signer: JsonRpcSigner,
    controller: IController,
    requestsManager: IRequestsManager,
    companyManager: ICompanyManager,
    approvalsManager: IApprovalsManager,
    debtsManager: IDebtsManager,
    paymentsManager: IPaymentsManager,
    pricOracle: IPriceOracle,
    batchReader: IBatchReader,
    provider: ethers.providers.Web3Provider,
  ) {
    this.signer = signer;
    this.controller = controller;
    this.companyManager = companyManager;
    this.requestsManager = requestsManager;
    this.approvalsManager = approvalsManager;
    this.debtsManager = debtsManager;
    this.paymentsManager = paymentsManager;
    this.priceOracle = pricOracle;
    this.batchReader = batchReader;
    this._provider = provider;

    this.cachedData = new AppStaticDataCache(this);
  }

  /**
   *
   * @param provider this.web3Store.provider!
   */
  public static async get(provider: ExternalProvider | JsonRpcFetchFunc | null): Promise<ContractsSingleton> {
    if (!this._instance && provider) {
      // we need to get a signer. It's a bit tricky in Web3Store:
      // https://github.com/ethers-io/ethers.js/issues/775
      const wallet = new ethers.providers.Web3Provider(provider);

      // always connect to controller
      // we need to get addresses of all other contracts
      const signer = wallet.getSigner();
      const controller = IController__factory.connect(ControllerAddress, signer);
      const requestsManager = IRequestsManager__factory.connect(await controller.requestsManager(), signer);
      const companyManager = ICompanyManager__factory.connect(await controller.companyManager(), signer);
      const approvalsManager = IApprovalsManager__factory.connect(await controller.approvalsManager(), signer);
      const debtsManager = IDebtsManager__factory.connect(await controller.debtsManager(), signer);
      const paymentsManager = IPaymentsManager__factory.connect(await controller.paymentsManager(), signer);
      const pricOracle = IPriceOracle__factory.connect(await controller.priceOracle(), signer);
      const batchReader = IBatchReader__factory.connect(await controller.batchReader(), signer);
      this._instance = new ContractsSingleton(
        signer,
        controller,
        requestsManager,
        companyManager,
        approvalsManager,
        debtsManager,
        paymentsManager,
        pricOracle,
        batchReader,
        wallet,
      );
    }
    return this._instance;
  }

  public async runAndWait(callback: () => Promise<ContractTransaction>, stopOnError = true): Promise<void> {
    const start = Date.now();
    const tr = await callback();
    const r0 = await tr.wait(1);

    console.log("tx sent", tr.hash, "gas used:", r0.gasUsed.toString());

    let receipt;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      receipt = await this._provider.getTransactionReceipt(tr.hash);
      if (receipt) {
        break;
      }
      console.log("not yet complete", tr.hash);
      await new Promise((r) => setTimeout(r, 10000)); //wait 10 secs
    }
    console.log("transaction result", tr.hash, receipt?.status);
    console.log("gas used", receipt.gasUsed.toString());
    if (receipt?.status !== 1 && stopOnError) {
      throw Error("Wrong status!");
    }
    console.log("runAndWait completed", start);
  }
}

