import { makeAutoObservable, runInAction } from "mobx";
import {
  ContractsSingleton,
  DepartmentBudgetInfo,
  DepartmentBudgetInfoValueKind,
  DepartmentData,
  RoleData,
} from "../../logic";
import { web3Store } from "../web3-store";
import { BudgetUtils } from "../../logic/utils/BudgetUtils";

class BudgetTableViewStore {
  isFetchingData = false;
  departments: DepartmentData[] | null = null;
  roles: RoleData[] | null = null;

  /** key = departmentUid.toString()
   *  value: budget value. It depends on selected mode.
   * */
  departmentBudgetValues: Map<string, DepartmentBudgetInfo> | null = null;

  private readonly web3Store = web3Store;

  constructor() {
    makeAutoObservable(this);
  }

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  //region Load data
  /** Load budget values of specified kind */
  async fetchBudgetValues(kind: DepartmentBudgetInfoValueKind) {
    runInAction(() => {
      this.isFetchingData = true;
    });
    try {
      const cs = await this.core();
      const r = await BudgetUtils.getBudgetValue(cs, kind);
      runInAction(() => {
        this.departmentBudgetValues = r.departmentBudgetValues;
        this.departments = r.departments;
        this.roles = r.roles;
      });
      return r;
    } finally {
      runInAction(() => {
        this.isFetchingData = false;
      });
    }
  }

  //endregion Load data
}

export const budgetTableViewStore = new BudgetTableViewStore();
