import { action, makeObservable, observable, runInAction } from "mobx";
import { createRequestChainStore } from "../chain-stores";
import { ITokenInfo, RequestInfo, UnpaidDebt } from "../../logic";
import { ErrorUtils } from "../../logic/utils/ErrorUtils";
import { BigNumber } from "ethers";
import { epochNavigatorViewStore } from "./EpochNavigatorViewStore";

class CreateRequestViewStore {
  private readonly chainStore = createRequestChainStore;
  private readonly epochNavigatorViewStore = epochNavigatorViewStore;

  isFetchingRequestData = false;
  request: RequestInfo | null = null;
  currentWorkerUid: BigNumber | undefined;

  /** The debt for the current request */
  currentDebt: UnpaidDebt | undefined;

  /** Last happened error */
  error = "";

  constructor() {
    //makeAutoObservable(this);
    makeObservable(this, {
      isFetchingRequestData: observable,
      request: observable.ref,
      cancelRequest: action,
      fetchRequest: action,
      createRequest: action,
    });
  }

  /** Hide last happened error */
  hideError() {
    runInAction(() => {
      this.error = "";
    });
  }

  /**
   * Cancel exist request on the server.
   * It allows to create new request instead of the current.
   */
  async cancelRequest() {
    runInAction(() => {
      // https://mobx.js.org/actions.html#asynchronous-actions
      this.isFetchingRequestData = true;
    });
    try {
      await this.chainStore.cancelRequest();
    } catch (e: unknown) {
      runInAction(() => {
        this.error = ErrorUtils.getErrorTextToDisplay(e);
      });
    } finally {
      this.request = null;
      runInAction(() => {
        // https://mobx.js.org/actions.html#asynchronous-actions
        this.isFetchingRequestData = false;
      });
    }
  }

  async fetchRequest(): Promise<RequestInfo> {
    runInAction(() => {
      this.isFetchingRequestData = true;
    });
    try {
      const r = await this.chainStore.getCurrentRequest(epochNavigatorViewStore.selectedEpoch);
      runInAction(() => {
        this.request = r;
      });
      return r;
    } finally {
      runInAction(() => {
        this.isFetchingRequestData = false;
      });
    }
  }

  async createRequest(countHours: number, urlDescription: string) {
    runInAction(() => {
      this.isFetchingRequestData = true;
    });
    try {
      await this.chainStore.createRequest(countHours, urlDescription);
    } catch (e: unknown) {
      runInAction(() => {
        this.error = ErrorUtils.getErrorTextToDisplay(e);
      });
    } finally {
      runInAction(() => {
        this.isFetchingRequestData = false;
        this.request = null;
      });
    }
  }

  async fetchCurrentWorkerUid() {
    const wid = await this.chainStore.getCurrentWorkerUid();
    runInAction(() => {
      this.currentWorkerUid = wid;
    });
  }

  async fetchTheDebt() {
    const theDebt = this.request ? await this.chainStore.getUnpaidDebt(this.request.requestUid) : undefined;
    runInAction(() => {
      this.currentDebt = theDebt;
    });
  }

  async getDebtTokenInfo(tokenAddress: string): Promise<ITokenInfo> {
    const core = await this.chainStore.core();
    return {
      name: await core.cachedData.getTokenName(tokenAddress),
      decimals: await core.cachedData.getTokenDecimals(tokenAddress),
    };
  }
}

export const createRequestViewStore = new CreateRequestViewStore();
