import React from "react";
import { useStores } from "../../stores";
import { Button, Space } from "antd";
import { observer } from "mobx-react";

export const HomePage = observer(() => {
  const { navViewStore } = useStores();

  return (
    <div>
      <Space size="large">
        <Button
          loading={navViewStore.isFetchingData}
          disabled={navViewStore.isFetchingData}
          type="primary"
          onClick={async () => {
            await navViewStore.connect();
          }}
        >
          Connect
        </Button>
        <Button
          loading={navViewStore.isFetchingData}
          disabled={navViewStore.isFetchingData}
          type="primary"
          onClick={async () => {
            await navViewStore.disconnect();
          }}
        >
          Disconnect
        </Button>
      </Space>
      <h2>Payroll Clerk 2</h2>
      <h3>The purpose of the application</h3>
      <div>The application is intended to record hours worked and pay salaries.</div>
      <h3>How it works</h3>
      <div>
        The salary is payed by <b>epoch</b>. Usually an epoch is equal to a week.
      </div>
      <div>
        At the end of each epoch, all workers register hours worked by creating <b>requests</b>. The request contains
        the number of hours and a link to a document containing a detailed description of the work performed.
      </div>
      <div>
        In order for the request to be paid, it must be <b>approved</b>. Approval of the application is carried out by
        other workers. Request of ordinal worker requires single positive approval. Request of nomarch requires two
        positive approvals.
      </div>
      <div>
        Approved request is converted to <b>the debt</b> - the company`&apos;`s debt to the employee. So, at the end of the
        each epoch new set of debts is registered.
      </div>
      <div>
        Co-workers can disapprove or reject the request. It means that request would be rejected and won`&apos;`t be paid. If
        rejected request was already converted to company`&apos;`s debt, the debt is revoked after rejection.
      </div>
      <div>
        A worker can cancel request and then recreated it, i.e. with different number of hours. As result all received
        approvals will be canceled, the recreated request must be re-approved.
      </div>
      <div>
        At the end of epoch the governance starts <b>new epoch</b>. As result, status of all requests of previous epoch
        are fixed. All approved requests, converted to company`&apos;`s debts, would be paid. All rejected or un-approved
        request won`&apos;`t be paid.
      </div>
      <div>
        After starting new epoch, the governance can <b>pay salary</b>. Paying salary means that all unpaid debts within
        the <b>weekly budget</b> will be paid. The debts are paid in the order they are registered. If there is not
        enough funds, some debts may remain fully or partially unpaid. They will be paid when the next salary is paid.
      </div>
      <h3>Departments</h3>
      All workers are divided into departments. Each department has a head and separate week budget. The head is allowed
      to configure properties of the department and its workers, i.e. change names, roles, hour rates, wallets and so
      on.
      <h3>Roles</h3>
      Each worker has a role or grade. The list of grads is configurable by governance. There are following grades at
      this moment:
      <ul>
        <li>Novice</li>
        <li>Educated</li>
        <li>Blessed</li>
        <li>Nomarch</li>
      </ul>
      <b>Nomarch</b> is special: there is only one nomarch in the department - the head of the department.
      <h4>Role limits</h4>
      <div>
        The head of the department can set up budget-limits for the roles. The purpose of the limits is control the
        process of paying salaries in conditions of lack of funds. I.e. limit 20, means, that it`&apos;`s allowed to use at
        least 20% of department budget to pay debts to the workers with the given role. It`&apos;`s possible to pay more but
        only if lowest roles have available funds and don`&apos;`t have unpaid debts.
      </div>
      <div>
        Example. Nomarch=20. All roles have not-zero debts, Governance starts to pay salary to Nomarchs at first. As
        result, only 20% of department budget will be used to pay debts to Nomarchs, not more. Then governance pays
        salary to other roles. Suppose, they don`&apos;`t have unpaid debts anymore but have available funds. Now this funds
        can be used to pay remain debts to Nomarchs.
      </div>
      <div>
        Example 2. Novice=100, Educated=0, Blessed=0, Nomarch=0 means, that all debts of the Novice should be paid at
        first, then all debts of the Educated, then all debts of the Blessed and only then any debts of the Nomarchs.
      </div>
      <h3>Request</h3>
      <div>
        Request contains count of hours worked and URL to the document with detailed description of what these hours
        were spent on. The size of URL shouldn`&apos;`t exceed 20 chars.
      </div>
      <div>
        Any worker is able to create only single request for specified epoch. If you need to change request properties,
        you should cancel exist request and recreate the request. Cancellation of the request leads to cancellation of
        all approvals received by the request. So, recreated request must be re-approved.
      </div>
      <h3>Approving permissions</h3>
      Any worker`&apos;`s request can be approved by the head of the worker`&apos;`s department.
      <h4>Option &quot;A worker with higher role can approve requests of the worker with lower role&quot;</h4>
      The head of the department can turn ON the option &quot;A worker with higher role can approve requests of the worker
      with lower role&quot; on Department-page. If this option active, then workers with higher role can approve requests of
      the workers with lower role if both workers belong to the same department.
      <h4>Explicit approvers and delegates</h4>A head of department can assign any wallet as <b>approver</b> to the
      worker. The approver can temporary delegate his approving permission to any other wallet (<b>delegate</b>) and
      revoke it back in some time. Such delegation can be useful if i.e. the approve has a vacation. If the permission
      is delegated, the approver cannot approve request anymore, but the delegate can approve them instead.
    </div>
  );
});
