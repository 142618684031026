import { makeAutoObservable } from "mobx";
import { epochNavigatorChainStore } from "../chain-stores";

/**
 * Store to get info about current epoch
 * We need to display it at the top of the app
 */

class EpochNavigatorViewStore {
  private readonly chainStore = epochNavigatorChainStore;

  isFetchingRequestData = false;
  currentEpoch: number | undefined;
  selectedEpoch: number | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchCurrentEpoch() {
    const epoch = await this.chainStore.getCurrentEpoch();
    this.currentEpoch = epoch;
    this.selectedEpoch = epoch;
  }

  isCurrentEpoch() {
    return this.currentEpoch === this.selectedEpoch;
  }
}

export const epochNavigatorViewStore = new EpochNavigatorViewStore();
