import React, { Key, useEffect, useState } from "react";
import { ITokenInfo, WorkerData, WorkerDataWithTitles, ZERO_ADDRESS } from "../../logic";
import {Button, Input, Modal, Radio, RadioChangeEvent, Space, Table, Tag, Typography} from "antd";
import {listWorkersEditorViewStore, web3Store} from "../../stores";
import {BigNumber} from "ethers";
import {observer} from "mobx-react";
import { DebtTokenUtils } from "../../logic/utils/DebtTokenUtils";

interface ListItem {
  key: string;
  data: WorkerDataWithTitles;

  /**
   * hour rate as float number (without decimals)
   */
  hourRateFloat: number;
  token?: ITokenInfo;
}

type ListProperties = {
  workerUids: BigNumber[];
  useMultiSelection?: boolean;
  onChangeSelection?: (selectedWorkerUids: string[]) => Promise<void>;

  /** To clean up list of selected workers we increase this counter */
  cleanupSelection?: number;
};

/**
 * Allow to edit properties of the workers from the given list.
 * It's allowed to change role, department, title, hour rate, name and so on.
 */
export const ListWorkersEditor: React.FC<ListProperties> = observer((props) => {
  const [listItems, setListItem] = useState<ListItem[]>([]);
  useEffect(() => {
    if (web3Store.provider) {
      const fetchData = async () => {
        await listWorkersEditorViewStore.fetchWorkers(props.workerUids);
        recreateListItems();
      };
      fetchData().catch(console.error);
      console.log(props);
    }
  }, [props, web3Store.provider]);

  //region Create list of items
  function createListItems(workers: WorkerDataWithTitles[]): ListItem[] {
    return workers.map((x) => {
      const token: ITokenInfo | undefined = DebtTokenUtils.isDefaultDebtToken(x.debtToken)
        ? undefined
        : listWorkersEditorViewStore.mapTokenDecimals.get(x.debtToken || "");
      return {
        key: x.workerUid.toString(),
        data: x,
        token,
        hourRateFloat: DebtTokenUtils.getHourRateToDisplay(x.debtToken, x.hourRate)
      };
    });
  }

  //endregion Create list of items

  //region All modal windows
  const [selectedWorker, setSelectedWorker] = useState<WorkerData | undefined>();

  function recreateListItems() {
    if (listWorkersEditorViewStore.workers) {
      const list = createListItems(listWorkersEditorViewStore.workers);
      setListItem(list);
    }
  }

  //endregion All modal windows

  //region Modal window to edit worker name
  const [isModalRenameVisible, setIsModalRenameVisible] = useState(false);
  const [workerName, setWorkerName] = useState("");

  const showModalRename = (listItem: ListItem) => {
    setWorkerName(listItem.data.name);
    setSelectedWorker(listItem.data);
    setIsModalRenameVisible(true);
  };

  function modalToRename(): JSX.Element {
    return (
      <Modal
        title={`Enter new name for ${selectedWorker?.name || ""}`}
        visible={isModalRenameVisible}
        onOk={async () => {
          setIsModalRenameVisible(false);
          await listWorkersEditorViewStore.changeWorkerName(selectedWorker?.workerUid || BigNumber.from(0), workerName);
          recreateListItems();
        }}
        onCancel={() => {
          setIsModalRenameVisible(false);
        }}
      >
        <Input
          value={workerName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setWorkerName(event.target.value);
          }}
          style={{marginBottom: 20}}
        />
      </Modal>
    );
  }

  //endregion Modal window to edit worker name

  //region Modal window to edit debt token and hour rate
  const [isModalHourRateVisible, setIsModalHourRateVisible] = useState(false);
  /**
   * Hour rate can be with and without decimals depending on the kind of debt-token.
   * hourRateNum is float number without decimals, i.e. 4012.0 tetu or $100
   */
  const [hourRateNum, setHourRateNum] = useState(0.0);
  const [debtToken, setDebtToken] = useState("");

  const showModalHourRate = (listItem: ListItem) => {
    setHourRateNum(DebtTokenUtils.getHourRateToDisplay(listItem.data.debtToken, listItem.data.hourRate));
    setDebtToken(DebtTokenUtils.getDebtTokenToEdit(listItem.data.debtToken));
    setSelectedWorker(listItem.data);
    setIsModalHourRateVisible(true);
  };

  function modalToHourRate(): JSX.Element {
    return (
      <Modal
        title = {`Enter new hourly rate for ${selectedWorker?.name} [debt tokens/hour]`}
        visible = {isModalHourRateVisible}
        onOk = {async () => {
          setIsModalHourRateVisible(false);
          if (debtToken || debtToken !== selectedWorker?.debtToken) {
            await listWorkersEditorViewStore.changeDebtTokenAndHourRate(
              selectedWorker?.workerUid || BigNumber.from(0),
              DebtTokenUtils.getHourRateToSave(debtToken, hourRateNum),
              DebtTokenUtils.getDebtTokenToSave(debtToken),
            );
          } else {
            await listWorkersEditorViewStore.changeWorkerHourRate(
              selectedWorker?.workerUid || BigNumber.from(0),
              Number(hourRateNum)
            );
          }
          recreateListItems();
        }}
        onCancel = {() => {
          setIsModalHourRateVisible(false);
        }}
      >
        <Typography.Text>Hourly rate</Typography.Text>
        <Input
          value = {hourRateNum}
          onChange = {(event: React.ChangeEvent<HTMLInputElement>) => {
            setHourRateNum(Number(event.target.value));
          }}
          style={{marginBottom: 20}}
        />

        <Typography.Text>Debt token address (empty for USD)</Typography.Text>
        <Input
          value = {debtToken}
          onChange = {(event: React.ChangeEvent<HTMLInputElement>) => {
            setDebtToken(event.target.value);
          }}
          style={{marginBottom: 20}}
        />
      </Modal>
    );
  }

  //endregion Modal window to edit debt token and hour rate

  //region Modal window to edit worker wallet
  const [isModalWalletVisible, setIsModalWalletVisible] = useState(false);
  const [workerWallet, setWorkerWallet] = useState("");

  const showModalWallet = (listItem: ListItem) => {
    setWorkerWallet(listItem.data.wallet);
    setSelectedWorker(listItem.data);
    setIsModalWalletVisible(true);
  };

  function modalToWallet(): JSX.Element {
    return (
      <Modal
        title={`Enter new wallet for ${selectedWorker?.name}`}
        visible={isModalWalletVisible}
        onOk={async () => {
          setIsModalWalletVisible(false);
          await listWorkersEditorViewStore.changeWorkerWallet(selectedWorker?.workerUid || BigNumber.from(0), workerWallet);
          recreateListItems();
        }}
        onCancel={() => {
          setIsModalWalletVisible(false);
        }}
      >
        <Input
          value={workerWallet}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setWorkerWallet(event.target.value);
          }}
          style={{marginBottom: 20}}
        />
      </Modal>
    );
  }

  //endregion Modal window to edit worker name

  //region Modal window to edit worker role
  const [isModalRoleVisible, setIsModalRoleVisible] = useState(false);
  const [workerRole1, setWorkerRole1] = useState(0);

  const showModalRole = (listItem: ListItem) => {
    setWorkerRole1(listItem.data.roleUid);
    setSelectedWorker(listItem.data);
    setIsModalRoleVisible(true);
  };

  function getRolesSelector(): JSX.Element {
    const rows = [];
    if (listWorkersEditorViewStore.roles) {
      for (const d of listWorkersEditorViewStore.roles) {
        rows.push(
          <Radio key={d.uid} value={d.uid}>
            {d.title}
          </Radio>,
        );
      }
    }
    return (
      <Radio.Group
        onChange={(event: RadioChangeEvent) => {
          setWorkerRole1(Number(event.target.value));
        }}
        value={workerRole1}
        style={{marginBottom: 20}}
      >
        {rows}
      </Radio.Group>
    );
  }

  function modalToRole(): JSX.Element {
    return (
      <Modal
        title={`Enter new role for ${selectedWorker?.name}`}
        visible={isModalRoleVisible}
        onOk={async () => {
          setIsModalRoleVisible(false);
          await listWorkersEditorViewStore.changeWorkerRole(selectedWorker?.workerUid || BigNumber.from(0), workerRole1);
          recreateListItems();
        }}
        onCancel={() => {
          setIsModalRoleVisible(false);
        }}
      >
        {getRolesSelector()}
      </Modal>
    );
  }

  //endregion Modal window to edit worker role

  //region Table columns
  function buttonRename(item: ListItem) {
    return <Button onClick={() => showModalRename(item)}>Name</Button>;
  }

  function buttonChangeRole(item: ListItem) {
    return <Button onClick={() => showModalRole(item)}>Role</Button>;
  }

  function buttonChangeWallet(item: ListItem) {
    return <Button onClick={() => showModalWallet(item)}>Wallet</Button>;
  }

  function buttonSetHourRate(item: ListItem) {
    return <Button onClick={() => showModalHourRate(item)}>HourRate</Button>;
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "workerId",
      key: "workerId",
      render: (text: string, item: ListItem) => <div>{item.data.workerUid.toString()}</div>,
      sorter: (a: ListItem, b: ListItem) => a.data.workerUid.sub(b.data.workerUid).toNumber(),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, item: ListItem) => <div>{item.data.name}</div>,
      sorter: (a: ListItem, b: ListItem) => a.data.name.localeCompare(b.data.name),
    },
    {
      title: "Wallet",
      dataIndex: "wallet",
      key: "wallet",
      render: (text: string, item: ListItem) => <div>{item.data.wallet}</div>,
      sorter: (a: ListItem, b: ListItem) => a.data.wallet.localeCompare(b.data.wallet),
    },
    {
      title: "Department",
      dataIndex: "departmentTitle",
      key: "departmentTitle",
      render: (text: string, item: ListItem) => (
        <div>
          {item.data.departmentUid}: {item.data.departmentTitle}
        </div>
      ),
      sorter: (a: ListItem, b: ListItem) => (a.data.departmentTitle || "").localeCompare(b.data.departmentTitle || ""),
    },
    {
      title: "Role",
      dataIndex: "roleTitle",
      key: "roleTitle",
      render: (text: string, item: ListItem) => <Tag>{item.data.roleTitle}</Tag>,
      sorter: (a: ListItem, b: ListItem) => (a.data.roleTitle || "").localeCompare(b.data.roleTitle || ""),
    },
    {
      title: "Hour rate",
      dataIndex: "hourRate",
      key: "hourRate",
      render: (text: string, item: ListItem) => <div>{item.hourRateFloat}</div>,
      sorter: (a: ListItem, b: ListItem) => a.hourRateFloat - b.hourRateFloat,
    },
    {
      title: "Debt token",
      dataIndex: "token",
      key: "debtToken",
      render: (text: string, item: ListItem) => <div>{item.token?.name || "$"}</div>,
      sorter: (a: ListItem, b: ListItem) => (a.token?.name || "").localeCompare(b.token?.name || ""),
    },
    {
      title: "Actions",
      key: "action",
      render: (_: unknown, item: ListItem) => (
        <Space>
          {buttonRename(item)}
          {buttonChangeRole(item)}
          {buttonSetHourRate(item)}
          {buttonChangeWallet(item)}
        </Space>
      ),
    },
  ];
  //endregion Table columns

  //region Selection
  const [selectedRowKeys, setRowKeys] = useState<Key[]>([]);
  const [checkedItems, setCheckedItems] = useState<ListItem[]>([]);

  /** If parent changes value of cleanupSelection, we need to clean up the selection  */
  useEffect( () => {
    setCheckedItems([]);
    setRowKeys([]);
  }, [props.cleanupSelection]);

  /** multi-selection */
  const rowSelection = {
    selectedRowKeys,
    onChange: async (selectedRowKeys: Key[], selectedRows: ListItem[]) => {
      setCheckedItems(selectedRows);
      setRowKeys(selectedRowKeys);
      if (props.onChangeSelection) {
        await props.onChangeSelection(selectedRows.map(x => x.key));
      }
    },
    hideSelectAll: true,
  };
  //endregion Selection

  return (
    <div>
      <Space direction="vertical">
        {!listItems || listItems.length === 0 ? (
          <div>No workers</div>
        ) : (
          <Table<ListItem>
            columns={columns}
            dataSource={listItems}
            rowSelection = {
              props.useMultiSelection
                ? {
                  type: "checkbox",
                  ...rowSelection,
                }
                : undefined
            }

          />
        )}
      </Space>

      {/*Modal windows to enter new values*/}
      {modalToRename()}
      {modalToWallet()}
      {modalToHourRate()}
      {modalToRole()}
    </div>
  );
});
