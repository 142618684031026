/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IDebtsManagerBase,
  IDebtsManagerBaseInterface,
} from "../IDebtsManagerBase";

const _abi = [
  {
    inputs: [],
    name: "ErrorAccessDenied",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorActionIsAlreadyDone",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "",
        type: "uint16",
      },
    ],
    name: "ErrorAlreadyHead",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorArraysHaveDifferentLengths",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorDataNotChanged",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "worker",
        type: "uint64",
      },
    ],
    name: "ErrorDebtTokenIsUsed",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "debtToken",
        type: "address",
      },
    ],
    name: "ErrorDebtTokenNotEnabled",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorEmptyArrayNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorEmptyString",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorGovernanceOrDepartmentHeadOnly",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorIncorrectAmount",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DebtUid",
        name: "debtUid",
        type: "uint64",
      },
    ],
    name: "ErrorIncorrectDebtConversion",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.HourRate",
        name: "rate",
        type: "uint16",
      },
    ],
    name: "ErrorIncorrectRate",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "providedAddress",
        type: "address",
      },
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "worker",
        type: "uint64",
      },
    ],
    name: "ErrorNotApprover",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorNotEnoughFund",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "debtToken",
        type: "address",
      },
    ],
    name: "ErrorPriceOracleForDebtTokenNotFound",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "currentLength",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxAllowedLength",
        type: "uint256",
      },
    ],
    name: "ErrorTooLongString",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "request",
        type: "uint256",
      },
    ],
    name: "ErrorUnapprovedRequestExists",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.DepartmentUid",
        name: "uid",
        type: "uint16",
      },
    ],
    name: "ErrorUnknownDepartment",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.RequestUid",
        name: "uid",
        type: "uint256",
      },
    ],
    name: "ErrorUnknownRequest",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "IClerkTypes.WorkerUid",
        name: "uid",
        type: "uint64",
      },
    ],
    name: "ErrorWorkerNotFound",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "errorCode",
        type: "uint256",
      },
    ],
    name: "ErrorZeroAddress",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "ErrorZeroPrice",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "errorCode",
        type: "uint256",
      },
    ],
    name: "ErrorZeroValueNotAllowed",
    type: "error",
  },
];

export class IDebtsManagerBase__factory {
  static readonly abi = _abi;
  static createInterface(): IDebtsManagerBaseInterface {
    return new utils.Interface(_abi) as IDebtsManagerBaseInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IDebtsManagerBase {
    return new Contract(address, _abi, signerOrProvider) as IDebtsManagerBase;
  }
}
