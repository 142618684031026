import { RequestInfo, RequestStatus } from "../core/DataTypes";
import { ContractsSingleton } from "../core/ContractsSingleton";
import { BigNumber } from "ethers";
import { PaginationUtils } from "./PaginationUtils";

/**
 * Utils to read request-related data from chain
 */
export class RequestUtils {
  /**
   * Load info about worker's request for the current epoch.
   * If the request doesn't exist, we will load empty data
   * with requestStatus === 0
   * @param cs
   * @param epoch
   *      For which epoch we need request
   */
  static async getCurrentRequestInfo(cs: ContractsSingleton, epoch: number | undefined): Promise<RequestInfo> {
    const selectedEpoch = epoch || (await cs.cachedData.getCurrentEpoch());
    const signerWorkerUid = await cs.cachedData.getWorkerByWallet(await cs.signer.getAddress());
    return (await RequestUtils.getRequestsInfo(cs, selectedEpoch, [signerWorkerUid]))[0];
  }

  /**
   * Convert RequestStatus to string
   */
  static getRequestStatus(status: RequestStatus): string {
    const requestStatusTitles = ["", "On approving", "Approved", "Rejected", "Canceled"];
    return status === undefined
      ? ""
      : status < requestStatusTitles.length
        ? requestStatusTitles[status]
        : status.toString();
  }

  static async getRequestsInfo(cs: ContractsSingleton, epoch: number, workerUids: BigNumber[]): Promise<RequestInfo[]> {
    // rid = requestUid, wid = workerUid

    const rws: {rid: BigNumber, wid: BigNumber}[] = workerUids.length === 0
      ? []
      : await PaginationUtils.chunkCall(
        workerUids,
        async () => (await cs.batchReader.getRequestUidBatch(epoch, workerUids)).map(
          (x: BigNumber, index: number) => ({
            rid: x,
            wid: workerUids[index]
          })
        )
      );

    // get statuses of all requests
    const rwss: { rid: BigNumber; wid: BigNumber; status: RequestStatus }[] =
      rws.length === 0
        ? []
        : await PaginationUtils.chunkCall(rws, async (items) =>
          (
            await cs.batchReader.getRequestStatuses(
              epoch,
              items.map((x) => x.wid),
            )
          ).map((x, index) => ({
            rid: items[index].rid,
            wid: items[index].wid,
            status: x as RequestStatus,
          })),
        );

    // we need to get info about requests only if it was created and not canceled
    const notExist = rwss.filter((x) => x.status === RequestStatus.Unknown_0 || x.status === RequestStatus.Canceled_4);
    const exist = rwss.filter((x) => x.status !== RequestStatus.Unknown_0 && x.status !== RequestStatus.Canceled_4);

    const retNotExist = notExist.map((x) => {
      return {
        requestStatus: x.status,
        requestUid: x.rid,
        workerUid: x.wid,
        epoch: epoch,
      };
    });

    const retExist: RequestInfo[] = [];
    for (let i = 0; i < exist.length; i += PaginationUtils.defaultPageSize) {
      const chunk = exist.slice(i, i + PaginationUtils.defaultPageSize);
      const r = await cs.batchReader.getRequests(chunk.map((x) => x.rid));
      for (let j = 0; j < r.outWorkerUids.length; ++j) {
        retExist.push({
          requestStatus: chunk[j].status,
          requestUid: chunk[j].rid,
          workerUid: chunk[j].wid,
          epoch: epoch,
          countHours: r.outCountHours[j],
          departmentTitle: (await cs.cachedData.getDepartmentData(r.outDepartmentUids[j]))?.title,
          departmentUid: r.outDepartmentUids[j],
          descriptionUrl: r.outDescriptionUrls[j],
          hourRate: r.outHourRates[j],
          roleUid: r.outWorkerRoles[j],
          roleTitle: (await cs.cachedData.getRoleData(r.outWorkerRoles[j]))?.title,
          debtToken: r.outDebtToken[j]
        });
      }
    }

    return [...retExist, ...retNotExist];
  }

  static getStatusColor(status: RequestStatus): string {
    switch (status) {
    case RequestStatus.Approved_2:
      return "green";
    case RequestStatus.Rejected_3:
      return "red";
    case RequestStatus.Canceled_4:
      return "magenta";
    default:
      return "";
    }
  }
}

