import {Tag} from "antd";
import React, {useEffect} from "react";
import {addressContractViewStore, web3Store} from "../../stores";
import {observer} from "mobx-react";

/**
 * Display address of controller contract
 */
export const ContractAddress = observer(() => {
  useEffect(() => {
    if (web3Store.provider) {
      const fetchData = async () => {
        await addressContractViewStore.fetchAddressController();
      };
      fetchData().catch(console.error);
    }
  }, [web3Store.provider, addressContractViewStore.addressController]);

  return (
    <>
      Controller: <Tag>{addressContractViewStore.addressController}</Tag>
    </>
  );
});
