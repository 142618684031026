import { makeAutoObservable, runInAction } from "mobx";
import { ContractsSingleton } from "../../logic";
import { web3Store } from "../web3-store";

class NavViewStore {
  isFetchingData = false;
  departmentHeadedByTheSigner: number | undefined;
  isGovernance: boolean | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  private readonly web3Store = web3Store;

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  async fetchData() {
    runInAction(() => {
      this.isFetchingData = true;
    });
    try {
      const cs = await this.core();
      const g = await cs.cachedData.isSignerGovernance();
      const h = await cs.cachedData.getDepartmentHeadedBySigner();
      runInAction(() => {
        this.departmentHeadedByTheSigner = h;
        this.isGovernance = g;
      });
    } finally {
      runInAction(() => {
        this.isFetchingData = false;
      });
    }
  }

  async connect() {
    await web3Store.connect();
  }

  async disconnect() {
    await web3Store.disconnect();
  }
}

export const navViewStore = new NavViewStore();
