import { makeAutoObservable } from "mobx";
import { web3Store } from "../web3-store";
import { ContractsSingleton, DepartmentData } from "../../logic";
import { BigNumber } from "ethers";
import { DepartmentUtils } from "../../logic/utils/DepartmentUtils";

/**
 * Store for department editor page
 */
class DepartmentPageChainStore {
  constructor() {
    makeAutoObservable(this);
  }

  private readonly web3Store = web3Store;

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  //region List of departments
  async fetchAvailableDepartments(): Promise<DepartmentData[]> {
    const cs = await this.core();
    return await cs.cachedData.getListDepartment();
  }

  //endregion List of departments

  //region Current department
  async fetchDepartmentWorkers(departmentUid: number): Promise<BigNumber[]> {
    const cs = await this.core();
    return await DepartmentUtils.getWorkersOfDepartment(cs, departmentUid);
  }

  //endregion Current department
}

export const departmentPageChainStore = new DepartmentPageChainStore();
