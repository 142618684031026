import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { navViewStore, web3Store } from "../../stores";
import { observer } from "mobx-react";

export const Nav = observer(() => {
  const [departmentHeadedBySigner, setDepartmentHeadedBySigner] = useState<number>();
  const [isGovernance, setIsGovernance] = useState<boolean>();

  useEffect(() => {
    if (web3Store.provider) {
      const fetchData = async () => {
        // fetch raw data
        await navViewStore.fetchData();
        setDepartmentHeadedBySigner(navViewStore.departmentHeadedByTheSigner);
        setIsGovernance(navViewStore.isGovernance);
        console.log("The governance:", navViewStore.isGovernance);
      };
      fetchData().catch(console.error);
    }
  }, [web3Store.provider]);

  return (
    <div style={{ marginBottom: 40 }}>
      <ul style={{ listStyleType: "none", display: "flex", margin: 0, padding: 0 }}>
        <li style={{ marginRight: 20 }}>
          <NavLink to="/">home</NavLink>
        </li>
        <li style={{ marginRight: 20 }}>
          <NavLink to="/create-request">Request</NavLink>
        </li>
        <li style={{ marginRight: 20 }}>
          <NavLink to="/to-approve">To approve</NavLink>
        </li>

        {/* Governance or Head of a departments
            Heads of departments view same info as governance,
            but governance-only actions are forbidden for them.
        */}

        {(isGovernance || departmentHeadedBySigner) && (
          <>
            <li style={{ marginRight: 20 }}>
              <NavLink to="/show-worker-debts">Debts</NavLink>
            </li>
            <li style={{ marginRight: 20 }}>
              <NavLink to="/department">Department</NavLink>
            </li>
            <li style={{ marginRight: 20 }}>
              <NavLink to="/budgets">Budgets</NavLink>
            </li>
            <li style={{ marginRight: 20 }}>
              <NavLink to="/payments">Payments</NavLink>
            </li>
            <li style={{ marginRight: 20 }}>
              <NavLink to="/workers">Workers</NavLink>
            </li>
          </>
        )}
      </ul>
    </div>
  );
});
