import { makeAutoObservable, runInAction } from "mobx";
import { web3Store } from "../web3-store";
import { ApprovalByApproverInfo, ContractsSingleton } from "../../logic";
import { BigNumber } from "ethers";
import { ApproveTargetUtils } from "../../logic/utils/ApproveTargetUtils";

class ListApprovalsViewStore {
  isFetchingData = false;
  approvals: ApprovalByApproverInfo[] | null = null;
  /** Last happened error */
  error = "";

  private readonly web3Store = web3Store;

  constructor() {
    makeAutoObservable(this);
  }

  /** Hide last happened error */
  hideError() {
    runInAction(() => {
      this.error = "";
    });
  }

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  //region Load data
  /** Load all approvals of the worker's request for the current epoch */
  async fetchApprovals(workerUid: BigNumber, epoch: number | undefined) {
    runInAction(() => {
      this.isFetchingData = true;
    });
    try {
      const cs = await this.core();
      const r = await ApproveTargetUtils.getApprovals(cs, workerUid, epoch);
      runInAction(() => {
        this.approvals = r;
      });
      return r;
    } finally {
      runInAction(() => {
        this.isFetchingData = false;
      });
    }
  }

  //endregion Load data
}

export const listApprovalsViewStore = new ListApprovalsViewStore();
