import { runInAction } from "mobx";
import { ContractsSingleton } from "../../logic";
import { web3Store } from "../web3-store";
import { BigNumber } from "ethers";
import { IERC20, IERC20__factory } from "../../typechain";
import { epochNavigatorViewStore } from "./EpochNavigatorViewStore";
import { AmountUtils } from "../../logic/utils/AmountUtils";

class PaymentsPageViewStore {
  isFetchingData = false;

  defaultWeekBudgetST: BigNumber | null = null;

  salaryTokenAddress = "";
  availableBalanceDefaultST: BigNumber | null = null;
  defaultSalaryTokenName: string | undefined;
  defaultSalaryTokenDecimals: number | undefined;
  priceUsdDefaultST: BigNumber | undefined;

  weekSalaryTokenAddress = "";
  weekSalaryTokenName: string | undefined;
  weekSalaryTokenDecimals: number | undefined;
  availableBalanceWeekST: BigNumber | null = null;
  priceUsdWeekST: BigNumber | undefined;

  private readonly web3Store = web3Store;
  private readonly epochNavigatorViewStore = epochNavigatorViewStore;

  constructor() {
    //makeAutoObservable(this);
  }

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  //region Load data
  /** Load budget values of specified kind
   *
   * There are two kind of salary tokens:
   * - default salary token (CompanyManager.salaryToken)
   * - salary token assigned to current epoch (DebtsManager.weekSalaryToken)
   * They can be different in transition period, when the salary token is changed to another one
   * */
  async fetchBudgetValues() {
    runInAction(() => {
      this.isFetchingData = true;
    });
    try {
      const cs = await this.core();
      const weekBudgetST = await cs.cachedData.getDefaultWeekBudgetST();

      const defaultSalaryTokenAddress = await cs.cachedData.getDefaultSalaryToken();
      const defaultSalaryTokenName = await cs.cachedData.getTokenName(defaultSalaryTokenAddress);
      const defaultSalaryTokenDecimals = await cs.cachedData.getTokenDecimals(defaultSalaryTokenAddress);

      const weekSalaryTokenAddress = await cs.debtsManager.weekSalaryToken();
      const weekSalaryTokenName = await cs.cachedData.getTokenName(weekSalaryTokenAddress);
      const weekSalaryTokenDecimals = await cs.cachedData.getTokenDecimals(weekSalaryTokenAddress);

      let availableBalanceDefaultST: BigNumber | undefined;
      try {
        availableBalanceDefaultST = await (
          (await IERC20__factory.connect(defaultSalaryTokenAddress, cs.signer)) as IERC20
        ).balanceOf(cs.paymentsManager.address);
      } catch (e) {
        //not enough funds
      }

      let priceUSDDefaultST: BigNumber | undefined;
      try {
        priceUSDDefaultST = await cs.priceOracle.getPrice(defaultSalaryTokenAddress);
      } catch (e) {
        //token is not supported
      }

      let availableBalanceWeekST: BigNumber | undefined;
      if (defaultSalaryTokenAddress === weekSalaryTokenAddress) {
        availableBalanceWeekST = availableBalanceDefaultST;
      } else if (!AmountUtils.isZeroAddress(weekSalaryTokenAddress)) {
        try {
          availableBalanceWeekST = await (
            (await IERC20__factory.connect(weekSalaryTokenAddress, cs.signer)) as IERC20
          ).balanceOf(cs.paymentsManager.address);
        } catch (e) {
          //not enough funds
        }
      }

      let priceUSDWeekST: BigNumber | undefined;
      try {
        if (!AmountUtils.isZeroAddress(weekSalaryTokenAddress)) {
          priceUSDWeekST = await cs.priceOracle.getPrice(weekSalaryTokenAddress);
        }
      } catch (e) {
        //token is not supported
      }

      runInAction(() => {
        this.defaultWeekBudgetST = weekBudgetST;

        this.salaryTokenAddress = defaultSalaryTokenAddress;
        this.availableBalanceDefaultST = availableBalanceDefaultST || null;
        this.defaultSalaryTokenName = defaultSalaryTokenName;
        this.priceUsdDefaultST = priceUSDDefaultST;
        this.defaultSalaryTokenDecimals = defaultSalaryTokenDecimals;

        this.weekSalaryTokenAddress = weekSalaryTokenAddress;
        this.weekSalaryTokenName = weekSalaryTokenName;
        this.availableBalanceWeekST = availableBalanceWeekST || null;
        this.priceUsdWeekST = priceUSDWeekST;
        this.weekSalaryTokenDecimals = weekSalaryTokenDecimals;
      });
    } finally {
      runInAction(() => {
        this.isFetchingData = false;
      });
    }
  }

  /** Start new epoch and refetch data */
  async startEpoch(makePaymentImmediately: boolean) {
    const cs = await this.core();
    await cs.runAndWait(() => cs.debtsManager.startEpoch(makePaymentImmediately));
    await cs.cachedData.getCurrentEpoch(true);
    await this.fetchBudgetValues();

    // we need to refresh current epoch in the header
    await epochNavigatorViewStore.fetchCurrentEpoch();
  }

  async paySalaryToCompany() {
    const cs = await this.core();
    await cs.runAndWait(() => cs.debtsManager.pay());
    await cs.cachedData.getCurrentEpoch(true);
    await this.fetchBudgetValues();
  }

  getDefaultST(): string {
    return this.defaultSalaryTokenName || "ST";
  }

  getWeekST(): string {
    return this.weekSalaryTokenName || "ST";
  }

  //endregion Load data
}

export const paymentsPageViewStore = new PaymentsPageViewStore();
