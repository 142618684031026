import React, { useEffect, useState } from "react";
import { Radio, RadioChangeEvent, Space } from "antd";
import { ListWorkersEditor } from "../../components/ListWorkersEditor";
import { BigNumber } from "ethers";
import { departmentPageViewStore, listWorkersEditorViewStore, web3Store } from "../../stores";
import { DepartmentData } from "../../logic";
import { DepartmentPropertiesEditor } from "../../components/DepartmentPropertiesEditor";
import { DepartmentBudgetEditor } from "../../components/DepartmentBudgetEditor";
import { observer } from "mobx-react";

/** Governance is able to select any department and edit it
 * Head of department is able to edit only his own department, but he is able to view all other departments.
 * Others cannot do anything
 * */
export const DepartmentPage = observer(() => {
  //region List of available departments
  const [listDepartments, setListDepartments] = useState<DepartmentData[]>([]);

  /** Load list of available departments */
  useEffect(() => {
    if (web3Store.provider) {
      const fetchData = async () => {
        await departmentPageViewStore.fetchAvailableDepartments();
        setListDepartments(departmentPageViewStore.departments || []);
        if (departmentPageViewStore.departments?.length) {
          setSelectedDepartmentUid(departmentPageViewStore.departments[0].uid);
        }
      };
      fetchData().catch(console.error);
    }
  }, [web3Store.provider]);

  function onChangeSelectedDepartment(e: RadioChangeEvent) {
    setSelectedDepartmentUid(e.target.value);
  }

  function getDepartmentsSelector(): JSX.Element {
    const rows = [];
    for (const d of listDepartments) {
      rows.push(
        <Radio key={d.uid} value={d.uid}>
          {d.title}
        </Radio>,
      );
    }
    return (
      <Radio.Group onChange={onChangeSelectedDepartment} value={selectedDepartmentUid}>
        {rows}
      </Radio.Group>
    );
  }

  //endregion List of available departments

  //region Selected department
  /** List of the workers of selected department*/
  const [selectedDepartmentUid, setSelectedDepartmentUid] = useState<number>(0);
  const [listWorkers, setListWorkers] = useState<BigNumber[]>([]);

  /** Load data for selected department */
  useEffect(() => {
    if (web3Store.provider) {
      const fetchData = async () => {
        await departmentPageViewStore.fetchDepartmentWorkers(selectedDepartmentUid);
        const newList = departmentPageViewStore.departmentWorkerUids || [];
        setListWorkers(() => newList);
      };
      fetchData().catch(console.error);
    }
  }, [listWorkersEditorViewStore, selectedDepartmentUid, web3Store.provider]);
  //endregion Selected department

  return (
    <div className="container">
      <Space size={[8, 16]} wrap>
        {listDepartments && listDepartments.length > 1 && getDepartmentsSelector()}
      </Space>

      <h2>Department info</h2>

      <DepartmentPropertiesEditor departmentUid={selectedDepartmentUid} />

      <h2>Department workers</h2>
      <Space size={[8, 16]} wrap direction="vertical">
        <ListWorkersEditor workerUids={listWorkers} />
      </Space>

      <h2>Budget</h2>
      <DepartmentBudgetEditor departmentUid={selectedDepartmentUid} />
    </div>
  );
});
