import { makeAutoObservable } from "mobx";
import { web3Store } from "../web3-store";
import {
  ApprovalByApproverInfo,
  ApprovalInfo,
  ApproveTargetInfo,
  ContractsSingleton,
  RequestStatus,
} from "../../logic";
import { ApproveTargetUtils } from "../../logic/utils/ApproveTargetUtils";
import { BigNumber } from "ethers";

/**
 * Store for create-request page
 */
class ApproveRequestsChainStore {
  constructor() {
    makeAutoObservable(this);
  }

  private readonly web3Store = web3Store;

  async core(): Promise<ContractsSingleton> {
    return await ContractsSingleton.get(this.web3Store.provider);
  }

  //region Get data
  async getListApproveTargets(epoch: number | undefined): Promise<ApproveTargetInfo[]> {
    const cs = await this.core();
    return ApproveTargetUtils.getListApproveTargets(cs, await cs.signer.getAddress(), epoch);
  }

  /** Download single approval and updated final status */
  async getApproval(workerUid: BigNumber): Promise<{ approval: ApprovalInfo | undefined; finalStatus: RequestStatus }> {
    return await ApproveTargetUtils.getApproval(await this.core(), workerUid);
  }

  /** Get all approvals received by the worker's request in the selected epoch */
  async fetchApprovals(workerUid: BigNumber, epoch: number | undefined): Promise<ApprovalByApproverInfo[]> {
    const cs = await this.core();
    return await ApproveTargetUtils.getApprovals(cs, workerUid, epoch);
  }

  //endregion Get data

  //region Approve, disapprove
  async approveSingleRequest(workerUid: BigNumber) {
    await this.approve(workerUid, true, "");
  }

  async rejectSingleRequest(workerUid: BigNumber, explanationWhyRejected: string) {
    await this.approve(workerUid, false, explanationWhyRejected);
  }

  async approveRequestsBatch(workerUids: BigNumber[]) {
    const cs = await this.core();
    const requestUids = await cs.batchReader.getRequestUidBatch(await cs.cachedData.getCurrentEpoch(), workerUids);
    await cs.runAndWait(() => cs.requestsManager.approveBatch(requestUids));
  }

  //endregion Approve, disapprove

  //region Utils
  async approve(workerUid: BigNumber, approveValue: boolean, explanation: string) {
    const cs = await this.core();
    const requestUid = await cs.requestsManager.getRequestUid(await cs.cachedData.getCurrentEpoch(), workerUid);
    await cs.runAndWait(() => cs.requestsManager.approve(requestUid, approveValue, explanation));
  }

  //endregion Utils
}

export const approveRequestsChainStore = new ApproveRequestsChainStore();
